<template>
    <div v-if="items" class="breadcrumbs">
      <div class="breadcrumbs-back">
        <Icon v-if="backItem" name="back-arrow" @click="onBack"/>
      </div>

      <div class="breadcrumbs-main">
        <div class="breadcrumbs__path">
          <template v-for="(item, index) in items">
            <div @click="redirect(item.link)"
                 :key="key+index"
                 v-if="index >= from"
                 class="breadcrumbs__item"
                 :class="{'breadcrumbs__item-active': item.active}">
              <slot :name="item.id" :item="item">
                {{item.name}}
              </slot>
              <span v-if="index < items.length-1" class="ms-2">/</span>
            </div>
          </template>
        </div>
        <div class="breadcrumbs__title">
          <slot name="icon"></slot>
          <slot name="title">
            {{items[items.length - 1].name}}
          </slot>
          <div class="breadcrumbs__title__button">
            <slot name="button"></slot>
          </div>
        </div>
      </div>

      <div class="breadcrumbs-filter">
        <template v-if="filter || customFilterModal">
          <Icon name="filter" @click="openFilter" />
          <slot name="filter-modal" :show="showFilter" :close="closeFilter">
            <BreadcrumbFilterModal :show="showFilter"
                                   v-model:filter="breadcrumbFilter.filter"
                                   @close="closeFilter"
                                   @accept="onAccept"
                                   @reset="onReset"
            />
          </slot>
        </template>
      </div>
    </div>
</template>

<script setup>
import Icon from "Common/components/svg";
import BreadcrumbFilterModal from "Common/components/modals/BreadcrumbFilterModal";
import {useBreadcrumbFilter} from "Common/utility/breadcumbsUtility";
import {useUserStore} from "Common/stores/userStore";
import {computed, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {ROUTES} from "@/utility/routeUtils";
const {t} = useI18n({useScope: 'global'});

const props = defineProps({
  items: Array,
  from: {type: Number, default: 0},
  filter: Array,
  customFilterModal: {type: Boolean, default: false},
})
const emit = defineEmits(['update:filter', 'acceptFilter']);

const router = useRouter();
const userStore = useUserStore();

const breadcrumbFilter = ref(null);

watch(() => props.filter, () => {
  breadcrumbFilter.value = useBreadcrumbFilter(t, props.filter);
}, {immediate: true})

const key = ref("breadcrumbs");
const showFilter = ref(false);

const backItem = computed(() => {
  const backItemInd = props.items.length - 2;
  if(backItemInd < 0) return {};
  return props.items[backItemInd];
})

function redirect(link) {
  router.push(link);
}
function onBack() {
  if(!backItem.value || !backItem.value.link || backItem.value.link === '#'){
    if(userStore.user.defaultHomeUrl) router.push(userStore.user.defaultHomeUrl);
    else if(userStore.user.organization?.defaultHomeUrl) router.push(userStore.user.organization?.defaultHomeUrl);
    else router.push({name: ROUTES.AnalyzeNow});
  }
  else router.push(backItem.value.link);
}
function openFilter() {
  showFilter.value = true;
}
function closeFilter() {
  showFilter.value = false;
}

function onAccept(){
  emit('acceptFilter', breadcrumbFilter.value.getValues());
}

function onReset(){
  breadcrumbFilter.value.resetFilter();
  emit('acceptFilter', breadcrumbFilter.value.getValues());
}

</script>

<style scoped lang="scss">
.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}
.breadcrumbs-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.breadcrumbs-filter,
.breadcrumbs-back{
  display: none;
  width: 24px;
  height: 24px;
  > svg{
    cursor: pointer;
  }
}
.breadcrumbs__path{
  display: flex;
  flex-direction: row;
}
.breadcrumbs__title{
  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 34px;
  line-height: 42px;
  margin-top: 5px;

  &__button{
    margin-left: auto;
  }
}

.breadcrumbs__item {
  font-size: 14px;
  color: #A3AED0;
  font-weight: 400;
  cursor: pointer;

  &.breadcrumbs__item-active {
      color: #A3AED0;
  }
}

.breadcrumbs__item span {
  margin-right: 10px;
}

.mobile-device{
  .breadcrumbs{
    justify-content: space-between;
    width: 100%;
    margin-bottom: 18px;
  }
  .breadcrumbs-filter,
  .breadcrumbs-back{
    display: unset;
    color: white;
  }
  .breadcrumbs-main{
    align-items: center;
    flex-direction: column-reverse;
    gap: 4px;
  }
  .breadcrumbs__title{
    margin-top: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -0.02em;
    text-align: left;
    color: white;
  }
  .breadcrumbs__path{
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0;
    text-align: left;
    color: #A3AED0;
  }

  @media (max-width: 1365px){ //1024 - 1366

  }

  @media (max-width: 1023px){ //678 - 1024

  }

  @media (max-width: 767px){ //375 - 768

  }
}


body.rtl {
  .breadcrumbs__title{
    &__button{
      margin-right: auto;
      margin-left: 0;
    }
  }

  .breadcrumbs__item span {
    margin-left: 10px;
  }

  .breadcrumbs__item {
    .ms-2 {
      margin-left: 10px!important;
      margin-right: .5rem!important;
    }
  }

  .mobile-device{
    .breadcrumbs__title{
      text-align: right;
    }
    .breadcrumbs__path{
      text-align: right;
    }

  }

  .btn-send {
    svg {
      transform: rotateY(0deg);
    }
  }
}
</style>
