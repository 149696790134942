<template>
    <div class="main-bg-container wrap-768">
      <div class="main-container">
        <div class="main-form-container">
          <div class="second-bg-container">
            <div class="second-bg-doc-container"><img src="~Common/assets/emlo-affiliate.png" alt=""/></div>
          </div>
          <div class="main-input-group">
            <div class="main-input-group-title">{{ t('createPassword.title') }}</div>
            <div class="main-input-group-text">{{ t('createPassword.text') }}</div>

            <BaseInputPassword
              :name="t('common.newPassword')"
              v-model="form.password"
              :is-required="true"
              :error="errors['password']"
              class="mb-3"
            />

            <BaseInputPassword
              :name="t('common.confirmPassword')"
              v-model="form.confirmPassword"
              :is-required="true"
              :error="errors['confirmPassword']"
            />

            <BaseButton class="my-3 w-100"
                        @click="createPassword"
                        :loading="loadingSending">
              {{ t('common.update') }}
            </BaseButton>

          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import BaseInputPassword from "Common/components/controls/BaseInputPassword";
import BaseButton from "Common/components/controls/BaseButton";
import {useI18n} from "vue-i18n";
import {ref, shallowRef} from "vue";
import {createFormErrors} from "Common/utility/formErrors";

const props = defineProps({
    onUpdate: Function
});
const emit = defineEmits(['onError'])

const {t, te} = useI18n({useScope: 'global'});
const form = ref({password: '', confirmPassword: ''});
const loadingSending = shallowRef(false);
const {errors, showHiddenErrors, removeErrors, addErrors} = createFormErrors(t, te, [form], () => {
    const errs = {};
    if (form.value.password && form.value.confirmPassword !== form.value.password) {
        errs['confirmPassword'] = 'password_not_match';
    }
    return errs;
}, {
    requiredFields: ['password', 'confirmPassword'],
    translationOrder: ['common.errorList', 'changePasswordModal.errorList'],

})

function createPassword(e) {
    if (showHiddenErrors())
        return;
    removeErrors();

    loadingSending.value = true
    e.preventDefault();
    e.stopPropagation();

    const req$ = props.onUpdate ? props.onUpdate(form.value) : Promise.resolve();

    req$.then(() => {

    }, err => {
        if (err?.fieldErrors) {
            addErrors(err.fieldErrors);
        } else {
            emit('onError', err);
        }
    }).then(() => {
        loadingSending.value = false;
    });

}

</script>


<style scoped lang="scss" src="Common/assets/styles/themes/light/auth.scss"></style>