<template>
  <div class="base-search" 
       :class="{[rootClass]: true, 'base-input-disabled': $attrs.disabled}"
       @click="focus"
  >
    <div class="base-input-container" :class="{'top-filter-style': topFilterStyle}">
      <IconSearch />
      <div class="d-flex position-relative w-100">
          <input
              ref="refSearch"
              v-model="value"
              type="text"
              v-bind="$attrs"
              v-on="$attrs"
          >
          <div v-show="!value" class="input-placeholder">{{placeholder}}</div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup>

import IconSearch from "Common/assets/icons/search.svg?inline"
import {computed, shallowRef, watch, toRef} from "vue";
const props = defineProps ({
    name: String,
    placeholder: String,
    modelValue: String,
    debounce: Number,
    topFilterStyle: Boolean,
    rootClass: String
});

defineExpose({
    focus
})

const emit = defineEmits( ['update:modelValue']);

let debounceObj = null;
const innerValue = shallowRef( '');
const refSearch = shallowRef(null);

const value = computed({
    get() {
        return props.debounce ? innerValue.value : props.modelValue;
    },
    set(value) {
        if (props.debounce) {
            innerValue.value = value;
            debounceObj && clearTimeout(debounceObj);
            debounceObj = setTimeout(() => {
                debounceObj = null;
                emit('update:modelValue', value);
            }, props.debounce);
        } else {
            emit('update:modelValue', value);
        }
    }
});

watch(toRef(props, "modelValue"), v => {
    innerValue.value = v;
}, {
    immediate: true
});


function focus(){
    refSearch.value?.focus();
}

</script>

<style lang="scss" scoped>
@import "~Common/assets/styles/themes/light/light-theme-colors.scss";
  .base-search{
    .base-input-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 48px;
        border-radius: 8px;
        font-size: 14px;
        padding: 12px 14px;
        
        svg{
            margin-right: 13px;
        }
    }
    
    &.base-input-disabled {
      background: $c-input-disabled-bg;

      input{
        background: $c-input-disabled-bg;
      }
    }
  }

body.rtl {
  .base-search{
    .base-input-container{
        svg{
            margin-left: 13px;
            margin-right: 0;
        }
    }
  }
}
</style>
