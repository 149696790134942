<template>
    <div id="app-container" class="app-container d-flex flex-row" v-if="!loading">
        <Sidebar></Sidebar>
        <div id="main_content" class="main_content">
            <Header></Header>
            <div id="page_data" class="page_data">
                <template v-if="userStore.user && permitted">
                    <router-view></router-view>
                </template>

                <template v-else>
                    <div v-if="userStore.user">
                        <div>
                            Your account does not have proper permissions to view this page. <br/>
                            Please contact support service to get any help.
                        </div>
                    </div>
                </template>

                <BaseTableActionModals/>
<!--                <OrgPhoneNumberVerificationModal/>-->
                <CreateCouponModal />

            </div>
        </div>
    </div>
</template>

<script setup>

import {BarChart, PieChart, LineChart} from "echarts/charts";
import {
    ToolboxComponent,
    BrushComponent,
    TitleComponent,
    TooltipComponent,
    LegendComponent, GridComponent, MarkLineComponent
} from "echarts/components";
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";

use([
    ToolboxComponent,
    BrushComponent,
    CanvasRenderer,
    LineChart,
    BarChart,
    PieChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    MarkLineComponent,
    LegendComponent,
    GridComponent,
]);

import Sidebar from './app/Sidebar.vue'
import Header from './app/Header.vue'
import {useUserStore} from "Common/stores/userStore";
import {useOrganizationStore} from "Common/stores/organizationStore";
import router from "@/routes";
import {ref, unref, computed, onMounted} from "vue";
import {checkUserPermission, ROUTES} from "@/utility/routeUtils";
import BaseTableActionModals from "Common/components/table/BaseTableActionModals";
import OrgPhoneNumberVerificationModal from "@/components/OrgPhoneNumberVerificationModal";
import CreateCouponModal from "@/pages/affiliate/app/modals/CreateCouponModal";


const loading = ref(true);
const userStore = useUserStore();
const organizationStore = useOrganizationStore();

const permitted = computed(() => {
    return checkUserPermission(userStore.user, unref(router.currentRoute));
});

onMounted(() => {
    if (!userStore.getUserToken()) {
        userStore.saveDestinationPath();
        router.push('/login');
        return;
    }

    userStore.loadUser().then(() => {
        organizationStore.loadOrganizationById(userStore.user.organizationExternalId);
        if (!userStore.user.emailVerified) router.push({name: ROUTES.EmailConfirmation});
    }).catch((err) => {
        console.log(err);
    }).finally(() => {
        loading.value = false;
    });
});
        
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.app-container {
    flex: 1;
    overflow: auto;
}

.main_content {
    display: flex;
    flex-direction: column;
    width: 100%;

    max-width: calc(100% - 290px);
    @media (max-width: 1200px) {
        max-width: calc(100% - 24px);
    }
}

.page_data {
    position: relative;
    flex: 1;

    &.onboarding-overflow {
        overflow: hidden;
    }
}

.hamburger ~ .main_content {
    //padding-left: 24px;
    @media (max-width: 1200px) {
        //padding-left: 270px;
    }

    max-width: calc(100% - 24px);
    @media (max-width: 1200px) {
        max-width: calc(100% - 290px);
    }

}
</style>


