import { defineCustomElement } from 'vue'

export * from './reports/_exportComponents.js'

import _Diamond from '@/components/EmotionDiamondLibrary/blocks/Diamond.ce.vue'
import _TimeCode from '@/components/EmotionDiamondLibrary/blocks/TimeCode.ce.vue'
import _LVAOnline from '@/components/EmotionDiamondLibrary/blocks/LVAOnline.ce.vue'
import _LVAEmotionalStyle from '@/components/EmotionDiamondLibrary/blocks/CurrentEmotionalStyle.ce.vue'
import _LVAEmotions from '@/components/EmotionDiamondLibrary/blocks/Emotions.ce.vue'
import _VideoPlayer from '@/components/Players/NMSPlayer.ce.vue'

export const NMSDiamond = defineCustomElement(_Diamond)
export const NMSTimeCode = defineCustomElement(_TimeCode)
export const NMSLVAOnline = defineCustomElement(_LVAOnline)
export const NMSEmotionalStyle = defineCustomElement(_LVAEmotionalStyle)
export const NMSEmotions = defineCustomElement(_LVAEmotions)
export const NMSPlayer = defineCustomElement(_VideoPlayer)