<template>
    <CreatePassword
        :code="code"
        :email="email"
        :on-update="onVerify"
        @onError="onError"
    />
</template>

<script setup>
import CreatePassword from "./create-password.vue";
import {computed, onMounted} from "vue";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";
import {useUserStore} from "Common/stores/userStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import {useI18n} from "vue-i18n";

const {t, te} = useI18n({useScope: 'global'});

const badgeNotify = useBadgeNotify();

const userStore = useUserStore();

const code = computed(() => router.currentRoute.value.query.verificationCode);
const email = computed(() => router.currentRoute.value.query.email);

onMounted(() => {
    if (!email.value || !code.value) {
        gotoConfirmEmail();
    }
});
function onVerify(form) {
    return userStore.verifyEmailCode({
        ...form,
        email: email.value,
        emailVerificationCode: code.value,
    }).then(() => {
        router.push({name: ROUTES.RegisterDetails});
    });
}

function onError (err) {
    const errCode = `register.errorList.${err?.errorCode}`;

    badgeNotify.show({error: true, text: t(te(errCode) ? errCode : "common.errorList.sorrySomethingWrong")})

    gotoConfirmEmail();
}

function gotoConfirmEmail() {
    router.push({name: ROUTES.EmailConfirmation, query: email.value ? {email: email.value} : {}});
}

</script>


