<template>
  <div class="google-btn-block">
    <div class="header">
      <div class="line"/>
      <div class="text">
        {{ t('common.or_sign_in_with_google') }}
      </div>
    </div>
    <BaseButton outline class="w-100 google-login-btn" :disabled="disabled" @click="googleAuthHandler">
      GOOGLE
      <Icon name="google-login" class="icon"/>
    </BaseButton>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {googleTokenLogin, googleSdkLoaded } from "vue3-google-login"
import BaseButton from "./BaseButton";
import Icon from '../svg'
import {useI18n} from "vue-i18n";
import {useUserStore} from "Common/stores/userStore";
import {ROUTES} from "@/utility/routeUtils";
import {useRouter} from "vue-router";
import {useBadgeNotify} from "Common/stores/badgeNotify";

const props = defineProps({
  authRoute: String, 
  disabled: Boolean,
});

const {t} = useI18n({useScope: 'global'})
const userStore = useUserStore()
const router = useRouter()
const country = ref('')
const badgeNotify = useBadgeNotify();

const googleAuthHandler = async () => {
  if(props.disabled) return;
  
  googleSdkLoaded((google) => {
    google.accounts.oauth2.initCodeClient({
      client_id: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
      ux_mode: 'redirect',
      redirect_uri: location.origin + '/' + props.authRoute,
      scope: 'email profile openid',
      select_account: true
    }).requestCode()
  })
}

</script>

<style lang="scss" scoped>
@import "Common/assets/styles/themes/light/light-theme-colors.scss";

.google-btn-block {
  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 12px;

    .line {
      width: 100%;
      height: 1px;
      background-color: $toggle-disabled-bg;
      position: absolute;
      left: 0;
      top: 50%;
    }

    .text {
      background-color: white;
      padding: 0 10px;
      z-index: 2;
    }
  }

  .google-login-btn {
    width: 100%;
    border-width: 2px;

    .icon {
      margin-left: 10px;
    }
    &.disabled{
      border-color: #A3AED0;
      color: #A3AED0;
    }
  }
}

body.rtl {
  .google-btn-block {
    .header {
      .line {
        right: 0;
        left: unset;
      }
    }

    .google-login-btn {
      .icon {
        margin-right: 10px;
        margin-left: unset;
      }
    }
  }
}
</style>