import { defineStore } from 'pinia';

export const useTableActions = defineStore('tableActions', {
    state: () => {
        return {
            show: false,
            options: {},
            actionLoading: false,
            okCallBack: () => {},
        }
    },
    actions: {
        showApproveModal(options, callBack) {
            this.show = true;
            this.options = {...options};
            this.okCallBack = callBack;
        },
        close() {
            this.show = false;
            this.options = {};
            this.okCallBack = () => {};
        }
    }
})
