import {defineStore} from 'pinia';
import axios from "axios";
import {environment} from 'Common/environments/environment';

export const useSiteStore = defineStore('siteStore', {
    state: () => {
        return {
            cloudAnalysis: [],
            sites: [],
            stats: null,
            typesOfUse: [],
            useCases: [],
        }
    },
    getters: {
        
    },
    actions: {
        getSites(organizationExternalId, showArchived) {
            return axios.post(environment.sitesServiceUrl + '/site/search', {
                data: {
                    filter: {
                        organizationExternalId,
                        showArchived,
                    }
                }
            }).then(response => {
                if(!response.data.success) 
                    throw new Error(response.data.error || "Failed to get sites.");
                this.sites = response.data.data;
                return (response.data.data || []);
            });
        },

        addSite(query) {
            return axios.post(environment.sitesServiceUrl + '/site/insert', {
                data: query
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                this.sites.push(response.data.data);
                return response.data.data;
            });
        },

        updateSite(query) {
            return axios.post(environment.sitesServiceUrl + '/site/update', {
                data: query
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                const site = this.sites.find(s => s.siteExternalId === query.siteExternalId);
                return Object.assign(site || {}, query);
            });
        },

        updateSiteStatus(siteExternalId, status) {
            return axios.post(environment.sitesServiceUrl + '/site/updateSiteStatus', {
                siteExternalId,
                status
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                const site = this.sites.find(s => s.siteExternalId === siteExternalId);
                if (site) {
                    site.status = status;
                }
            });
        },

        deleteSite(siteExternalId) {
            return axios.post(environment.sitesServiceUrl + '/site/delete', {
                data: {
                    siteExternalId: siteExternalId
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                this.sites = this.sites.filter((item) => item.siteExternalId !== siteExternalId);
            });
        },

        siteInfo(externalId) {
            return axios.post(environment.sitesServiceUrl + '/site/getByExternalId', {
                data: {
                    siteExternalId: externalId
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return (response.data.data || {});
            });
        },

        getSiteStats() {
            return axios.post(environment.sitesServiceUrl + '/site/stats').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.stats = (response.data.data || {});
            });
        },
        
        getSiteById(siteExternalId){
            const site = this.sites.find((s) => s.siteExternalId === siteExternalId);
            if(site) return Promise.resolve(site);
            
            return this.siteInfo(siteExternalId);
        },

        loadTypesOfUse () {
            if(this.typesOfUse.length > 0) return Promise.resolve(this.typesOfUse);
            
            return axios.post(environment.sitesServiceUrl + '/site/typesOfUse').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.typesOfUse = (response.data.data || []);
            });
        },

        loadUseCases () {
            if(this.useCases.length > 0) return Promise.resolve(this.useCases);

            return axios.post(environment.sitesServiceUrl + '/site/useCases').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.useCases = (response.data.data || []);
            });
        },
        
        loadDocker(dockerExternalId, filter) {
            return axios.post(environment.sitesServiceUrl + '/docker/getByExternalId',{
                data: {
                    dockerExternalId: dockerExternalId,
                    filter
                }
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return (response.data.data || []);
            });
        },
        loadDockers(siteExternalId, apiKeyExternalId) {
            return axios.post(environment.sitesServiceUrl + '/docker/search',{
                data: {
                    filter: {
                        siteExternalId,
                        apiKeyExternalId,
                    }
                }
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return (response.data.data || []);
            });
        },

        getDockersReportUrl() {
            return environment.sitesServiceUrl + '/docker/report';
        },

        loadDockersReport(filter, params) {
            return axios.post(this.getDockersReportUrl(),{
                data: {
                    filter,
                    params
                }
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return (response.data.data || []);
            });
        },
        getCloudAnalysisUrl() {
            return environment.sitesServiceUrl + '/docker/cloudAnalysis';
        },
        getCloudAnalysis(data) {
            return axios.post(this.getCloudAnalysisUrl(), {
                data
            }).then(response => {
                if(!response.data.success)
                    throw new Error(response.data.error || "Failed to get sites.");
                this.cloudAnalysis = response.data?.data?.items || [];
                return (response.data.data || []);
            });
        },

        getSitesFullList() {
            return axios.post(environment.sitesServiceUrl + '/site/fullList').then(response => {
                if(!response.data.success)
                    throw new Error(response.data.error || "Failed to get sites.");
                return (response.data.data || []);
            });
        },

        async getUserActivity() {
            const response = await axios.post(environment.sitesServiceUrl + '/docker/getUserActivity');

            if (!response.data.success) {
                return Promise.reject(response.data);
            }

            return response.data.data;
        },
    }
})
