import { createWebHistory } from "vue-router";
import {ROUTES} from "@/utility/routeUtils";

//Affiliate
import Register from '@/pages/affiliate/login/register';
import Login from '@/pages/affiliate/login/login';
import Layout from '@/pages/affiliate/Layout';
import Dashboard from '@/pages/affiliate/app/dashboard/dashboard';
import RegisterDetails from '@/pages/affiliate/app/register-details/index';
import PrivacyPolicy from "@/components/PrivacyPolicy";
import AffiliatePaymentPolicy from "@/pages/affiliate/login/AffiliatePaymentPolicy";
import TermOfUse from "@/components/TermOfUse";
import Verify from "@/pages/affiliate/login/verify";
import EmailConfirmation from "@/pages/affiliate/login/email-confirmation";
import ForgotPassword from "@/pages/affiliate/login/forgot-password";
import ResetPassword from "@/pages/affiliate/login/reset-password";
import CreatePassword from "@/pages/affiliate/login/create-password";
import ProfileSettings from "@/pages/affiliate/app/profile-settings/profile_settings";
import CustomerSupportCasesRoot from "@/pages/customer-support/Index";
import CustomerSupportCases from "@/pages/customer-support/Cases";
import CustomerSupportCaseManage from "@/pages/customer-support/CaseManage";
import NotificationsList from "@/components/notifications-list";
import Payouts from "@/pages/affiliate/app/payouts/payouts";


export default {
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (to.meta?.preserveScroll) {
            if (to.path === from.path) {
                return savedPosition;
            }
        }
        // always scroll to top
        const appContainer = document.getElementById('app-container');
        if (appContainer)
            appContainer.scrollTop = 0;
        return {top: 0}
    },
    routes: [
        {
            path: '/term-of-use',
            name: ROUTES.TermOfUse,
            component: TermOfUse,
            meta: {permissions: {}}
        },
        {
            path: '/privacy-policy',
            name: ROUTES.PrivacyPolicy,
            component: PrivacyPolicy,
            meta: {permissions: {}}
        },
        {
            path: '/payment-policy',
            name: ROUTES.AffiliatePaymentPolicy,
            component: AffiliatePaymentPolicy,
            meta: {permissions: {}}
        },
        {
            path: '/register/verify',
            name: ROUTES.Verify,
            component: Verify
        },
        {
            path: '/register',
            name: ROUTES.Register,
            component: Register,
            meta: {permissions: {}},
        },
        {
            path: '/login',
            name: ROUTES.Login,
            component: Login,
            meta: {permissions: {}},
        },
        {
            path: '/email-confirmation',
            name: ROUTES.EmailConfirmation,
            component: EmailConfirmation,
            props: true
        },
        {
            path: '/forgot-password',
            name: ROUTES.ForgotPassword,
            component: ForgotPassword
        },
        {
            path: '/reset-password',
            name: ROUTES.ResetPassword,
            component: ResetPassword
        },
        {
            path: '/create-password',
            name: 'CreatePassword',
            component: CreatePassword,
            props: true,
            meta: {permissions: {}}
        },
        {
            path: '',
            component: Layout,
            redirect: '/dashboard',
            meta: {emailVerified: true},
            children: [
                {
                    path: '/dashboard',
                    name: ROUTES.Dashboard,
                    component: Dashboard,
                    meta: {permissions: {}},
                },
                {
                    path: '/details',
                    name: ROUTES.RegisterDetails,
                    component: RegisterDetails,
                    meta: {permissions: {}},
                },
                {
                    path: '/payouts',
                    name: ROUTES.Payouts,
                    component: Payouts,
                    meta: {permissions: {}},
                },
                {
                    path: '/personal-info',
                    name: ROUTES.PersonalInformation,
                    component: ProfileSettings,
                    meta: {permissions: {}},
                },
                {
                    path: '/notifications',
                    name: ROUTES.NotificationList,
                    component: NotificationsList,
                    meta: {permissions: {notifications_read: true}},
                    props: true
                },
                {
                    path: '/customer-support',
                    name: ROUTES.CustomerSupportCasesRoot,
                    component: CustomerSupportCasesRoot,
                    children: [
                        {
                            path: '',
                            name: ROUTES.CustomerSupportCases,
                            component: CustomerSupportCases,
                            meta: {permissions: {}},
                            props: true
                        },
                        {
                            path: ':csSupportRequestExternalId',
                            name: ROUTES.CustomerSupportCaseManage,
                            component: CustomerSupportCaseManage,
                            meta: {permissions: {}},
                            props: true
                        }
                    ]
                },
            ]
        }
    ]
}
