<template>
    <div class="w-100">
        <div class="form-container">
            <BaseInputString
                v-model="formData.linkLinkedIn"
                :error="errors.linkLinkedIn"
                :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'linkedin'
                           }
                         }]"
                :placeholder="t('affiliate.registerDetails.enterUrl')"
                class="w-100 mb-3"/>
            <BaseInputString
                v-model="formData.linkTwitter"
                :error="errors.linkTwitter"
                :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'twitter'
                           }
                         }]"
                :placeholder="t('affiliate.registerDetails.enterUrl')"
                class="w-100 mb-3"/>
            <BaseInputString
                v-model="formData.linkFacebook"
                :error="errors.linkFacebook"
                :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'facebook'
                           }
                         }]"
                :placeholder="t('affiliate.registerDetails.enterUrl')"
                class="w-100 mb-3"/>
            <BaseInputString
                v-model="formData.linkInstagram"
                :error="errors.linkInstagram"
                :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'instagram'
                           }
                         }]"
                :placeholder="t('affiliate.registerDetails.enterUrl')"
                class="w-100 mb-3"/>
            <BaseInputString
                v-model="formData.linkOther"
                :error="errors.linkOther"
                :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'dots-filled'
                           }
                         }]"
                :placeholder="t('affiliate.registerDetails.other')"
                class="w-100 mb-3"/>
        </div>
        <div class="d-flex mt-3 justify-content-end">
            <BaseButton @click="onSave" :loading="loadingSave">
                {{ t('common.update') }}
            </BaseButton>
        </div>
    </div>
</template>
<script setup>

import {ref, onMounted, shallowRef, onBeforeMount, onBeforeUnmount, watch, computed} from "vue";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useUserStore} from "Common/stores/userStore";
import BasePreloader from "Common/components/controls/BasePreloader";
import BaseInputString from "Common/components/controls/BaseInputString";
import BaseButton from "Common/components/controls/BaseButton";
import Icon from "Common/components/svg";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import {createFormErrors} from "Common/utility/formErrors";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";

const {t, te, locale} = useI18n({useScope: 'global'});
const userStore = useUserStore();
const organizationStore = useOrganizationStore();
const affiliationStore = useAffiliationStore();
const badgeNotify = useBadgeNotify();
const loading = ref(true);
const loadingSave = ref(false);
const route = useRoute();

const formData = ref({});

const {errors, showHiddenErrors, addErrors} = createFormErrors(t, te, formData, () => {
    const newErrors = {};
    //if(formData.value.reason.length > 500) newErrors.reason = 'invalid_length';
    return newErrors;
}, {});

onMounted(() => {
    if(!affiliationStore.affiliateUserProfile){
        affiliationStore.loadAffiliateUserProfile().then((data) => {
            formData.value = data;
        }).catch((err) => {
            console.log(err);
            badgeNotify.show({
                test: t("common.errorList.sorrySomethingWrong"),
                error: true
            });
        }).finally(() => {
            loading.value = false;
        });
    }
    else{
        formData.value = affiliationStore.affiliateUserProfile;
        loading.value = false;
    }
})

function onSave() {
    if (showHiddenErrors()) return;
    loadingSave.value = true;

    affiliationStore.updateAffiliateUserProfile(formData.value).then(() => {
        affiliationStore.affiliateUserProfile = {...formData.value} ?? {};
        badgeNotify.show({
            text: t('user_profile.update_success'),
            success: true
        });
    }).catch((err) => {
        console.log(err);
        let text = t('user_profile.update_error');
        badgeNotify.show({
            text,
            error: true
        });
    }).finally(() => {
        loadingSave.value = false;
    })
}

function onPaymentPolicyPage() {
    router.push({name: ROUTES.AffiliatePaymentPolicy});
}

</script>

<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';

.form-container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
}
</style>
