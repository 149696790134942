<template>
  <div class="tooltip-wrapper">
    <slot />
    <span class="tooltip__arrow"></span>
    <span class="tooltip__text">{{ text }}</span>
  </div>
</template>
<script>
  export default {
    props: {
      text: {
        type: String,
        required: true
      }
    }
  }
</script>

<style>
.tooltip__text {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;

  color: #45557D;
  text-align: center;
  padding: 12px 18px;
  border-radius: 12px;
  background: #F6F6F8;
  border: 1px solid #A3AED0;
  white-space: nowrap;

  position: absolute;
  z-index: 1080;

  top: 200%;
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-wrapper:hover .tooltip__text{
  visibility: visible;
  opacity: 1;
}

.tooltip__text::after {
  content: url("data:image/svg+xml, %3Csvg height='17' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,18 10,0 20,18' style='fill:%23F6F6F8;stroke:%23A3AED0;stroke-width:1' /%3E%3C/svg%3E ");
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translate(-50%, 0);
}


</style>
