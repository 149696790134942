
export {
    getApplicationTypeColor
}

const getApplicationTypeColor = (appType, typeIndex = 0) => {
    appType = applicationTypeColors[appType] ? appType : '_NEW_';
    
    const colorGroup = applicationTypeColors[appType];
    return colorGroup[typeIndex % colorGroup.length];
}

const applicationTypeColors = {
    CAL: ['#4A148C', '#6A1B9A', '#7B1FA2', '#8E24AA', '#9C27B0', '#AB47BC'],
    CCS: ['#311B92', '#4527A0', '#512DA8', '#5E35B1', '#673AB7', '#7E57C2'],
    CCC: ['#1A237E', '#283593', '#303F9F', '#3949AB', '#3F51B5', '#5C6BC0'],
    CRQ: ['#880E4F', '#AD1457', '#C2185B', '#D81B60', '#E91E63', '#EC407A'],
    HRP: ['#004D40', '#00695C', '#00796B', '#00897B', '#009688', '#26A69A'],
    RB1: ['#1B5E20', '#2E7D32', '#388E3C', '#43A047', '#4CAF50', '#66BB6A'],
    RB2: ['#33691E', '#558B2F', '#689F38', '#7CB342', '#8BC34A', '#9CCC65'],
    PSN: ['#263238', '#37474F', '#455A64', '#546E7A', '#607D8B', '#78909C'],
    INV: ['#E65100', '#EF6C00', '#F57C00', '#FB8C00', '#FF9800', '#FFA726'],
    FUN: ['#01579B', '#0277BD', '#0288D1', '#039BE5', '#03A9F4', '#29B6F6'],
    LOV: ['#827717', '#9E9D24', '#AFB42B', '#C0CA33', '#CDDC39', '#D4E157'],
    ACD: ['#B71C1C', '#C62828', '#D32F2F', '#E53935', '#F44336', '#FF7043'],
    NMS: ['#0D47A1', '#1565C0', '#1976D2', '#1E88E5', '#2196F3', '#42A5F5'],
    BAS: ['#006064', '#00838F', '#0097A7', '#00ACC1', '#00BCD4', '#26C6DA'],
    LVA: ['#F57F17', '#F9A825', '#FBC02D', '#FDD835', '#FFEB3B', '#FFEE58'],
    QA7: ['#FF6F00', '#FF8F00', '#FFA000', '#FFB300', '#FFC107', '#FFCA28'],
    HRM: ['#BF360C', '#D84315', '#E64A19', '#F4511E', '#FF5722', '#FF7043'],

    _NEW_: ['#3E2723', '#4E342E', '#5D4037', '#6D4C41', '#795548', '#8D6E63'],
}