<template>
  <div class="base-btn" :class="className" @click="onClick">
    <BasePreloader :show="loading" />
    <slot></slot>
  </div>
</template>

<script>

import BasePreloader from 'Common/components/controls/BasePreloader';

export default {
  components: {BasePreloader},
  name: 'BaseButton',
  props: {
    loading: Boolean,
    outline: Boolean,
    add: Boolean,
    white: Boolean,
    icon: Boolean,
    disabled: Boolean,
    middleSize: Boolean,
  },
  emits: ['click'],
  computed: {
    className(){
      let classes = "";
      if(this.outline) classes += ' base-btn-outline';
      if(this.add) classes += ' base-btn-add';
      if(this.white) classes += ' base-btn-white';
      if(this.icon) classes += ' base-btn-icon';
      if(this.disabled || this.loading) classes += ' disabled';
      if(this.middleSize) classes += ' base-btn-middle-size';
      return classes;
    }
  },
  methods: {
    onClick(e){
      this.$emit('click', e);
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-btn{
    
  }
</style>
