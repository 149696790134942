<template>
    <div :class="$style.root">
        <div :class="[$style.title, $style.titleSpace]">
            {{t('common.promotionInformation')}}
            <span :class="[$style.text]">{{t('affiliate.registerDetails.optional')}}</span>
        </div>
        <div :class="[$style.text, $style.textSpace]">{{t('affiliate.registerDetails.step4Text')}}</div>
        <div :class="$style['description-text']">
            {{t('affiliate.registerDetails.iWantToPromoteText')}}
        </div>
        <div :class="$style['dropdown-title']">
            {{t('affiliate.registerDetails.iWantToPromote')}}
        </div>
        <DropdownWithCheckbox
            v-model="formData.promotes"
            :options="promoteOptions"
            :error="errors.promotes"
            class="w-100 mb-3"
        />
        <div :class="$style['description-text']">
            {{t('affiliate.registerDetails.targetAudienceText')}}
        </div>
        <div :class="$style['dropdown-title']">
            {{t('affiliate.registerDetails.targetAudience')}}
        </div>
        <DropdownWithCheckbox
            v-model="formData.promotionTargets"
            :options="promotionTargetOptions"
            :error="errors.promotionTargets"
            class="w-100 mb-3"
        />
        
        <div class="d-flex flex-row gap-2 mb-3 w-100">
            <div class="w-100">
                <BaseButton class="w-100" outline @click="onBack">
                    {{t('common.back')}}
                </BaseButton>
            </div>
            <div class="w-100">
                <BaseButton class="w-100" :loading="loadingSave" @click="onSave">
                    {{t('common.next')}}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

import DropdownWithCheckbox from "Common/components/controls/DropdownWithCheckbox";
import BaseButton from "Common/components/controls/BaseButton";
import {createFormErrors} from "Common/utility/formErrors";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";



const {t, te} = useI18n({useScope: 'global'});
const affiliationStore = useAffiliationStore();
const badgeNotify = useBadgeNotify();
const emit = defineEmits(['next', 'back']);
const loading = ref(true);
const loadingSave = ref(false);

const formData = ref({});

const promoteOptions = ref([
    {value: 'contactCenterApps', label: t('affiliate.registerDetails.promoteOptions.contactCenterApps')},
    {value: 'hrApps', label: t('affiliate.registerDetails.promoteOptions.hrApps')},
    {value: 'riskAssessmentApps', label: t('affiliate.registerDetails.promoteOptions.riskAssessmentApps')},
    {value: 'vrAndBotsApps', label: t('affiliate.registerDetails.promoteOptions.vrAndBotsApps')},
    {value: 'psychoMedicalEvaluationApps', label: t('affiliate.registerDetails.promoteOptions.psychoMedicalEvaluationApps')},
    {value: 'entertainmentApps', label: t('affiliate.registerDetails.promoteOptions.entertainmentApps')},
    {value: 'matchmakingApps', label: t('affiliate.registerDetails.promoteOptions.matchmakingApps')},
    {value: 'other', label: t('affiliate.registerDetails.promoteOptions.other')},
])

const promotionTargetOptions = ref([
    {value: 'entrepreneurs', label: t('affiliate.registerDetails.promotionTargetOptions.entrepreneurs')},
    {value: 'developers', label: t('affiliate.registerDetails.promotionTargetOptions.developers')},
    {value: 'productManagers', label: t('affiliate.registerDetails.promotionTargetOptions.productManagers')},
    {value: 'innovationManagers', label: t('affiliate.registerDetails.promotionTargetOptions.innovationManagers')},
    {value: 'cLevelManagement', label: t('affiliate.registerDetails.promotionTargetOptions.cLevelManagement')},
    {value: 'other', label: t('affiliate.registerDetails.promotionTargetOptions.other')},
])

const {errors, showHiddenErrors, addErrors} = createFormErrors(t, te, formData, () => {
    const newErrors = {};
    //if(formData.value.promotes.length === 0) newErrors.promotes = 'required';
    //if(formData.value.promotionTargets.length === 0) newErrors.promotionTargets = 'required';
    return newErrors;
});


onMounted(() => {
    if(!affiliationStore.affiliateUserProfile){
        affiliationStore.loadAffiliateUserProfile().then((data) => {
            formData.value = data;
        }).catch((err) => {
            console.log(err);
            badgeNotify.show({
                test: t("common.errorList.sorrySomethingWrong"),
                error: true
            });
        }).finally(() => {
            loading.value = false;
        });
    }
    else{
        formData.value = affiliationStore.affiliateUserProfile;
        loading.value = false;
    }
})

function onBack() {

    emit('back')
}

function onSave() {
    if (showHiddenErrors()) return;

    loadingSave.value = true;

    affiliationStore.updateAffiliateUserProfile(formData.value).then(() => {
        affiliationStore.affiliateUserProfile = {...formData.value} ?? {};
        emit('next')
    }).catch((err) => {
        console.log(err);
    }).finally(() => {
        loadingSave.value = false;
    })
}


</script>

<style lang="scss" module>
@import "./steps-style.scss";

.root{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    width: 404px;
}
.option-checkbox{
    pointer-events: none;
    margin: 0 12px 0 12px;
}
.description-text{
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    margin-bottom: 16px;
}
.dropdown-title{
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    margin-bottom: 12px;
}

body.rtl {
    .description-text{
        text-align: right;
    }
    .dropdown-title{
        text-align: right;
    }
}
</style>
