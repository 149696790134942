const comparePaths = (path, pathToCompare) => {
    if (!pathToCompare) {
        return false
    }
    return preparePathToCompare(path) === preparePathToCompare(pathToCompare)
}

const preparePathToCompare = (path) => {
    if (path[0] === '/') {
        path.slice(1)
    }

    if (path[path.length - 1] === '/') {
        path.slice(0, -1)
    }

    return path
}

export { comparePaths }
