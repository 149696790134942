<template>
  <div>
    <slot
        name="mobile.header.topActions"
        :rowsData="rowsData"
    ></slot>
    <div class="base-table-mobile">
      <div v-if="!rowsData?.length" class="position-relative">
          <div class="position-relative">
            <BasePreloader :show="loading"/>
            <template v-if="!loading && !rowsData?.length">
              <slot name="noData"></slot>
            </template>
          </div>
      </div>
      <div v-else class="base-table-mobile-container position-relative">
        <div :key="key" 
             v-for="(row, key) in rowsData" 
             @click="$emit('rowClicked', {key, row})" 
             :style="row['_style']"
             class="base-table-mobile-item"
             :class="row['_class']"
        >
          <template v-if="headers.filter(h => h.mobile?.title).length > 0">
            <slot :name="'mobile._title'" :row="row" :headers="headers.filter(h => h.mobile?.title)">
              <div class="base-table-th">
                {{row[headers[0].fieldName]}}
              </div>
            </slot>
          </template>
          
          <template :key="header" v-for="header in headers.filter(h => h.mobile?.visible !== false)">
            <slot :name="'mobile.'+header.fieldName" :row="row" :header="header">
              <div class="base-table-tr">
                <div>{{header.label}}</div>
                <slot :name="'mobile.'+header.fieldName+'.value'" :row="row" :header="header">
                  <div class="d-flex flex-row align-items-center"
                       :class="{'comparison-color': header.comparison && header.comparison.useComparisonColor}"
                  >
                    <div class="base-table-tr-value">{{row[header.fieldName]}}</div>
                    <div v-if="header.comparison && getComparisonData(row, header).type !== ''" class="comparison-icon"
                         :class="{[getComparisonData(row, header).direction]: true, [getComparisonData(row, header).type]: true}">
                      <Icon v-if="getComparisonData(row, header).compareIconName"
                            :name="getComparisonData(row, header).compareIconName"/>
                      <div v-else style="width: 24px; height: 24px;"></div>
                    </div>
                  </div>
                </slot>
              </div>
            </slot>
          </template>
        </div>
      </div>
      <BaseTablePagination v-if="pagination" class="my-3" :total-rows="paginationTotalRows || (rows || []).length"
                           v-model:data-filter="filter"/>
    </div>
  </div>
</template>


<script setup>
import Icon from "Common/components/svg";
import BaseTablePagination from "Common/components/table/BaseTablePagination";
import BaseCheckbox from "Common/components/controls/BaseCheckbox";
import BasePreloader from "Common/components/controls/BasePreloader";

import {init, propsDefinitions, emitDefinitions} from "Common/components/table/baseTable/BaseTableController";

const props = defineProps(propsDefinitions);
const emit = defineEmits(emitDefinitions);

const {
  rowsData,
  rowsDataForExport,
  filter,
  checkAll,
  filterParams,
  changeColumnSort,
  getFieldOrderByName,
  getComparisonData
} = init(props, emit);

</script>


<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';
.base-table-mobile{
  display: flex;
  flex-direction: column;
}
.base-table-mobile-container{
  display: grid;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  gap: 24px;
}
.base-table-mobile-item{
  display: flex;
  flex-direction: column;
}
.base-table-th{
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #2A385D;
}
.base-table-tr{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  padding: 9px 0;
  border-bottom: 1px solid #A3AED0;
  
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #45557D;
}
.base-table-tr-value{
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #2A385D;
}
.comparison-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.up{
    transform: rotate(0deg);
  }
  &.down{
    transform: rotate(180deg);
  }
}
.comparison-color .comparison-icon{
  &.better{
    color: $c-green-1;
  }
  &.worse{
    color: $c-red-accent;
  }
}

@media (max-width: 767px){ //375 - 768
  .base-table-mobile-container{
    grid-template-columns: 100%;
  }
}

body.rtl {
  .base-table-th{
    text-align: right;
  }
  .base-table-tr{
    text-align: right;
  }
  .base-table-tr-value{
    text-align: right;
  }
}
</style>
