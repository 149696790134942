<template>
    <div>
        <Breadcrumbs :items="breadcrumbs" />

        <div class="root">
            <div class="d-flex flex-row justify-content-between align-items-center mb-4">
                <div class="title">{{ t('common.payouts') }}</div>
                <div class="d-flex flex-row gap-2">
                    <BaseSearch top-filter-style v-model="dataFilter.query" :placeholder="t('common.search')"/>
                </div>
            </div>


            <BaseTable
                async-data
                :headers="grid_heads"
                :rows="grid_rows"
                v-model:data-filter="dataFilter"
                @loadData="loadData"
                :searchable-fields="['activity_id']"
                pagination
                :pagination-total-rows="totalRows"
            >
                <template #name="{row}">
                    <div class="d-flex">

                    </div>
                </template>
                <template #time_processed="{row}">
                    <div>{{ formatDate(row.time_processed) }}</div>
                </template>
                <template #amount="{row}">
                    <div>${{ row.amount }}</div>
                </template>
                <template #fee_amount="{row}">
                    <div>${{ row.fee_amount }}</div>
                </template>
                <template #_totalPayout="{row}">
                    <div>${{ row.amount + row.fee_amount }}</div>
                </template>
                <template #activity_id="{row}">
                    <div class="invoice-url"><a :href="affiliationStore.getPayPalPayoutInvoiceUrl(row.activity_id)" class="link-btn">{{ affiliationStore.getPayPalPayoutInvoiceUrl(row.activity_id) }}</a></div>
                </template>
            </BaseTable>
        </div>
    </div>
</template>

<script setup>

import Breadcrumbs from "Common/components/controls/breadcrumbs";
import {ref, computed} from "vue";
import Icon from "Common/components/svg";
import BaseTable from "Common/components/table/BaseTable";
import BaseSearch from "Common/components/controls/BaseSearch";

import {useUserStore} from "Common/stores/userStore";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import { useI18n } from 'vue-i18n';
import {useOrganizationDateFormat} from "Common/utility/dateTimeUtility";

const userStore = useUserStore();
const affiliationStore = useAffiliationStore();
const {formatDate} = useOrganizationDateFormat();

const { t, locale } = useI18n({ useScope: 'global' });

const breadcrumbs = [{
    "name": t('common.payouts'),
    "link": "#",
    "active": false
}];

const dataFilter = ref({query: ''});
const grid_rows = ref([]);

const totalRows = ref(0);

const grid_heads = computed(() => [
    {sortable: true, label: t('common.date'), fieldName: 'time_processed'},
    {sortable: true, label: t('common.payoutSum'), fieldName: 'amount'},
    {sortable: true, label: t('common.taxWithholding'), fieldName: 'fee_amount'},
    {sortable: false, label: t('common.totalPayout'), fieldName: '_totalPayout'},
    {sortable: false, label: t('common.invoiceId'), fieldName: 'activity_id'},
    {sortable: true, label: t('common.paymentMethod'), fieldName: 'recipient_wallet'},
]);

function loadData(filter, params) {
    affiliationStore.searchAffiliatePayout(filter, params).then((data) => {
        totalRows.value = data.total;
        grid_rows.value = data.items;
    }).catch((err) => {
        console.log(err);
    });
}


</script>

<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';

.root {
    border: 1px solid $c-text-secondary;
    border-radius: 12px;
    padding: 16px 24px 0 24px;
    background-color: $c-white;
}
.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $c-text-primary;
}
.invoice-url{
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>

