import {languages} from '@/locales/index.js'
import * as GeneralDefinitions from './_exportDefinitions.js'

export * from './_exportComponents.js'
export * from './_exportDefinitions.js'

const allDefinitions = {
  ...GeneralDefinitions,
}

const Locale = languages.en;
const Locales = languages;

export {
  Locale,
  Locales,
  registerAllComponents,
  setProps
}

window.NMSDIAMOND = {
  setProps,
  registerAllComponents,
  ...allDefinitions,
}

function registerAllComponents() {
  for(const key in allDefinitions){
    try{
      allDefinitions[key]();
    }
    catch (e) {}
  }
}

function setProps(selector, props) {
  const element = document.querySelector(selector);
  if(!element) return 'element not found';
  if(typeof props !== "object") return 'props must be an object';
  for(let key in props){
    if(props[key] === undefined) continue;
    let propName = camelCaseToKebabCase(key);
    let propValue = props[key];
    if(typeof propValue !== "string") propValue = JSON.stringify(propValue);

    element.setAttribute(propName, propValue)
  }
}

function camelCaseToKebabCase(str) {
  return str.replace(/[A-Z][a-z]*/g, str => '-' + str.toLowerCase())
      .replace('--', '-') // remove double hyphens
      .replace(/(^-)|(-$)/g, ''); // remove hyphens at the beginning and the end
}