<template>
  <div class="cs-ticket-messages">
    <div class="d-flex flex-row justify-content-between align-items-center mb-4">
      <div>
        <div class="subtitle">{{ ticket?.subject }}</div>
        <div class="d-flex" v-if="isAdmin">
          <div class="font-weight-bold me-2">{{ t("common.client") }}</div>
          <div
              v-if="ticket?.organization"
              class="link-btn"
              @click="viewOrganization(ticket)">
            {{ ticket?.organization?.name }}
          </div>
        </div>

      </div>
      <div class="d-flex flex-row">
        <BaseButton
            add
            outline
            :loading="loadingClose"
            :disabled="customerSupportStore.currentCase.status === CaseStatus.closed || savingNewItem"
            class="mr-2"
            @click="closeCase">
          {{ t('common.closeCase') }}
          <icon-minus-outline/>
        </BaseButton>
        <BaseButton
            :disabled="customerSupportStore.currentCase.status === CaseStatus.closed || savingNewItem || isReply"
            @click="onReply"
        >
          {{ t('common.reply') }}
        </BaseButton>
      </div>
    </div>

    <BaseTable
        :headers="grid_heads"
        :rows="filteredRows"
        class="ticket-table-panel"
        :searchableFields="['topic']"
    >
      <template #_index="{row}">
        <div class="d-flex justify-content-center"
             :class="{'c-secondary': row.status === CaseStatus.closed}">
          {{ row._index > -1 ? row._index : "" }}
        </div>
      </template>

      <template #user="{row}">
        {{ row.userProfile?.firstName }}
        {{ row.userProfile?.lastName }}
      </template>
      <template #createDate="{row}">
        <div :class="{'c-secondary': row.status === CaseStatus.closed}">
          {{ row.createDate }}
        </div>
      </template>
      <template #topic="{row}">
        {{ row.topic ? t('common.' + row.topic) : '' }}
      </template>
      <template #body="{row}">
        <template v-if="row._index < 0">
          <div>
            <BaseTextarea
                :name="t('common.message')"
                :rows="4"
                :is-required="true"
                :is-resizable="true"
                v-model="newItem.body"
                :max-length="5000"
                :error="errors.body"/>

            <div class="base-input-container flex-column align-items-sm-start mt-2">
              <input ref="file" type="file"/>
              <div>{{ t("common.attachmentSizeLimit", {sizeMb: 25}) }}</div>
            </div>
          </div>
          <div>
            <div class="d-flex justify-content-end mb-3">
              <BaseButton :loading="savingNewItem" class="mr-3" @click="createNewItem">
                {{ t('common.submit') }}
              </BaseButton>
              <BaseButton :disabled="savingNewItem" outline @click="closeNewItem">
                {{ t('common.cancel') }}
              </BaseButton>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="d-flex flex-row">
            <div v-if="row.type === 'answer'" class="mr-2">
              <icon-arrow-forward class="icon"/>
            </div>
            <div v-else-if="row.type === 'support'" class="mr-2">
              <span style="color: #A3AED0">Support:</span>
            </div>
            <div class="rowbody" v-html="row.body"></div>
          </div>
          <div v-if="row.attachmentFileName" class="mt-1 d-flex">
            <span>{{ t("common.attachment") }}:</span>
            <a href="#" @click="downloadAttachment(row)" class="d-flex align-items-center">
              <img alt="" src="~/Common/assets/icons/link.png" style="filter: invert(100%);" class="mx-1"/>
              {{ row.attachmentFileName }}
            </a>
          </div>
        </template>
      </template>
    </BaseTable>

  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {computed, shallowRef, toRef, watch, ref, unref} from "vue";
import {useCustomerSupportStore} from "Common/stores/customerSupportStore";
import {useUserStore} from "Common/stores/userStore";
import BaseButton from "Common/components/controls/BaseButton";
import BaseTable from "Common/components/table/BaseTable";
import {useOrganizationDateFormat} from "Common/utility/dateTimeUtility";
import {CaseStatus} from "Common/types/customer_support_type";
import IconArrowForward from "Common/assets/icons/arrow-forward.svg?inline"
import IconMinusOutline from "Common/assets/icons/minus-outline.svg?inline"
import {useBadgeNotify} from "Common/stores/badgeNotify";
import {createFormErrors} from "Common/utility/formErrors";
import BaseTextarea from "Common/components/controls/BaseTextarea";
import {htmlEntities} from "Common/components/charts/htmlUtility";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";
import {TableActionModalTypes} from "Common/types/table_action_modal_type";
import {useTableActions} from "Common/stores/tableActions";

const file = shallowRef(null);

const props = defineProps({
  csSupportRequestExternalId: {
    type: String,
    default: null,
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  ticket: {
    type: Object,
    default: () => {}
  }
});

const emit = defineEmits(['openCase']);

const userStore = useUserStore();
const customerSupportStore = useCustomerSupportStore();
const tableActions = useTableActions()
const {formatDateTime} = useOrganizationDateFormat();
const {t, te} = useI18n({useScope: 'global'});
const badgeNotify = useBadgeNotify();
const newItem = ref({});
const savingNewItem = shallowRef(false);
const loadingClose = shallowRef(false);
const isReply = ref(false)

const {errors, showHiddenErrors, removeErrors, removeHiddenErrors} = createFormErrors(t, te, newItem, null, {
  requiredFields: ["body"]
});


const grid_heads = computed(() => [
  {sortable: false, label: '#', class: 'base-table-th-sm', fieldName: '_index'},
  {sortable: false, label: t('common.date'), class: 'base-table-th-w-datetime', fieldName: 'createDate'},
  {sortable: false, label: t('common.user'), class: 'base-table-th-w-datetime', fieldName: 'user'},
  {sortable: false, label: t('common.topic'), class: 'base-table-th-w-datetime', fieldName: 'topic'},
  {sortable: false, label: t('common.message'), fieldName: 'body'},
]);

const filteredRows = computed(() => {
  const rows = (customerSupportStore.currentCase.messages || []).map((i, idx) => {
    const row = {
      ...i,
      topic: customerSupportStore.currentCase.topic,
      createDate: i.createDate ? formatDateTime(i.createDate) : ''
    };

    if (customerSupportStore.currentCase.messages.length - 1 === idx) {
      row.type = 'initial'
    } else {
      row.type = customerSupportStore.currentCase.userProfile?.externalId === i.userProfile?.externalId ? 'answer' : 'support';
    }

    row.body = formatMessageHtml(row.body);

    return row
  });


  return [...(newItem.value._index ? [newItem.value] : []), ...rows];
});


watch(newItem, n => {
    if (n?._index) {
        customerSupportStore.saveDraft(props.csSupportRequestExternalId, {body: n?.body || ''});
    }
}, {
    deep: true
});


function closeTicketHandler() {
  customerSupportStore.closeCustomerSupportCase({
    csSupportRequestExternalId: customerSupportStore.currentCase.csSupportRequestExternalId,
    status: CaseStatus.closed,
  }).then(() => {
    customerSupportStore.clearDraft(props.csSupportRequestExternalId);
    customerSupportStore.currentCase.status = CaseStatus.closed;
  }).catch((err) => {
    badgeNotify.show({
      text: t("common.errorList.sorrySomethingWrong"),
      error: true,
    });
  }).finally(() => {
    tableActions.close()
    customerSupportStore.getCustomerSupportStats().catch((err) => {
      console.log(err);
    });
    router.push({name: ROUTES.CustomerSupportCases})
  })
}

function closeCase() {
  const tableActions = useTableActions();
  const options = {
    title: t('customerSupport.closeTicket') || '',
    text: t('customerSupport.closeTicketModalText') || '',
    modalType: TableActionModalTypes.ApproveWithoutUnderstand,
  };
  tableActions.showApproveModal(options, closeTicketHandler);
}

function viewOrganization(row) {
  const route = router.resolve({
    name: ROUTES.AdminOrganizationDetails,
    params: {organizationExternalId: row.organization.organizationExternalId}
  });
  window.open(route.href, '_blank');
}

function messageCreated(messageData) {
  customerSupportStore.currentCase.messages.unshift({
    _index: customerSupportStore.currentCase.messages.length + 1,
    createDate: new Date(),
    body: messageData.body,
    topic: customerSupportStore.currentCase?.topic || '',
    attachmentFileName: messageData.attachmentFileName,
    csSupportRequestMessageExternalId: messageData.csSupportRequestMessageExternalId,
    userProfile: {
      externalId: userStore.user?.externalId,
      firstName: userStore.user?.firstName,
      lastName: userStore.user?.lastName,
    }
  })
}

function onReply() {
  isReply.value = true
  removeErrors("requiredReactive");

  newItem.value._index = -1;
  newItem.value.createDate = formatDateTime(new Date());
  newItem.value.body = customerSupportStore.loadDraft(props.csSupportRequestExternalId)?.body || '';
  newItem.value.attachmentFileName = '';
  newItem.value.topic = customerSupportStore.currentCase?.topic || '';
  newItem.value.csSupportRequestMessageExternalId = '';
  newItem.value.userProfile = {
    externalId: userStore.user?.externalId,
    firstName: userStore.user?.firstName,
    lastName: userStore.user?.lastName,
  };
}

function downloadAttachment(row) {
  customerSupportStore.downloadAttachment(row);
}

function createNewItem() {

  if (showHiddenErrors())
    return;

  //errorsObj.removeErrors();

  const fileField = unref(file.value);

  savingNewItem.value = true;

  customerSupportStore.insertCustomerSupportCaseMessage({
    ...newItem.value,
    csSupportRequestExternalId: props.csSupportRequestExternalId,
  }, fileField?.files[0]).then(data => {

    const createdObj = {...newItem.value, ...data};

    messageCreated(createdObj);
    closeNewItem();

  }).catch((err) => {

    badgeNotify.show({
      error: true,
      text: err?.errorCode ? t(`common.errorList.${err.errorCode}`) : t(`common.errorList.sorrySomethingWrong`)
    });

    console.log(err);
  }).finally(() => {
    isReply.value = false
    savingNewItem.value = false;
    customerSupportStore.getCustomerSupportStats().catch((err) => {
      console.log(err);
    });
  });
}

function closeNewItem() {
  isReply.value = false
  newItem.value._index = null;
  customerSupportStore.clearDraft(props.csSupportRequestExternalId);
}

function formatMessageHtml(t) {
  const linkRegExp = /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig;
  const links = [];
  let linkId = new Date().getTime();

  const noLinks = String(t).replace(linkRegExp, (s, a) => {
    const link = {id: `[[link-${linkId.toString(32)}]]`, url: s};
    links.push(link);
    return link.id;
  });

  const encoded = htmlEntities(noLinks).replace(/\r?\n|\r/g, "<br/>");

  return links.reduce((t, {id, url}) => {
    const re = new RegExp(escapeRegex(id));
    return t.replace(re, `<a href="${encodeURI(url)}" target="_blank">${htmlEntities(url)}</a>`);
  }, encoded);

}


function escapeRegex(s) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}
</script>

<style lang="scss">
.cs-ticket-messages {
  @import '~Common/assets/styles/themes/light/light-theme-colors.scss';

  td {
    vertical-align: top;

  }

  .icon {
    fill: $c-blue-accent;
  }

  .subtitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
  }

  .rowbody{
    word-wrap: break-word;
    white-space: break-spaces;
    width: 100%;
  }

  table{
    table-layout: fixed;
  }
}

</style>
