import {getApplicationTypeColor} from "@/utility/applicationTypeUtility";

export default function (data) {
    const settings = {};
    
    data = data.map((item) => {
        return {
            value: item.totalCount,
            name: item.name,
            itemStyle: {
                color: getApplicationTypeColor(item.applicationType, 0),
            }
        }
    })

    settings.tooltip = {
        trigger: 'item',
        padding: [4,8,4,8],
        formatter
    };
    settings.series = [
        {
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '90%'],

            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 3
            },
            emphasis: {
                label: {
                    show: false,
                }
            },
            labelLine: {
                show: false
            },
            data: data
        }
    ];

    return settings;
}

function formatter(params) {
    return `
    <div class="d-flex flex-column" style="width: min-content; white-space: pre-wrap;">
        <div style="font-family: 'DM Sans'; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px;">${params.data.value}</div>
        <div style="font-family: 'DM Sans'; font-style: normal;font-weight: 400; font-size: 10px; line-height: 10px;">${params.data.name}</div>
    </div>`;
}