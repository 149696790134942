<template>
    <div :class="$style.root">
        <div class="d-flex flex-column align-items-center">
            <div :class="$style.stepper">
                <stepper :steps="4" :current-step="stepNumber"/>
            </div>
            <Step1 v-if="stepNumber === 1" @next="nextStep"/>
            <Step2 v-if="stepNumber === 2" @next="nextStep" @back="backStep"/>
            <Step3 v-if="stepNumber === 3" @next="nextStep" @back="backStep"/>
            <Step4 v-if="stepNumber === 4" @next="nextStep" @back="backStep"/>
        </div>
        <div class="d-flex justify-content-end">
            <a :class="$style.skipLink" href="javascript:void(0);" @click="skip">{{ t("onboarding.skipBoardingStage") }}</a>
        </div>
        <Welcome :show="showWelcomeModal" :close="closeWelcomeModal" />
        <AllDone :show="showAllDoneModal" :close="closeAllDoneModal" />
        <CompleteLater :show="showCompleteLaterModal" :close="closeCompleteLaterModal" />
    </div>
</template>

<script setup>

import {computed, onMounted, shallowRef, watch} from "vue";
import {useI18n} from "vue-i18n";
import {ROUTES} from "@/utility/routeUtils";
import router from "@/routes";
import {useRoute} from "vue-router";

import Welcome from "../modals/Welcome";
import AllDone from "../modals/AllDone";
import CompleteLater from "../modals/CompleteLater";
import Stepper from "Common/components/Stepper";
import {useBadgeNotify} from "Common/stores/badgeNotify";

import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";


const route = useRoute();
const badgeNotify = useBadgeNotify();
const {t, te} = useI18n({useScope: 'global'});

const loading = shallowRef(false);
const loaded = shallowRef(false);

const showWelcomeModal = shallowRef(true);
const showAllDoneModal = shallowRef(false);
const showCompleteLaterModal = shallowRef(false);

onMounted(() => {
    //loading.value = true;
});

const stepNumber = shallowRef(1);

function nextStep() {
    stepNumber.value += 1;
    if(stepNumber.value > 4){
        showAllDoneModal.value = true;
    }
}
function backStep() {
    stepNumber.value -= 1;
}

function skip() {
    showCompleteLaterModal.value = true;
}

function closeCompleteLaterModal() {
    showCompleteLaterModal.value = false;
    toCreateCouponPage();
}

function closeAllDoneModal() {
    showAllDoneModal.value = false;
    toCreateCouponPage();
}

function closeWelcomeModal() {
    showWelcomeModal.value = false;
}

function toCreateCouponPage() {
    router.push({name: ROUTES.Dashboard});
}

</script>

<style lang="scss" module>
@import "~Common/assets/styles/themes/light/light-theme-colors.scss";

.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    background-color: $c-white;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 0 24px 30px 24px;
    position: absolute;
}

.stepper {
    margin: 14px auto 14px;
    display: flex;
    justify-content: center;
}

.skipLink {
    position: absolute;
    bottom: 30px;
    right: 24px;

    font-weight: 700;
    font-size: 14px;

    color: $c-blue-accent;
}

body.rtl {
    .skipLink {
        left: 24px;
        right: unset;
    }
}
</style>