<template>
  <div class="main-bg-container wrap-768">
    <div class="main-container">
        <div class="main-form-container">
            <div class="second-bg-container">
                <div class="second-bg-doc-container">
                    <img src="~Common/assets/emlo-affiliate.png" alt=""/>
                </div>
            </div>
            <div class="main-input-group">
                <div class="main-input-group-title">{{ t('login.hello_title') }}</div>
                <div class="main-input-group-text">{{ t('login.hello_text') }}</div>
                <BaseInputString
                    :name="t('common.email')"
                    v-model="email"
                    class="mb-3"
                    :is-required="true"
                />
                <BaseInputPassword
                    :name="t('common.password')"
                    v-model="password"
                    class="mb-3"
                    :is-required="true"
                    :error="errorCode ? t(`login.errorList.${errorCode}`) : ''"
                    @keypress.enter="loginDashboard"
                >
                    <template #subNameContent>
                        <div class="link-btn forgot-password" @click="forgotPassword">{{ t('common.forgotPassword') }}</div>
                    </template>
                </BaseInputPassword>
                <div v-if="captchaRequired" class="d-flex justify-content-center my-2 recccc">
                    <vue-recaptcha
                        ref="recaptcha"
                        :sitekey="RECAPTCHA_SITE_KEY"
                        @verify="onCaptchaVerify"
                        @expired="captchaToken = ''"
                    />
                </div>
                <BaseButton class="w-100 mb-3"
                            @click="loginDashboard"
                            :loading="loadingLogin"
                            :disabled="captchaRequired && !captchaToken"
                >
                    {{ t('common.login') }}
                </BaseButton>
                <GoogleButton @authenticated="googleAuth" class="mb-3"/>
                <div class="d-flex align-items-center justify-content-center">
                    <router-link to="/register">
                        <div class="link-btn register-button">{{ t('common.registration') }}</div>
                    </router-link>
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script setup>

import router from "@/routes";
import {useUserStore} from 'Common/stores/userStore';
import BaseInputString from "Common/components/controls/BaseInputString";
import BaseInputPassword from "Common/components/controls/BaseInputPassword";
import BaseButton from "Common/components/controls/BaseButton";
import GoogleButton from "Common/components/controls/GoogleButton"
import {useI18n} from "vue-i18n";
import {ROUTES} from "@/utility/routeUtils";
import {onMounted, shallowRef} from "vue";
import {useRoute} from "vue-router";

import {RECAPTCHA_SITE_KEY, RECAPTCHA_ENABLED} from "@/utility/captchaUtils";
import { VueRecaptcha  } from 'vue-recaptcha'


const loading = shallowRef(false);
const loadingLogin = shallowRef(false);
const passwordResetMessage = shallowRef('');
const errorCode = shallowRef('');
const errors = shallowRef({});
const password = shallowRef(null);
const email = shallowRef('');
const code = shallowRef('');
const route = useRoute();
const captchaRequired = shallowRef(false);
const captchaToken = shallowRef('');
const recaptcha = shallowRef(null);

const userStore = useUserStore();
const {t, locale} = useI18n({useScope: 'global'});

onMounted(() => {
    // if there are "login with code" parameters in query string - try login
    const {email: emailValue, code} = route.query || {};
    if (emailValue && code) {
        email.value = emailValue;
        doLogin(emailValue, code);
    }
});


function loginDashboard() {
    doLogin(email.value, password.value);
}

function doLogin(email, password) {

    passwordResetMessage.value = '';
    errorCode.value = '';
    errors.value = {};
    loadingLogin.value = true;

    userStore.login({
        email,
        password,
        captchaToken: captchaToken.value,
    }).then(onLoginSuccess).catch(err => {
        captchaToken.value = '';
        recaptcha.value?.reset();

        errorCode.value = err.errorCode;
        captchaRequired.value = err?.extraData?.requiresCaptcha;
    }).finally(() => {
        loadingLogin.value = false;
    });

}

function onLoginSuccess() {
    if (userStore.tryGoToDestinationPath()) return;

    if (router.currentRoute.value?.query?.returnUrl) {
        // if there is returnUrl return user there
        router.push(router.currentRoute.value?.query?.returnUrl);
    } else {
        router.push({name: ROUTES.Dashboard});
    }
}

function forgotPassword() {
    router.push({name: ROUTES.ForgotPassword});
}
function onCaptchaVerify(r) {
    captchaToken.value = r;
}

function googleAuth() {
    if (!userStore.isNewUser) {
        router.push({name: ROUTES.Dashboard})
    } else {
        router.push({name: ROUTES.RegisterDetails})
    }
}
</script>

<style scoped lang="scss" src="Common/assets/styles/themes/light/auth.scss"></style>

<style scoped lang="scss">
.register-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
}

.forgot-password {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
}
</style>