<template>
    <div class="main-bg-container wrap-768">
      <div class="main-container">
        <div class="main-form-container">
          <div class="second-bg-container">
            <div class="second-bg-doc-container"><img src="~Common/assets/emlo-affiliate.png" alt=""/></div>
          </div>
          <div class="main-input-group">
            <div class="main-input-group-title">{{ t('resetPassword.title') }}</div>
            <div class="main-input-group-text">{{ t('resetPassword.text') }}</div>

            <BaseInputPassword :name="t('common.newPassword')"
                               v-model="formData.password"
                               :maxlength="'50'"
                               :is-required="true"
                               class="mb-3"
            />
            <BaseInputPassword :name="t('common.confirmPassword')"
                               v-model="formData.repeatPassword"
                               :is-required="true"
                               :maxlength="'50'"
                               :error="errorCode ? t(`resetPassword.errorList.${errorCode}`) : ''"
            />

            <BaseButton class="my-3 w-100" @click="resetPassword" :loading="loadingSending"
                        :disabled="!formData.password || !formData.repeatPassword">
              {{ t('common.update') }}
            </BaseButton>
          </div>
        </div>
        <div class="nemesysco-info-container">
        </div>
      </div>
    </div>
</template>

<script setup>
import {useUserStore} from "Common/stores/userStore";
import BaseInputPassword from "Common/components/controls/BaseInputPassword";
import BaseButton from "Common/components/controls/BaseButton";
import {useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import Icon from "Common/components/svg.vue";
import {useI18n} from "vue-i18n";

const userStore = useUserStore();
const {t} = useI18n({useScope: 'global'})
const router = useRouter();

const loadingSending = ref(false);
const errorCode = ref(null);
const formData = ref({
    password: null,
    repeatPassword: null,
    code: null,
    email: null,
})

onMounted(() => {
    formData.value = {
        ...formData.value,
        email: router.currentRoute.value.query.email,
        code: router.currentRoute.value.query.code,
    }

    if (!formData.value.code || !formData.value.email) {
        router.push("/")
    }
})

const resetPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    loadingSending.value = true;
    userStore.resetPassword({...formData.value})
        .then(() => {
            router.push('/login');
        })
        .catch((err) => {
            if (err.errorCode) {
                errorCode.value = err.errorCode;
            }
        })
        .finally(() => {
            loadingSending.value = false;
        });
}
</script>
<style scoped lang="scss" src="Common/assets/styles/themes/light/auth.scss"></style>