import {
    diamondHeadersDictionary,
    enumLvaOnlineData,
} from '@/utils/dictionary'
import {formatTime} from '@/utils/format-time.js'

const detectCurrentEmotionalStyle = (diamondData) => {
    const Emotional = diamondData[0].value
    const Uneasy = diamondData[1].value
    const Stressful = diamondData[2].value
    const Thoughtful = diamondData[3].value
    const Confident = diamondData[4].value
    const Concentrated = diamondData[5].value
    const Energetic = diamondData[6].value
    const Passionate = diamondData[7].value

    const arr = [
        {field: 'ENLO', value: Energetic + Confident + Concentrated},
        {field: 'ENEM', value: Energetic + Passionate + Emotional},
        {field: 'STEM', value: Emotional + Uneasy + Stressful},
        {field: 'STLO', value: Stressful + Thoughtful + Confident},
    ]

    let max = {field: '', value: 0}

    for (const elem of arr) {
        if (elem.value > max.value) {
            max = elem
        }
    }

    const emotionalStyle = {
        ENLO: 'Energetic Logical',
        ENEM: 'Energetic Emotional',
        STEM: 'Defensive Emotional',
        STLO: 'Defensive Logical',
    }

    return emotionalStyle[max.field]
}

const getMoods = (data, headersPositions) => {
    const elements = [
        {fieldId: headersPositions['aggression'], id: 'aggressive', average: 2},
        {fieldId: headersPositions['hesitation'], id: 'hesitant', average: 16.5},
        {fieldId: headersPositions['mentalEffort'], id: 'mental_effort', average: 15},
        {fieldId: headersPositions['overallCognitiveActivity'], id: 'upset', average: 1250},
    ]

    const allMoods = []
    for(const idx in data){
        const moods = [];
        if(idx < 3){
            allMoods.push([]); continue;
        }

        for (const el of elements) {
            const vals = [];
            for (let i = 1; i <= 3; i++) {
                const val = data[idx - i][el.fieldId];
                if(val !== null && val !== undefined){
                    vals.push(val);
                }
            }
            const average = vals.reduce((a, b) => a + b, 0) / vals.length;
            if (average > el.average) {
                moods.push(el.id);
            }
        }
        allMoods.push(moods);
    }

    return allMoods;
}

export const emloConverter = (content) => {
    const contentData = content.segments.data
    const contentHeadersPositions = content.segments.headersPositions

    const LVA_POS_INDEX = contentHeadersPositions['offlineLVA-value'] || contentHeadersPositions['onlineLVA'];

    const OVERALL_COGNITIVE_ACTIVITY_POS_INDEX = contentHeadersPositions.overallCognitiveActivity
    const STT_INDEX = contentHeadersPositions.speechToText
    const hasSpeechToText = !!contentHeadersPositions.speechToText

    const finallyContent = {}

    const moods = getMoods(contentData, contentHeadersPositions);

    let timeCodesOnVideoLine = [];

    for (const lineIdx in contentData) {
        const line = contentData[lineIdx]

        if (!line[contentHeadersPositions.validSegment]) continue
        const elem = Math.round(line[contentHeadersPositions.startPosSec])
        const currentDiamondContent = []

        for (const dk in diamondHeadersDictionary) {
            const value = line[contentHeadersPositions[dk]]
            currentDiamondContent.push({
                text: diamondHeadersDictionary[dk],
                value: value,
            })
        }

        const speechToText = hasSpeechToText ? line[STT_INDEX] : ''
        let timeCode = {
            seconds: elem,
            time: formatTime(elem),
            text: detectCurrentEmotionalStyle(currentDiamondContent),
            speechToText,
        }

        if(!finallyContent[elem] || finallyContent[elem].lvaStatus < line[LVA_POS_INDEX]){
            finallyContent[elem] = {
                diamond: currentDiamondContent,
                lvaStatus: line[LVA_POS_INDEX],
                overallCognitiveActivity: line[OVERALL_COGNITIVE_ACTIVITY_POS_INDEX],
                currentEmotionalStyle: detectCurrentEmotionalStyle(currentDiamondContent),
                speechToText,
                moods: moods[lineIdx],
                timeCode: timeCode,
            }    
        }

        const riskType = getRiskType(finallyContent[elem].lvaStatus);
        
        if(riskType){
            timeCodesOnVideoLine.push({
                channel: line[contentHeadersPositions.channel],
                time: line[contentHeadersPositions.startPosSec],
                type: riskType
            })
        }
    }
    
    let showOneChannel = !isSecondChannelValid(content)

    timeCodesOnVideoLine = timeCodesOnVideoLine.filter(i => !showOneChannel || i.channel === 0);

    return {
        finallyContent,
        timeCodesOnVideoLine
    }
}

export const isSecondChannelValid = (data) => {
    const segments = data?.segments || data;

    const distributions = speakersDistribution(segments);

    const totalTalk = 100 - distributions.silence;
    const crossTalkPercentage = distributions.crosstalk / totalTalk * 100;

    if(distributions.channel1 === 0) return false;
    if(crossTalkPercentage > 50) return false;
    if(distributions.channel1 <= distributions.crosstalk) return false;

    return true;
}

function speakersDistribution(data){
    const segments = data?.segments || data; 
    
    let result = {channel0: 0, silence: 0, crosstalk: 0, channel1: 0};
    
    if(!segments?.data) return result;
    
    const validSegments = segments.data.filter(s => s[headerPos(segments, 'validSegment')]);

    const channelPos = headerPos(segments, 'channel');
    const startPos = headerPos(segments, 'startPosSec');
    const endPos = headerPos(segments, 'endPosSec');

    let callLength = 0;
    let lastTimePos = 0;
    
    for(const segment of validSegments){
        if(segment[endPos] > callLength) callLength = segment[endPos];
        if(lastTimePos < segment[startPos]){
            result.silence += segment[startPos] - lastTimePos;
        }
        result[`channel${segment[channelPos]}`] += segment[endPos] - segment[startPos];

        lastTimePos = segment[endPos];
    }

    if(callLength > 0){
        if(result.channel0 + result.channel1 + result.silence > callLength){
            const cross = result.channel0 + result.channel1 + result.silence - callLength;
            result.crosstalk = cross;
            result.channel0 -= cross;
            result.channel1 -= cross;
        }
        result.channel0 = Math.round(result.channel0 / callLength * 100);
        result.channel1 = Math.round(result.channel1 / callLength * 100);
        result.silence = Math.round(result.silence / callLength * 100);
        result.crosstalk = Math.round(result.crosstalk / callLength * 100);
    }
    else result = {channel0: 0, silence: 0, crosstalk: 0, channel1: 0};

    return result;
}

export const headerPos = (data, header) => {
    const segments = data.segments || data;
    return segments.headersPositions[header];
}

export const getRiskType = (value) => {
    if(value >= 19) return "high";
    else if(value >= 18) return "medium";
    else if(value >= 17) return "suspected";
    return false;
}