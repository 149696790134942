import {environment} from '../common/environments/environment';
import {io} from "socket.io-client";

let socket = null;

export const getSocket = () => {
    if (!socket) {
        const path = environment.pushServiceListenerPath;
        socket = io(
            environment.pushServiceListenerUrl,
            {
                path: path,
                transports: [ "websocket", "polling" ],
                extraHeaders: {
                    clientId: Date.now(),
                }
            }
        )
        socket.on('connect', authenticate)
        socket.connect()
    }
    return socket
}

const authenticate = () => {
    const token = localStorage.getItem('token');
    if (token) {
        socket?.emit('authenticate', {token});
    }
}

addEventListener("storage", (event) => {
    const {key, newValue} = event;
    if (key === 'token') authenticate();
});
