<template>
  <div
      :class="{['notification-modal-item '+this.class]: true}"
      :key="item.notificationExternalId"
      @dblclick="notificationStore.setActive(item.notificationExternalId)"
      @click="readNotification(item.notificationExternalId)"
  >
    <div class="d-flex flex-column">
      <div class="notification-date">{{createdAt}}</div>
      <div class="notification-text" v-html="item.text"></div>
    </div>
    <div class="notification-right d-flex flex-column justify-content-between align-items-end">
      <div class="d-flex flex-row align-items-center">
        <div class="notification-badge-dot" :style="{'background-color': item.color}"></div>
        <div class="notification-badge">{{item.tag}}</div>
      </div>
<!--      <div class="see_more" @click="readNotification(item.notificationExternalId)">{{ t('common.seeMore') }}</div>-->
    </div>
  </div>
</template>

<script>

import {useOrganizationDateFormat} from "Common/utility/dateTimeUtility";
import Icon from "Common/components/svg";
import {useNotificationStore} from "Common/stores/notificationStore";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";
import {useI18n} from "vue-i18n";
import {computed} from "vue";

export default {
  components: {
    Icon,
  },
  props: {
    item: Object,
    class: String,
  },
  setup(props, {emit}){
    const { t } = useI18n({ useScope: 'global' });
    const notificationStore = useNotificationStore();
    const {formatDateTime} = useOrganizationDateFormat();

    const createdAt = computed(() => {
      return formatDateTime(props.item.createdDate);
    });

    return {
      t,
      notificationStore,
      createdAt,

      readNotification
    }

    function readNotification(externalId) {
      emit('on-close');
      notificationStore.openNotification.openExternalId = externalId;
      router.push({name: ROUTES.NotificationList});
    }
  }
}

</script>

<style scoped lang="scss">
  .notification-modal-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 65px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid white;
    background-color: inherit;
    .see_more{
      cursor: pointer;
      font-size: 12px;
      text-decoration-line: underline;
    }
    &.click-for-details {
      font-size: 12px;
    }
    .notification-badge {
      font-size: 10px;
      font-weight: 400;
    }
    .notification-badge-dot{
      width: 6px;
      height: 6px;
      min-width: 6px;
      min-height: 6px;
      border-radius: 50%;
      margin-right: 6px;
    }
    .notification-date {
      font-size: 10px;
      line-height: 13px;
      margin-bottom: 6px;
    }
    .notification-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      padding-right: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .notification-right{
      width: 100px;
    }
  }

body.rtl {
  .notification-modal-item {
    .notification-badge-dot{
      margin-left: 6px;
      margin-right: 0;
    }
    .notification-text {
      padding-left: 10px;
      padding-right: 0;
    }
  }
}
</style>
