<template>
  <div class="ces">
    <LeftBracketIcon class="ces-bracket left" />
    <div class="ces-body">
      <div class="ces-title">
        Current Emotional Style
      </div>
      <div class="ces-value" :style="valueStyle">
        {{ value }}
      </div>
    </div>
    <RightBracketIcon class="ces-bracket left" />
  </div>
</template>

<script setup>
import LeftBracketIcon from '../../../assets/icons/ces/left-bracket.svg'
import RightBracketIcon from '../../../assets/icons/ces/right-bracket.svg'
import {computed} from "vue";

const props = defineProps(['currentEmotionalStyle'])

const value = computed(() => {
  return `- ${props.currentEmotionalStyle} -`
})

const valueStyle = computed(() => {
  const styles = {
    'Energetic Logical': 'rgba(139, 197, 65, 1)',
    'Energetic Emotional': 'rgba(255, 0, 0, 1)',
    'Defensive Emotional': 'rgba(255, 199, 0, 1)',
    'Defensive Logical': 'rgba(78, 155, 240, 1)',
  }
  return {
    color: styles[props.currentEmotionalStyle] || 'rgba(0, 0, 0, 1)'
  }
})
</script>



<style src="./CurrentEmotionalStyle.ce.scss" lang="scss"></style>
