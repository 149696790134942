<template>
  <div part="analysis-risk" class="analysis-risk">
    <div part="analysis-risk-item"  class="analysis-risk-item" :class="{'analysis-risk-item--active-high': props.risk.high > 0}">
      <span>{{Locale.high}}</span>
      <span>{{props.risk.high}}</span>
    </div>
    <div part="analysis-risk-item" class="analysis-risk-item" :class="{'analysis-risk-item--active-medium': props.risk.medium > 0}">
      <span>{{Locale.medium}}</span>
      <span>{{props.risk.medium}}</span>
    </div>
    <div part="analysis-risk-item" class="analysis-risk-item" :class="{'analysis-risk-item--active-suspected': props.risk.suspected > 0}">
      <span>{{Locale.suspected}}</span>
      <span>{{props.risk.suspected}}</span>
    </div>
  </div>
</template>

<script>
const propsDefinition = {
  risk: {
    required: true,
    typeof: "object",
    existProps: ['high', 'medium', 'suspected']
  },
  localeTexts: {
    required: false,
    typeof: "object",
    existProps: ['risk', 'emotionDiamond'],
  },
  language: {
    required: false,
  }
}
</script>
<script setup>
import {getDefinePropsObject, getLocaleTexts, validateProps} from "@/helpers/helper.js";

const stringifyProps = defineProps(getDefinePropsObject(propsDefinition));

const {propsValidation, props} = validateProps(propsDefinition, stringifyProps);
const Locale = getLocaleTexts(props, (locale) => locale.analysisReport.risk);

</script>

<style src="./RiskBlocks.scss" lang="scss"/>
