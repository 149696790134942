<template>
  <modal :visible="show" :close="onClose" className="form">
    <div class="header">
      <div>{{ headerTitleText }}</div>
      <div class="modal-close" @click="onClose"><Icon name="close"/></div>
    </div>
    <div class="body attention-modal-text">
      <slot name="body">
        <div v-if="showIcon" class="text-center my-3">
          <slot name="top">
            <Icon name="attention_green" />
          </slot>
        </div>
        <div class="attention-modal-title text-center mb-3">
          <slot name="title"></slot>
        </div>
        <div class="text-center">
          <slot name="text"></slot>
        </div>
      </slot>
    </div>
    <div class="footer">
      <slot name="footer" :data="{onApprove, onClose}">
        <BaseButton class="mr-2" @click="onApprove" :loading="loadingApprove">
          {{ approveText ? approveText :t('common.apply')}}
        </BaseButton>
        <BaseButton outline @click="onClose">
          {{ cancelText ? cancelText :  t('common.cancel') }}
        </BaseButton>
      </slot>
    </div>
  </modal>
</template>

<script>

import { useI18n } from 'vue-i18n';
import {computed, ref, watch} from 'vue';
import Icon from 'Common/components/svg';
import BaseButton from 'Common/components/controls/BaseButton';
import BaseInputString from 'Common/components/controls/BaseInputString';

export default {
  components: {Icon, BaseButton, BaseInputString},
  props: {
    headerTitle: String,
    show: Boolean,
    showIcon:{
      type: Boolean,
      default: true
    },
    loadingApprove: Boolean,
    approveText: String,
    cancelText: String,
  },
  emits: ['approve', 'close'],
  setup(props, {emit}){
    const { t } = useI18n({ useScope: 'global' });
    const approve = ref("");

    const headerTitleText = computed(() => {
      return props.headerTitle ? props.headerTitle : t('common.attention');
    });

    watch(() => props.show, function (){
      approve.value = "";
    });

    function onApprove(){
      emit('approve');
    }

    function onClose(){
      emit('close');
    }

    return {
      t,
      headerTitleText,
      approve,
      onApprove,
      onClose
    }
  }
}
</script>

<style scoped lang="scss">
  .attention-modal-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
  .attention-modal-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
</style>
