<template>
  <div v-for="notify in badgeNotify.notifications" :key="notify.id">
    <BadgeNotify @close-notify="closeNotify" v-bind="notify" />
  </div>
</template>

<script>

import BadgeNotify from "Common/components/badge_notifications/BadgeNotify";
import {useBadgeNotify} from "Common/stores/badgeNotify";

export default {
  components: {
    BadgeNotify,
  },
  setup(){
    const badgeNotify = useBadgeNotify();
    return {
      badgeNotify
    }
  },
  methods:{
    closeNotify(id){
      this.badgeNotify.remove(id);
    }
  }
}

</script>