<template>
    <div class="root">
        <div class="d-flex flex-row justify-content-between align-items-center mb-4">
            <div class="title">{{ t('affiliate.dashboard.affiliateLinksCodes') }}</div>
            <div class="d-flex flex-row gap-2">
                <BaseSearch top-filter-style v-model="dataFilter.query" :placeholder="t('common.search')"/>
                <!--        <BaseDatepicker
                            v-model="dataFilter.dates"
                            :enableTimePicker="false"
                            multi-calendars
                            :maxDate="new Date()"
                            range
                            top-filter-style
                            :placeholder="t('common.datepickerRangePlaceholder')"
                        />-->
            </div>
        </div>


        <BaseTable
            async-data
            :headers="grid_heads"
            :rows="grid_rows"
            v-model:data-filter="dataFilter"
            @loadData="loadData"
            :searchable-fields="['name', 'code']"
            pagination
            :pagination-total-rows="totalRows"
        >
            <template #name="{row}">
                <div class="d-flex">
                    <FastEditString :data="row" @save="onUpdate" />
                </div>
            </template>
            <template #link="{row}">
                <div class="d-flex justify-content-center">
                    <Icon v-if="getCouponStatus(row) === 'valid'" name="copy" class="cursor-pointer" @click="() => copyToClipboard(affiliationStore.getCouponLink(row.code))" />
                    <Icon v-else name="copy" class="color-red" />
                </div>
            </template>
            <template #code="{row}">
                <div class="d-flex justify-content-center">
                    <Icon name="copy" class="cursor-pointer" @click="() => copyToClipboard(row.code)" />
                </div>
            </template>
            <template #createdAt="{row}">
                <div>{{ formatDate(row.createdAt) }}</div>
            </template>
            <template #expirationDate="{row}">
                <div>{{ formatDate(row.expirationDate) }}</div>
            </template>
            <template #couponTypeStatus="{row}">
                <div class="couponStatus" :class="{[getCouponStatus(row)]: true}">
                    {{ t('common.'+getCouponStatus(row)) }}
                </div>
            </template>
            <template #payoutDue="{row}">
                <div>${{ row.payoutDue }}</div>
            </template>
        </BaseTable>
    </div>
</template>

<script setup>
import {ref, computed} from "vue";
import Icon from "Common/components/svg";
import BaseTable from "Common/components/table/BaseTable";
import BaseSearch from "Common/components/controls/BaseSearch";
import FastEditString from "./FastEditString";
import {useI18n} from "vue-i18n";
import {useOrganizationDateFormat} from "Common/utility/dateTimeUtility";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";

const {t} = useI18n({useScope: 'global'});
const {formatDate} = useOrganizationDateFormat();
const affiliationStore = useAffiliationStore();
const badgeNotify = useBadgeNotify();

const dataFilter = ref({query: ''});
const grid_rows = computed(() => {
    return affiliationStore.coupons;
});

const totalRows = computed(() => {
    return affiliationStore.couponsTotal;
});

const grid_heads = computed(() => [
    {sortable: false, label: t('affiliate.dashboard.linkCodeName'), fieldName: 'name'},
    {sortable: false, label: t('common.copyLink'), fieldName: 'link', class: 'base-table-th-sm text-nowrap'},
    {sortable: false, label: t('common.copyCode'), fieldName: 'code', class: 'base-table-th-sm text-nowrap'},
    {sortable: true, label: t('common.dateCreated'), fieldName: 'createdAt'},
    {sortable: true, label: t('common.validThrough'), fieldName: 'expirationDate'},
    {sortable: false, label: t('common.status'), fieldName: 'couponTypeStatus', class: 'base-table-th-sm text-nowrap'},
    {sortable: true, label: t('common.timesUsed'), fieldName: 'usageCount'},
    {sortable: true, label: t('common.payoutDue'), fieldName: 'payoutDue'},
]);

function loadData(filter, params) {
    affiliationStore.searchAffiliateCoupon(filter, params).catch((err) => {
        console.log(err);
    });
}

function copyToClipboard(text) {
    if(text) navigator.clipboard.writeText(text);
}

function onUpdate(row){
    affiliationStore.updateAffiliateCoupon(row).then(() => {
        const old = affiliationStore.coupons.find(a => a.affiliateCouponExternalId === row.affiliateCouponExternalId);
        Object.assign(old, row);
    }).catch((err) => {
        const invalidParams = err?.check?.invalidParams || [];
        const [invalidField] = invalidParams.map(i => i.field).filter(Boolean);

        let text = t('common.errorList.sorrySomethingWrong');

        text = invalidField ? t(`common.errorList.invalid_value`) : text;

        badgeNotify.show({
            text,
            error: true
        });
    })
}

function getCouponStatus(row) {
    if(row.expirationDate && new Date() > new Date(row.expirationDate)) return 'expired';
    if((row.couponTypeExpirationDate && new Date() > new Date(row.couponTypeExpirationDate)) || row.couponTypeStatus !== 'active') return 'promoEnded';
    return 'valid';
}

</script>

<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';

.root {
    border: 1px solid $c-text-secondary;
    border-radius: 12px;
    padding: 16px 24px 0 24px;
    background-color: $c-white;
}
.edit-icon {
    cursor: pointer;
    fill: $c-blue-accent;
}

.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $c-text-primary;
}

.compare-arrow {
    color: $c-green;

    &.worse {
        transform: rotate(180deg);
        color: $c-red-accent;
    }
}
.couponStatus{
    width: 120px;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid $c-white;
    border-radius: 8px;
    &.expired{
        border-color: $c-red-accent;
    }
    &.promoEnded{
        border-color: $c-orange;
    }
    &.valid{
        border-color: $c-blue-accent;
    }
}
svg[data-name="copy.svg"].color-red{
    stroke: $c-red-accent;
}
</style>
