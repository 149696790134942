<template>
    <div class="w-100">
        <div class="form-container">
            <div class="description-text">
                {{t('affiliate.registerDetails.iWantToPromoteText')}}
            </div>
            <div class="dropdown-title">
                {{t('affiliate.registerDetails.iWantToPromote')}}
            </div>
            <DropdownWithCheckbox
                v-model="formData.promotes"
                :options="promoteOptions"
                :error="errors.promotes"
                class="w-100 mb-3"
            />
            <div class="description-text">
                {{t('affiliate.registerDetails.targetAudienceText')}}
            </div>
            <div class="dropdown-title">
                {{t('affiliate.registerDetails.targetAudience')}}
            </div>
            <DropdownWithCheckbox
                v-model="formData.promotionTargets"
                :options="promotionTargetOptions"
                :error="errors.promotionTargets"
                class="w-100 mb-3"
            />
            
        </div>
        <div class="d-flex mt-3 justify-content-end">
            <BaseButton @click="onSave" :loading="loadingSave">
                {{ t('common.update') }}
            </BaseButton>
        </div>
    </div>
</template>
<script setup>

import {ref, onMounted, shallowRef, onBeforeMount, onBeforeUnmount, watch, computed} from "vue";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useUserStore} from "Common/stores/userStore";
import BasePreloader from "Common/components/controls/BasePreloader";
import DropdownWithCheckbox from "Common/components/controls/DropdownWithCheckbox";
import BaseButton from "Common/components/controls/BaseButton";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import {createFormErrors} from "Common/utility/formErrors";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";

const {t, te, locale} = useI18n({useScope: 'global'});
const userStore = useUserStore();
const organizationStore = useOrganizationStore();
const affiliationStore = useAffiliationStore();
const badgeNotify = useBadgeNotify();
const loading = ref(true);
const loadingSave = ref(false);
const route = useRoute();

const formData = ref({});


const {errors, showHiddenErrors, addErrors} = createFormErrors(t, te, formData, () => {
    const newErrors = {};
    //if(formData.value.promotes.length === 0) newErrors.promotes = 'required';
    //if(formData.value.promotionTargets.length === 0) newErrors.promotionTargets = 'required';
    return newErrors;
});


const promoteOptions = ref([
    {value: 'contactCenterApps', label: t('affiliate.registerDetails.promoteOptions.contactCenterApps')},
    {value: 'hrApps', label: t('affiliate.registerDetails.promoteOptions.hrApps')},
    {value: 'riskAssessmentApps', label: t('affiliate.registerDetails.promoteOptions.riskAssessmentApps')},
    {value: 'vrAndBotsApps', label: t('affiliate.registerDetails.promoteOptions.vrAndBotsApps')},
    {value: 'psychoMedicalEvaluationApps', label: t('affiliate.registerDetails.promoteOptions.psychoMedicalEvaluationApps')},
    {value: 'entertainmentApps', label: t('affiliate.registerDetails.promoteOptions.entertainmentApps')},
    {value: 'matchmakingApps', label: t('affiliate.registerDetails.promoteOptions.matchmakingApps')},
    {value: 'other', label: t('affiliate.registerDetails.promoteOptions.other')},
])

const promotionTargetOptions = ref([
    {value: 'entrepreneurs', label: t('affiliate.registerDetails.promotionTargetOptions.entrepreneurs')},
    {value: 'developers', label: t('affiliate.registerDetails.promotionTargetOptions.developers')},
    {value: 'productManagers', label: t('affiliate.registerDetails.promotionTargetOptions.productManagers')},
    {value: 'innovationManagers', label: t('affiliate.registerDetails.promotionTargetOptions.innovationManagers')},
    {value: 'cLevelManagement', label: t('affiliate.registerDetails.promotionTargetOptions.cLevelManagement')},
    {value: 'other', label: t('affiliate.registerDetails.promotionTargetOptions.other')},
])


onMounted(() => {
    if(!affiliationStore.affiliateUserProfile){
        affiliationStore.loadAffiliateUserProfile().then((data) => {
            formData.value = data;
        }).catch((err) => {
            console.log(err);
            badgeNotify.show({
                test: t("common.errorList.sorrySomethingWrong"),
                error: true
            });
        }).finally(() => {
            loading.value = false;
        });
    }
    else{
        formData.value = affiliationStore.affiliateUserProfile;
        loading.value = false;
    }
})

function onSave() {
    if (showHiddenErrors()) return;

    loadingSave.value = true;

    affiliationStore.updateAffiliateUserProfile(formData.value).then(() => {
        affiliationStore.affiliateUserProfile = {...formData.value} ?? {};
        badgeNotify.show({
            text: t('user_profile.update_success'),
            success: true
        });
    }).catch((err) => {
        console.log(err);
        let text = t('user_profile.update_error');
        badgeNotify.show({
            text,
            error: true
        });
    }).finally(() => {
        loadingSave.value = false;
    })
}

</script>

<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';
.description-text{
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    margin-bottom: 16px;
}
.dropdown-title{
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    margin-bottom: 12px;
}
.form-container{
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

body.rtl {
    .description-text{
        text-align: right;
    }
    .dropdown-title{
        text-align: right;
    }
}
</style>
