<template>
  <modal :visible="show" :close="onClose" className="form">
    <div class="header">
      <div class="title">
        {{ title || t('common.newCase') }}
      </div>
      <div class="modal-close" @click="onClose">
        <IconClose name="close"/>
      </div>
    </div>
    <div class="body">
      <BaseDropdown
          :name="t('common.topic')"
          class="mb-3"
          :is-required="true"
          v-model="formData.topic"
          :options="topicOptions"
          :canClear="false"
          :error="errors.topic"/>
      <BaseInputString
          class="mb-3"
          v-model="formData.subject"
          :is-required="true"
          :name="t('common.subject')"
          :error="errors.subject"/>
      <BaseTextarea
          :name="t('common.message')"
          :rows="4"
          :is-required="true"
          v-model="formData.body"
          :error="errors.body"
          :max-length="5000"
      />
      <div class="base-input-container flex-column align-items-sm-start">
        <input ref="file" type="file"/>
        <div>{{t("common.attachmentSizeLimit", {sizeMb: 25})}}</div>
      </div>
    </div>
    <div class="footer">
      <div class="d-flex justify-content-end mb-3">
        <BaseButton class="mr-3" @click="createNew">
          {{ t('common.submit') }}
        </BaseButton>
        <BaseButton :loading="loadingSave" outline @click="onClose">
          {{ t('common.cancel') }}
        </BaseButton>
      </div>
    </div>
  </modal>
</template>

<script setup>
import IconClose from "Common/assets/icons/close.svg?inline";
import {useCustomerSupportStore} from "Common/stores/customerSupportStore";
import BaseInputString from "Common/components/controls/BaseInputString";
import BaseDropdown from "Common/components/controls/BaseDropdown";
import BaseTextarea from "Common/components/controls/BaseTextarea";
import BaseButton from "Common/components/controls/BaseButton";
import {useI18n} from "vue-i18n";
import {ref, shallowRef, toRef, unref, watch} from "vue";
import {createFormErrors} from "Common/utility/formErrors";
import {useBadgeNotify} from "Common/stores/badgeNotify";

const badgeNotify = useBadgeNotify();

const props = defineProps({
  show: Boolean,
  close: Function,
  title: String
});

const file = shallowRef(null);

const emit = defineEmits(['caseCreated'])

const {t, te, locale} = useI18n({useScope: 'global'});
const customerSupportStore = useCustomerSupportStore();
const loadingSave = ref(false);
const formData = ref(    {});

const topicOptions = customerSupportStore.getTopicOptionList(t);

const {showHiddenErrors, errors} = createFormErrors(t, te, formData, null, {
  requiredFields: ["topic", "subject", "body"]
});


watch(toRef(props, "show"), t => {
    if (t) {
        formData.value = customerSupportStore.loadDraft('new');
    }
});


watch(formData, n => {
    customerSupportStore.saveDraft('new', n);
}, {
    deep: true
});


function createNew() {
  if (showHiddenErrors()) return;
  //errorsObj.removeErrors();

  const fileField = unref(file.value);

  loadingSave.value = true;
  customerSupportStore.createCustomerSupportCase(
      formData.value,
      fileField?.files[0]
  ).then((data) => {
    const createdObj = {...formData.value, csSupportRequestExternalId: data.csSupportRequestExternalId};
    emit('caseCreated', createdObj);
    formData.value = {};
    customerSupportStore.clearDraft('new');
    props.close();
  }).catch((err) => {
    badgeNotify.show({
      error: true,
      text: err?.errorCode ? t(`common.errorList.${err.errorCode}`) : t(`common.errorList.sorrySomethingWrong`)
    });
    console.log(err);
  }).finally(() => {
    loadingSave.value = false;
  });
}

function onClose() {
    customerSupportStore.clearDraft('new');
    props?.close();
}

</script>
