<template>
  <div id="header_user_badge" class="header_user_badge" :class="this.class" v-on:keydown.capture.esc="closeModal" tabindex="1">
    <div class="user_badge_icon_container" @click="goToProfilePage">
      <img class="user-profile-img" :src="userStore.user?.profileImageLarge" alt="" />
    </div>
    <div class="d-flex flex-row">
      <div class="user-info" @click="goToProfilePage">
        <div class="user_badge_name">{{userStore.user?.firstName}}</div>
        <div class="user_badge_name">{{userStore.user?.lastName}}</div>
      </div>
      <div class="d-flex flex-row align-items-center">
        <div
            class="user-icon-container"
            :class=iconClass
            @click="openSetDefaultUrlConfirmModal">
          <Tooltip
            :text="isCurrentRouteIsDefault ? t('homePageSettings.isDefault') : t('homePageSettings.setAsDefault')"
          >
            <Icon name="home"/>
          </Tooltip>
        </div>
        <div v-if="!hideNotifications" class="user-icon-container" @click="openModal">
          <Icon name="notifications" />
          <div v-if="notificationStore.notificationStats.unreadCount > 0" class="notification-counter">{{countNotifications}}</div>
        </div>
        <language-selector class="user-icon-container" />
        <div class="user-icon-container user-logout" @click="logout">
          <Icon name="logout" />
        </div>
      </div>
    </div>
    <NotificationModal
      v-if="!hideNotifications"
      :show="notificationModalOpen"
      @on-close="closeModal"
    />
  </div>
  <SetDefaultUrlConfirmModal
    :show="isSetDefaultUrlConfirmModalShow"
    @submit="updateDefaultUrl"
    @close="closeSetDefaultUrlConfirmModal"
  />
</template>

<script>

import Icon from "Common/components/svg";
import NotificationModal from "Common/components/header/NotificationModal";
import {useUserStore} from "Common/stores/userStore";
import {useNotificationStore} from "Common/stores/notificationStore";
import {ref, onMounted, computed, shallowRef, watch} from "vue";
import {useI18n} from "vue-i18n";
import router from "@/routes";
import {getActivePinia} from "pinia";
import SetDefaultUrlConfirmModal from "Common/components/header/SetDefaultUrlConfirmModal.vue";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import {comparePaths} from "Common/utility/comparePath";
import Tooltip from "Common/components/tooltip.vue";
import LanguageSelector from "Common/components/header/language-selector.vue";

export default {
  components: {
    LanguageSelector,
    Tooltip,
    SetDefaultUrlConfirmModal,
    Icon,
    NotificationModal
  },
  props: {
    class: String,
    hideNotifications: Boolean
  },
  setup(){
    const pinia = getActivePinia();
    const resetAllPiniaStores = () => {
      const activeStores = Object.keys(pinia.state.value);

      activeStores.forEach((store) => {
        try{
          pinia._s.get(store).$reset();
        } catch (e) {
          console.log(e)
        }
      });
    };

    const {
      show
    } = useBadgeNotify()

    const isCurrentRouteIsDefault = shallowRef(false)

    const iconClass = computed(() => ({
      'isCurrentRouteIsDefault': isCurrentRouteIsDefault.value
    }))

    const { t } = useI18n({ useScope: 'global' });
    const userStore = useUserStore();
    const notificationStore = useNotificationStore();
    const notificationModalOpen = ref(false);

    const isSetDefaultUrlConfirmModalShow = shallowRef(false)

    const countNotifications = computed(() => {
      return notificationStore.notificationStats.unreadCount > 9
          ? `9+`
          : `${notificationStore.notificationStats.unreadCount}`
    })

    onMounted(() => {
      notificationStore.loadNotificationStats().catch((err) => {
        console.log(err);
      });

      updateIsSetDefaultUrlConfirmModalShow()
    })

    watch(router.currentRoute, updateIsSetDefaultUrlConfirmModalShow)

    function updateIsSetDefaultUrlConfirmModalShow () {
      isCurrentRouteIsDefault.value = comparePaths(window.location.pathname, userStore.user.defaultHomeUrl || userStore.user.organization?.defaultHomeUrl)
    }

    return {
      t,
      userStore,
      notificationStore,
      notificationModalOpen,
      countNotifications,
      isSetDefaultUrlConfirmModalShow,
      isCurrentRouteIsDefault,
      iconClass,

      logout,
      openModal,
      closeModal,
      goToProfilePage,
      updateDefaultUrl,
      openSetDefaultUrlConfirmModal,
      closeSetDefaultUrlConfirmModal
    }

    function logout(){
      resetAllPiniaStores()
      userStore.logout();
    }

    function updateDefaultUrl() {
      userStore.updateUserProfile({
        externalId: userStore.user.externalId,
        defaultHomeUrl: window.location.pathname
      })
          .then(() => {
            show({
              text: t('homePageSettings.success'),
              success: true
            });
            updateIsSetDefaultUrlConfirmModalShow()
          })
          .catch(() => {
            show({
              text: t('homePageSettings.error'),
              success: false
            });
          })
          .finally(() => {
            closeSetDefaultUrlConfirmModal()
          })
    }

    function openModal() {
      notificationModalOpen.value = true;
    }

    function closeModal() {
      notificationModalOpen.value = false;
    }

    function openSetDefaultUrlConfirmModal() {
      if (isCurrentRouteIsDefault.value) {
        return
      }

      isSetDefaultUrlConfirmModalShow.value = true
    }

    function closeSetDefaultUrlConfirmModal() {
      isSetDefaultUrlConfirmModalShow.value = false
    }

    function goToProfilePage(){
      router.push('/personal-info');
    }
  }
}

</script>

<style scoped lang="scss">
  .isCurrentRouteIsDefault{
    background: #4E9BF0!important;
    color: white!important;
  }
  .header_user_badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    border-radius: 12px;
    & + .header_sites_badge{
      margin-left: 12px;
    }
    &:focus-visible {
      outline: none!important;
    }
    &.onboarding-selected{
      box-shadow: rgba(white, 0.56) 0px 0px 15px 0px;
      border: 2px solid white;
    }
  }
  .user-info{
    margin-right: 20px;
    cursor: pointer;
    max-width: 180px;

    .user_badge_name{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }
  .user-icon-container{
    position: relative;
    cursor: pointer;
    padding: 8px;
    border-radius: 50px;
    color: #4E9BF0;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    & + .user-icon-container{
      margin-left: 10px;
    }
    svg{
      display: flex;
      width: 24px;
      height: 24px;
    }
  }
  .user-profile-img{
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
  .user_badge_icon_container {
    cursor: pointer;
    position: relative;
    border-radius: 100px;
    margin-right: 15px;
    width: 38px;
    height: 38px;
  }
  .sites_badge_org{
    font-size: 12px;
    line-height: 16px;
  }
  .sites_badge_name{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .notification-counter {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    right: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
  }
  .onboarding-disable .user-logout{
    pointer-events: all;
    color: white;
  }

body.rtl {
  .header_user_badge {
    & + .header_sites_badge{
      margin-right: 12px;
    }
  }
  .user-info{
    margin-left: 20px;
    margin-right: 0;
    
  }
  .user-icon-container{
    & + .user-icon-container{
      margin-right: 10px;
      margin-left: 0;
    }
  }
  
  .user_badge_icon_container {
    margin-left: 15px;
    margin-right: 0;
  }
  .notification-counter {
    left: -3px;
    right: unset;
  }
}
</style>
