import {computed, onMounted, ref, watch} from 'vue';
import _ from "lodash";

export {
  init,
  propsDefinitions,
  emitDefinitions
}

const propsDefinitions = {
  headers: Array, //[{ class, sortable, fieldName, label, rowClass, comparison:{comparisonField, useComparisonColor, fieldNameA, fieldNameB} }]
  rows: Array,
  dataFilter: Object,
  dataParams: Object,
  trStyle: String,
  checkable: Boolean,
  checkableField: {
    type: String,
    default: "_checked"
  },
  pagination: Boolean,
  paginationTotalRows:{
    type: Number,
    default: 0
  },
  searchableFields: { //only for not asyncData
    type: Array,
    default: ['name'],
  },
  sortOptions: { //only for not asyncData
    type: Object,
    default: {}, // {fieldName: {sort: Function}}
  },
  asyncData: Boolean, //true: search and sort do request;   false - work with local data
  stickyHeader: Boolean,
  loading: {
    type: Boolean,
    default: false
  },
}

const emitDefinitions = [
  'rowClicked',
  'loadData',
  'update:dataFilter',
  'update:dataParams',
  'rowsDataForExport',
  'resettingPageNumber' //allow to prevent or change updating of page number 
];

function init(props, emit){
  const searchTimeout = ref(null);
  const _filter = ref({});
  const _filterParams = ref((() => {
    const _params = {orderBy: undefined}; //{fieldName: '', rule: 'asc'}
    if(props.searchableFields) _params.searchFields = props.searchableFields;
    return _params;
  })());

  const stickyHeader = computed(() => props.stickyHeader);
  const filterParams = computed({
    get: function (){
      return props.dataParams ? props.dataParams : _filterParams.value;
    },
    set: function (value) {
      if(props.dataParams) emit('update:dataParams', value);
      else _filterParams.value = value;
    }
  });

  const filter = computed({
    get: function (){
      return props.dataFilter ? props.dataFilter : _filter.value;
    },
    set: function (value) {
      if(props.dataFilter) emit('update:dataFilter', value);
      else _filter.value = value;
    }
  });

  const rowsDataForExport = computed(() => {
    if (props.asyncData){
      return props.rows;
    }

    const data = props.rows?.filter((row) => {
      const search = filter.value?.query?.toString().toLowerCase() || '';
      if(search === '') return true;
      return props.searchableFields.some((fieldName) => row[fieldName].toString().toLowerCase().includes(search));
    });

    if(Array.isArray(filterParams.value.orderBy)){
      filterParams.value.orderBy.forEach((item) => {
        data.sort(
            props.sortOptions[item.fieldName]
                ? (a, b) => {return props.sortOptions[item.fieldName].sort(a, b, item.rule)}
                : (a, b) => {return defaultSort(a, b, item)}
        );
      })
    }

    return data;

  });

  const isSomeRowChecked = computed(() => {
    return rowsData.value?.some((e) => e[props.checkableField]);
  });

  const rowsData = computed(() => {
      if(props.asyncData){
          return props.rows;
      }

      const data = rowsDataForExport.value || [];

      if(!!props.pagination){
          const page = filter.value?.page || 1
          const total = filter.value?.limit || 20
          const from = (page - 1) * total
          const to = from + total
          return [...data].slice(from, to)
      }

      return data;
    }
  );

  onMounted(() => {
    filter.value = {
      ...filter.value,
      page: filter.value.page || 1,
      limit: filter.value.limit || 20,
    };

    if(props.dataFilter){
      watch(() => filter.value.query, () => {
        if(props.asyncData){
          if(!searchTimeout.value) setSearchTimeout();
          else{
            clearTimeout(searchTimeout.value);
            setSearchTimeout();
          }
        }
      });
    }

    watch(() => filterParams, () =>{
      if(!searchTimeout.value) loadData();
    }, { deep: true });

    watch(() => filter, () =>{
      if(!searchTimeout.value) loadData();
    }, { deep: true });

    if(!props.dataFilter){
      loadData(); //for fix initial loading without filter
    }

    watch(rowsDataForExport, r => {
      emit('rowsDataForExport', r);
    }, {
      immediate: true
    });

    watch(() => props.rows, (value, prevValue) => {
      if(!props.asyncData) {
        let goToPage = 1;
        
        emit('resettingPageNumber', {value, prevValue}, (result) => {
          goToPage = result;
        });
        
        if(goToPage) filter.value.page = goToPage;  
      }
    });
  })

  const checkAll = computed({
    get(){
      const notChecked = rowsData.value?.find((e) => !e[props.checkableField]);
      return !notChecked;
    },
    set(newValue){
      rowsData.value?.forEach((e) => {
        e[props.checkableField] = newValue;
      });
    }
  });

  function loadData(){
    emit('loadData', filter.value, filterParams.value);
  }

  function getFieldOrderByName(fieldName){
    const defaultObj = {fieldName: fieldName, rule: 'asc'};

    if(Array.isArray(filterParams.value.orderBy)) return filterParams.value.orderBy.find((item) => item.fieldName === fieldName) || defaultObj;
    return defaultObj;
  }

  function setSearchTimeout(){
    filter.value.page = 1;
    searchTimeout.value = setTimeout(() => {
      loadData();
      searchTimeout.value = null;
    }, 500);
  }

  function changeColumnSort(fieldName) {
    const hasOrder = Array.isArray(filterParams.value.orderBy) && filterParams.value.orderBy.length > 0;
    const isSameFieldName = hasOrder && filterParams.value.orderBy[0].fieldName === fieldName;

    filterParams.value.orderBy = [{
      fieldName: fieldName,
      rule: hasOrder && isSameFieldName && filterParams.value.orderBy[0].rule === 'asc' ? 'desc' : 'asc'
    }];
  }

  function defaultSort(a, b, orderItem) {
    const sortValue = orderItem.rule === 'asc' ? 1 : -1;
    let _a = a[orderItem.fieldName];
    let _b = b[orderItem.fieldName];

    //for fix comparison string with null|undefined and etc.
    if(!_a) _a = '';
    if(!_b) _b = '';

    if(_a > _b) return sortValue;
    if(_a < _b) return -sortValue;
    return 0;
  }

  function getComparisonData(row, header){
    const data = {
      compareIconName: "arrow-compare",
      direction: 'up', // up  down
      type: 'better', // better   worse  ''
    }

    const fieldNameA = header.comparison.fieldNameA ? header.comparison.fieldNameA : header.fieldName;
    const fieldNameB = header.comparison.comparisonField ? header.comparison.comparisonField : header.comparison.fieldNameB;

    if(row[fieldNameA] > row[fieldNameB]) {
      data.direction = 'up';
      data.type = 'better';
    }
    else if(row[fieldNameA] < row[fieldNameB]) {
      data.direction = 'down';
      data.type = 'worse';
    }
    else {
      data.compareIconName = '';
      data.type = '';
    }

    if(header.comparison.revert && data.type !== '') {
      data.type = data.type === 'better' ? 'worse' : 'better';
    }

    return data;
  }

  return {
    rowsData,
    rowsDataForExport,
    isSomeRowChecked,
    filter,
    checkAll,
    filterParams,
    changeColumnSort,
    getFieldOrderByName,
    getComparisonData,
    stickyHeader,
  };
}
