<template>
  <div class="dashboard-frame">
    <div class="dashboard-frame-header">
      <slot name="header" />
    </div>
    <div class="dashboard-frame-body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>


export default {
  components: {},
  props: {},
}
</script>

<style lang="scss" scoped>
  @import '~Common/assets/styles/themes/light/light-theme-colors';
  .dashboard-frame{
    background: $c-white;
    border: 1px solid $c-text-secondary;
    border-radius: 12px;
    overflow: auto;
    width: 100%;
    
    .dashboard-frame-header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: $c-sidebar_and_texts;
    }
  }
</style>

