<template>
    <div class="sidebar" :class="{'hamburger': hamburger}">
        <div class="mobile-hamburger mt-4" @click="hamburger = !hamburger">
            <Icon name="hamburger"/>
        </div>
        <div class="sidebar-content">
            <div>
                <div class="sidebar-header sidebar_item sidebar_item_no_hover flex-row flex-nowrap align-items-center">
                    <router-link to="/" class="d-flex flex-column ">
                        <div class="logo-elogic">
                            <img src="~Common/assets/emlo-affiliate.png" alt=""/>
                        </div>
                    </router-link>
                    <!--          <div class="close-btn ml-2" @click="hamburger = !hamburger">-->
                    <!--            <Icon name="close"/>-->
                    <!--          </div>-->
                </div>

                <div class="create-new-project">
                    <base-button class="" @click="onCreateCoupon">
                        {{t("common.createNewCampain")}}
                        <div class="add-icon">
                            <icon-add />
                        </div>
                    </base-button>
                </div>


                <MenuItem class="sidebar_item" :user="userStore.user"
                          :data="{icon: 'dashboard', text: t('common.dashboard'), route: ROUTES.Dashboard}"/>
                <MenuItem class="sidebar_item" :user="userStore.user"
                          :data="{icon: 'notifications', text: t('common.notifications'), route: ROUTES.NotificationList}"/>
                <MenuItem class="sidebar_item" :user="userStore.user"
                          :data="{icon: 'pension', text: t('common.payouts'), route: ROUTES.Payouts}"/>
                <MenuItem class="sidebar_item" :user="userStore.user"
                          :data="{icon: 'settings', text: t('common.profileSettings'), route: ROUTES.PersonalInformation}"/>
            </div>


            <div>
                <div class="sidebar-footer mb-0">
                    <MenuItem class="sidebar_item" :user="userStore.user"
                              :data="{icon: 'treasure-chest', text: t('common.resources'), route: '#'}"/>
                    <MenuItem class="sidebar_item" :user="userStore.user"
                              :data="{icon: 'headphones', text: t('common.customerSupport'), route: ROUTES.CustomerSupportCases}">
                        <template #afterText>
                            <CustomerSupportCaseCounter/>
                        </template>
                    </MenuItem>
                </div>
                <div class="sidebar-footer mt-0">
                    <div class="d-flex justify-content-evenly mx-5">
                        <router-link class="sidebar_brand" target="_blank" :to="{name: ROUTES.PrivacyPolicy}">{{t('common.privacyPolicy')}}</router-link>
                        <router-link class="sidebar_brand" target="_blank" :to="{name: ROUTES.TermOfUse}">{{ t('common.termOfUse') }}</router-link>
                    </div>

                    <div class="sidebar_brand">
                        <a target="_blank" class="sidebar_brand" href="https://emotionlogic.ai">https://emotionlogic.ai</a>
                    </div>

                    <div class="sidebar_brand d-flex justify-content-center align-items-center" v-html="t('common.copyright', {year: new Date().getFullYear()})" />
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import MenuItem from "Common/components/sidebar/SidebarItem";
import CustomerSupportCaseCounter from "@/pages/customer-support/CaseCounter";
import {useUserStore} from "Common/stores/userStore";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import Icon from "Common/components/svg";
import {ROUTES} from "@/utility/routeUtils";
import {useI18n} from "vue-i18n";
import {shallowRef, watch, toRef, unref} from "vue";
import BaseButton from "Common/components/controls/BaseButton";
import IconAdd from "@/assets/icons/onboarding/add.svg?inline"

const menu = shallowRef({});
const hamburger = shallowRef(false);

const props = defineProps({
    msg: String,
    appContainer: Object
})

const userStore = useUserStore();
const affiliationStore = useAffiliationStore();
const {t} = useI18n({useScope: 'global'});
const sidebarDOM = shallowRef(null);

let appContainer = null;

watch([toRef(props, "appContainer"), sidebarDOM], (news, olds) => {
    if (!news.every(Boolean))
        return;
    const [appContainerPrev,] = olds;
    const [appContainer,] = news;
    if (appContainerPrev) {
        appContainer.removeEventListener('click', setSidebarPosition);
        appContainer.removeEventListener('scroll', setSidebarPosition);
    }
    sidebarDOM.value = document.getElementsByClassName('sidebar')[0];

    appContainer.addEventListener('click', setSidebarPosition);
    appContainer.addEventListener('scroll', setSidebarPosition);

});

const SUBMENU_BILLING = {
    route: "#",
    icon: "billing",
    text: t("common.billing"),
    children: [{
        route: ROUTES.BillingOverview,
        text: t("common.overview"),
    }, {
        route: ROUTES.BillingPayment,
        text: t("common.payment"),
    }]
};

const SUBMENU_SETTINGS = {
    route: "#",
    icon: "settings",
    text: t("common.settings"),
    children: [{
        route: ROUTES.PersonalInformation,
        text: t("common.personalInformation"),
    }, {
        route: ROUTES.OrganizationInformation,
        text: t("common.organizationInformation"),
    }, {
        route: ROUTES.SettingsUsers,
        text: t("common.organizationUsers"),
    }, {
        route: ROUTES.SettingsNotifications,
        text: t("common.notifications"),
    }/*, {
        route: ROUTES.SettingsSecurity,
        text: t("common.security"),
      }*/]
};
const ADMIN_SUBMENU_RESEARCH = {
    route: "#",
    icon: "settings",
    text: t("common.research"),
    children: [
        {
            route: ROUTES.AppAnalyzeFile,
            text: t("common.analyzeFile"),
        }
    ]
};

const SUBMENU_REPORTS = {
    route: "#",
    icon: "reports",
    text: t('common.reports'),
    children: [{
        route: ROUTES.ReportsSites,
        text: t('common.sites'),
    }, {
        route: ROUTES.ReportsErrors,
        text: t('common.errors'),
    }, {
        route: ROUTES.CloudAnalysis,
        text: t('common.cloudAnalysis'),
    }]
};

const ADMIN_SUBMENU_SETTINGS = {
    route: "#",
    icon: "settings",
    text: t("common.settings"),
    children: [{
        route: ROUTES.MANAGEMENT.ROLES,
        text: t("common.roles"),
    }, {
        route: ROUTES.MANAGEMENT.APPLICATION_TYPES.LIST,
        text: t("common.applicationTypes"),
    }, {
        route: ROUTES.MANAGEMENT.PERMISSION_LEVELS,
        text: t("common.permissionLevels"),
    }, {
        route: ROUTES.MANAGEMENT.NOTIFICATION_TEMPLATES.LIST,
        text: t("common.notificationTemplates"),
    }, {
        route: ROUTES.MANAGEMENT.NOTIFICATION_TYPES,
        text: t("common.notificationTypes"),
    }, {
        route: ROUTES.MANAGEMENT.SYSTEM_TEXT.LIST,
        text: t("common.systemText"),
    }, {
        route: ROUTES.MANAGEMENT.DOCKER_VERSIONS.LIST,
        text: t("dockerVersions.dockerVersions"),
    }]
};


const LICENSES_SUBMENU_SETTINGS = {
    route: "#",
    icon: "settings",
    text: t("common.licenses"),
    children: [{
        route: ROUTES.LicenseKeys,
        text: t('common.licenseKeys'),
    }, {
        route: ROUTES.CreditKeys,
        text: t('common.creditKeys'),
    }, {
        route: ROUTES.ReconfigurationKeys,
        text: t('common.reconfigurationKeys'),
    }]
};

const AFFILIATES_SUBMENU_SETTINGS = {
    route: "#",
    icon: "settings",
    text: t("common.affiliates"),
    children: [{
        route: ROUTES.MANAGEMENT.AFFILIATE_COUPON_TYPE.LIST,
        text: t("common.couponTypes"),
    }]
};

const DOCUMENTATION_SUBMENU = {
    route: "#",
    icon: "settings",
    text: t("common.documentation"),
    children: [ {
        route: ROUTES.MANAGEMENT.DOCUMENTATION,
        text: t("common.editDocs"),
    },{
        route: ROUTES.DocumentationImages,
        text: t("common.images"),
    }, ]
};

function onMenuToggle(name, expanded) {
    if (expanded) {
        menu.value = {[name]: expanded };
    } else {
        menu.value = {...menu.value, [name]: false };
    }
}


function setSidebarPosition() {
    if (!props.appContainer || !sidebarDOM.value)
        return;
    const sidebar = unref(sidebarDOM);
    const appContainer = props.appContainer;
    if (sidebar.clientHeight < appContainer.scrollTop + appContainer.getBoundingClientRect().height)
        sidebar.style.top = appContainer.scrollTop + appContainer.getBoundingClientRect().height - sidebar.clientHeight + 'px';
    else
        sidebarDOM.value.style.top = 0;
}

function onCreateCoupon() {
    affiliationStore.showCreateCouponModal = true;
}

</script>
<style lang="scss" src="Common/assets/styles/sidebar.scss"></style>
<style lang="scss" scoped>
.mobile-hamburger {
    display: none;
    cursor: pointer;
    @media (max-width: 1200px) {
        display: block;
    }
}

.hamburger .mobile-hamburger {
    display: block;
    @media (max-width: 1200px) {
        display: none;
    }
}

.sidebar {
    //position: absolute;
    min-width: 290px;
    max-width: 290px;
    @media (max-width: 1200px) {
        min-width: 24px;
        max-width: 24px;
    }

    .create-new-project {
        margin: 16px auto 24px;
        .base-btn {
            width: 242px;
            margin: 0 auto;
            height: 36px;
            background: linear-gradient(267.35deg, #EF6524 0%, #FBBA12 100%);
            justify-content: start;
            padding-left: 36px;
        }
        .add-icon {
            border-radius: 50%;
            width: 44px;
            height: 44px;
            background: #fff;
            position: absolute;
            right: -5px;
            svg {
                left: 10px;
                top: 10px;
                position: absolute;
                stroke: #FF8000;
            }
        }
    }
}

.hamburger.sidebar {
    min-width: 24px;
    max-width: 24px;
    @media (max-width: 1200px) {
        min-width: 290px;
        max-width: 290px;
    }
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    @media (max-width: 1200px) {
        display: none;
    }
}

.hamburger .sidebar-content {
    display: none;
    @media (max-width: 1200px) {
        display: flex;
    }
}

.sidebar-header {
    height: 150px;
    padding: 0;
}

.close-btn {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

.logo-elogic {
    margin-top: 30px;
    margin-bottom: 15px;

    img {
        width: 100%;
    }
}

body.rtl {
    .sidebar {
        .create-new-project {
            .base-btn {
                padding-right: 36px;
                padding-left: 0;
            }
            .add-icon {
                left: -5px;
                right: unset;
                svg {
                    right: 10px;
                    left: unset;
                }
            }
        }
    }

    .close-btn {
        left: 0;
        right: unset;
    }
}
</style>
