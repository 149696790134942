import {watch} from "vue";

const debounce = (func, wait, immediate) => {
    let timeout;
    return function executedFunction() {
        const context = this;
        const args = arguments;

        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

const doAfterAvailable = (ref, callBack) => { // or    async callBack
    return new Promise((resolve, reject) => {
        const unwatch = {immediate: false, value: () => {}};
        unwatch.value = watch(ref, () => {
            if(!ref.value) return;
            let response;
            try{
                response = callBack();
            }
            catch (e){
                reject(e);
            }

            if(response && typeof response.then === "function"){
                response.then((data) => {
                    unwatch.value();
                    unwatch.immediate = true;
                    resolve(data);
                }).catch((e) => {
                    reject(e);
                })
            }
            else{
                unwatch.value();
                unwatch.immediate = true;
                resolve(response);
            }
        }, {immediate: true});
        
        if(unwatch.immediate) unwatch.value();
    })
}

export { 
    debounce,
    doAfterAvailable
};
