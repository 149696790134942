import { defineStore } from 'pinia';
import axios from "axios";
import { environment } from 'Common/environments/environment';

export const useApiKeyStore = defineStore('apiKeyStore', {
    state: () => {
        return {
            apiKeys: [],
            stats: null,
        }
    },
    getters: {

    },
    actions: {
        getApiKeyStats() {
            return axios.post(environment.apiKeysServiceUrl + '/apikey/stats').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.stats = (response.data.data || {});
            });
        },
        loadApiKeyById(apiKeyExternalId) {
            return axios.post(environment.apiKeysServiceUrl + '/apikey/getByExternalId', {
                data: {apiKeyExternalId}
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },
        loadApiKeys(filter = {}) {
            return axios.post(environment.apiKeysServiceUrl + '/apikey/search', {
                data: {
                    filter: {
                        ...filter,
                    }
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                const data = response.data.data || [];
                this.apiKeys = data;
                return data;
            });
        },
        loadReport() {
            return axios.post(environment.apiKeysServiceUrl + '/apikey/report', {
                data: {}
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || [];
            });
        },
        addApiKey(query) {
            return axios.post(environment.apiKeysServiceUrl + '/apikey/insert', {
                data: query
            }).then((response) => {
                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }
                return response.data.data || {};
            });
        },
        editApiKey(query) {
            return axios.post(environment.apiKeysServiceUrl + '/apikey/update', {
                data: query
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return query;
            });
        },
        updateWhiteList(query) {
            return axios.post(environment.apiKeysServiceUrl + '/apikey/updateWhiteList', {
                data: query
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return query;
            });
        },
        deleteApiKey(apiKeyExternalId) {
            return axios.post(environment.apiKeysServiceUrl + '/apikey/delete', {
                data: {
                    apiKeyExternalId: apiKeyExternalId
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                this.apiKeys = this.apiKeys.filter((item) => item.apiKeyExternalId !== apiKeyExternalId);
            });
        },
        getApiKeyByExternalId(apiKeyExternalId){
            return this.apiKeys.find((item) => item.apiKeyExternalId === apiKeyExternalId);
        }
    }
})
