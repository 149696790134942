import en from 'Common/locales/en.json';
import es from 'Common/locales/es.json';
import fr from 'Common/locales/fr.json';
import pt from 'Common/locales/pt.json';

export default {
    en,
    fr,
    es,
    pt
}
