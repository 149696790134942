<template>
  <span v-if="customerSupportStore?.csStats?.openedCases > 0" class="sidebar-item-counter">
    {{customerSupportStore?.csStats?.openedCases}}
  </span>
</template>

<script>
import {useUserStore} from "Common/stores/userStore";
import {useCustomerSupportStore} from "Common/stores/customerSupportStore";
import {shallowRef, onMounted} from "vue";

export default {
  setup(){
    const userStore = useUserStore();
    const customerSupportStore = useCustomerSupportStore();
    

    onMounted(() => {
      customerSupportStore.getCustomerSupportStats().catch((err) => {
        console.log(err);
      });
    })

    return {
      userStore,
      customerSupportStore,
    }
  },
}
</script>
<style lang="scss" scoped>
  .sidebar-item-counter {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
  }

body.rtl {
  .sidebar-item-counter {
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>