<template>
  <div class="base-input" :class="this.class">
    <div v-if="name" class="base-input-header base-input-label">
      <div>
        {{name}}
        <span v-if="isRequired" class="input-required">*</span>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <slot name="subNameContent"></slot>
      </div>
    </div>
    <label class="base-input-container" :class="{'base-input-disabled': disabled}">
      <span v-if="showPlaceholder"
            class="input-placeholder">{{placeholder}}
      </span>
      <input
          v-model="value"
          :disabled="disabled"
          :maxlength="maxlength"
          class="base-input-control"
          :type="isViewPassword ? 'text' : 'password'"
          v-bind="attrs"
      />
      <div class="base-input-eye" @click="onEye">
        <span v-show="!isViewPassword"><Icon name="new-eye-close" /></span>
        <span v-show="isViewPassword"><Icon name="new-eye" /></span>
      </div>
    </label>
    <div v-if="error" class="base-input-error">
      <Icon name="error"/>
      <div v-html="error"></div>
    </div>
  </div>
</template>

<script>

import Icon from 'Common/components/svg';

export default {
  components: {Icon},
  name: 'BaseInputString',
  props: {
    name: String,
    maxlength: String,
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {type: Boolean, required: false, default: false},
    modelValue: String,
    error: String,
    class: String,
    attrs: {type: Object, required: false, default: {}},
    placeholder: [String, Boolean],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isViewPassword: false, 
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    showPlaceholder: function (){
      if(!this.placeholder) return false;
      if(this.value === null || this.value === undefined || this.value.toString() === '') return true;
      return false;
    }
  },
  methods: {
    onEye() {
      this.isViewPassword = !this.isViewPassword;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~Common/assets/styles/themes/light/light-theme-colors.scss";
.base-input{
  display: flex;
  flex-direction: column;
  .base-input-label{
    margin-bottom: 8px;
    line-height: 14px;
  }
  .base-input-container{
    position: relative;
    padding: 17px 50px 17px 16px;
    border: 1px solid white;
    border-radius: 8px;
  }
  .input-placeholder{
    color: $c-text-secondary;
  }
  .base-input-eye{
    position: absolute;
    cursor: pointer;
    right: 17px;
  }
  .base-input-error{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    svg{
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}

body.rtl {
  .base-input{
    .base-input-container{
      padding: 17px 16px 17px 50px;
    }
    .base-input-eye{
      left: 17px;
      right: unset;
    }
    .base-input-error{
      svg{
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
}
</style>
