import BaseDropdown from "Common/components/controls/BaseDropdown";
import BaseCheckbox from "Common/components/controls/BaseCheckbox";
import {shallowRef, ref} from "vue";
import dayjs from "dayjs";

const FilterTypes = {
    dropdown:{
        default: 'dropdown.default',
        dateRange: 'dropdown.dateRange',
    },
    checkbox:{
        default: 'checkbox.default'
    }
}

export {
    FilterTypes,
    useBreadcrumbFilter,
}

function useBreadcrumbFilter(t, defaultItemValues){ // [ { id: '', type: '', defaultValue: '' } ]
    const _defaultItemValues = defaultItemValues || [];
    const filter = ref([]);
    
    function initFilter(){
        filter.value = [];
        
        for(const item of _defaultItemValues){
            switch (item.type) {
                case 'dropdown.default': {
                    const modelValue = ref(item.hasOwnProperty('defaultValue') ? item.defaultValue : null);
                    filter.value.push({
                        id: item.id,
                        type: item.type,
                        value: modelValue,
                        component: shallowRef(BaseDropdown),
                        props: {
                            name: item.name,
                            modelValue: modelValue,
                            'onUpdate:modelValue': (v) => {modelValue.value = v},
                            options: item.options,
                            canClear: item.canClear ?? true,
                        }
                    })
                } break;
                case 'dropdown.dateRange': {
                    const modelValue = ref(item.hasOwnProperty('defaultValue') ? item.defaultValue : null);
                    filter.value.push({
                        id: item.id,
                        type: item.type,
                        value: modelValue,
                        component: shallowRef(BaseDropdown),
                        props: {
                            name: item.name ?? t('common.datesRange'),
                            modelValue: modelValue,
                            'onUpdate:modelValue': (v) => {modelValue.value = v},
                            options: [
                                {label: t('common.last24hours'), value: [dayjs().subtract(1,'day').toDate(), dayjs().toDate()]},
                                {label: t('common.last7days'), value: [dayjs().subtract(7,'day').toDate(), dayjs().toDate()]},
                                {label: t('common.last30days'), value: [dayjs().subtract(30,'day').toDate(), dayjs().toDate()]},
                            ],
                            canClear: false
                        }
                    })
                } break;
                case 'checkbox.default': {
                    const modelValue = ref(item.hasOwnProperty('defaultValue') ? item.defaultValue : null);
                    filter.value.push({
                        id: item.id,
                        type: item.type,
                        value: modelValue,
                        component: shallowRef(BaseCheckbox),
                        props: {
                            name: item.name ?? '',
                            modelValue: modelValue,
                            'onUpdate:modelValue': (v) => {modelValue.value = v},
                        }
                    })
                } break;
            }
        }
    }
    
    initFilter();

    function resetFilter(){
        initFilter();
    }

    function getValues(){
        const values = {};
        filter.value.forEach(f => {
            values[f.id] = f.value;
        })
        return values;
    }

    return {
        filter,
        resetFilter,
        getValues,
    }
}