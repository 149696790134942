<template>
    <BaseDropdown
        :name="name"
        :model-value="_value"
        :options="_options"
        :error="error"
        :is-required="isRequired"
        searchable
        mode="multiple"
        :label-function="dropdownLabel"
        :closeOnSelect="false"
        @select="onSelect"
        @deselect="onDeselect"
        @clear="onClear"
        custom-options
        class="dropdown-checkboxes"
        :placeholder="t('dropdown.dropdownLabel0')"
    >
        <template #option="{option}">
            <div class="dropdown-custom-option" :class="{'select-all': option.value === '_all'}">
                <BaseCheckbox class="option-checkbox"
                              :model-value="_value.includes(option.value)"/>
                {{ option.label }}
            </div>
        </template>
    </BaseDropdown>
</template>

<script setup>
import {ref, watch, computed} from "vue";
import {useI18n} from "vue-i18n";

import BaseDropdown from "Common/components/controls/BaseDropdown";
import BaseCheckbox from "Common/components/controls/BaseCheckbox";

const {t, te} = useI18n({useScope: 'global'});
const props = defineProps({
    name: String,
    modelValue: Array,
    options: Array,
    error: String,
    isRequired: {
        type: Boolean,
        default: false
    },
})
const emit = defineEmits(['update:modelValue']);

const _value = ref([]); 
const _options = computed(() => {
    return [
        {value: '_all', label: t('dropdown.selectAll')},
        ...props.options
    ]
})

watch(_value, () => {
    emit('update:modelValue', _value.value.filter(v => v !== '_all'));
})

watch(() => props.modelValue, () => {
    if(props.modelValue?.every && !props.modelValue.every(v => _value.value.includes(v))){
        if(props.modelValue.length === props.options.length){
            _value.value = _options.value.map(o => o.value);
        }
        else {
            _value.value = props.modelValue;
        }
    }
})

function dropdownLabel(n) {
    n = n.length;
    //if (!n || n === 0) return `${t('dropdown.dropdownLabel0')}`;
    if (n !== _options.value.length) return `${n} ${t('dropdown.dropdownLabelN')}`;
    else return `${t('dropdown.dropdownLabelAll')}`;
}

function onSelect(o) {
    if(o === '_all'){
        _value.value = _options.value.map(o => o.value);
    }
    else {
        _value.value = [..._value.value, o];
        if(!_value.value.includes('_all') && _value.value.length === props.options.length){
            _value.value = [..._value.value, '_all'];
        }
    }
}

function onDeselect(o) {
    if(o === '_all'){
        _value.value = [];
    }
    else {
        _value.value = _value.value.filter(v => v !== o);
        if(_value.value.includes('_all') && _value.value.length === _options.value.length - 1){
            _value.value = _value.value.filter(v => v !== '_all');
        }
    }
}

function onClear() {
    _value.value = [];
}


</script>

<style lang="scss" scoped>
@import "~Common/assets/styles/themes/light/light-theme-colors.scss";

.dropdown-checkboxes:deep(.base-dropdown-container){
    .multiselect {
        .multiselect-option{
            padding: 0;
            &.is-selected:not(.is-pointed) {
                background-color: $c-white;
                color: $c-secondary-text-pl;
            }
        }
    }
}

.dropdown-custom-option{
    display: flex;
    align-items: center;
    padding: 8px 12px;
    width: 100%;
    .option-checkbox {
        pointer-events: none;
        margin: 0 12px 0 12px;
    }
    &.select-all{
        border-bottom: 1px solid $c-border-primary;
    }
}



</style>
