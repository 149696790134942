function disableDocumentScroll() {
    document.body.classList.add('modal-opened')
}
function enableDocumentScroll() {
    document.body.classList.remove('modal-opened')
}


export  {
    disableDocumentScroll,
    enableDocumentScroll,
}