<template>
  <div v-if="lvaData?.text" class="lva-wrapper" :class="{'is-audio': type === 'audio'}">
    <div class="lva" :style="{background:lvaData.background, color: lvaData.color}">
      {{ lvaData.text }}
    </div>
  </div>
</template>
<script setup>
import {ref, watch} from 'vue'
import {enumLvaOnlineData} from '@/utils/dictionary'

const props = defineProps([
  'lvaStatus',
  'type'
])

const lvaData = ref({})

let timeout;

watch(
    () => props.lvaStatus,
    () => {
      const lvaStatus = props.lvaStatus;
      if (!lvaStatus) return;
      lvaData.value = enumLvaOnlineData[lvaStatus.toString()];

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        lvaData.value = {}
      }, 1500)
    },
)

</script>
<style src="./LVAOnline.ce.scss"></style>
