<template>
  <component v-if="currentIcon" :is="currentIcon" />
</template>
<script>

import {watch, shallowRef} from "vue";

export default {
  props: {
    name: {type: String },
  },
  setup(props) {
    // use shallowRef to remove unnecessary optimizations
    const currentIcon = shallowRef('');

    watch(() => props.name, () => {
      import(`Common/assets/icons/${props.name}.svg?inline`).then(val => {
        // val is a Module has default
        currentIcon.value = val.default;
      });
    }, {immediate: true})
    
    return {
      currentIcon
    };
  }
}
</script>