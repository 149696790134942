<template>
  <modal :visible="organizationStore.showPhoneVerificationModal" :close="onCloseModal" className="phone-verification-modal">
    <div class="header">
      <div v-if="_wasVerified" class="title">
        {{ t("phoneVerification.newPhoneNumber") }}
      </div>
      <div v-else-if="_phoneNumberAlreadyGotGift" class="title">
        {{ t("phoneVerification.registrationCompleteTitle") }}
      </div>
      <div v-else class="title">
        {{ t("phoneVerification.phoneVerifyTitle") }}
      </div>
      <div class="modal-close" @click="onCloseModal">
        <IconClose/>
      </div>
    </div>
    <div class="body">
      <div class="text-center" v-if="!_phoneNumberAlreadyGotGift">
        <img v-if="_wasVerified"  width="100" height="100" src="~Common/assets/icons/telephone.svg">
        <img v-else class="gif-image" src="~Common/assets/imgs/confetti-flat.gif">
      </div>

      <div class="body-title">
        <template v-if="step === steps.finish">
          <template v-if="_phoneNumberAlreadyGotGift">{{t("phoneVerification.welcomeAboard")}}</template>
          <template v-else-if="_wasVerified">{{t("phoneVerification.thankYou")}}</template>
          <template v-else>{{t("phoneVerification.hurray")}}</template>
        </template>
        <template v-else>
          <template v-if="_wasVerified"></template>
          <template v-else>{{t("phoneVerification.welcomeAboard")}}</template>
        </template>
      </div>

      <template v-if="_phoneNumberAlreadyGotGift">
        <div class="body-text">{{t("phoneVerification.registrationCompleteText1Org")}}</div>
      </template>

      <template v-else-if="step === steps.phone">
        <div v-if="_wasVerified" class="body-text">{{t("phoneVerification.phoneNumberText4")}}</div>
        <div v-else-if="organizationStore.organization?.distributorExternalId" class="body-text" v-html="t('phoneVerification.phoneNumberText1OrgByDistributor', {distributorName: organizationStore.organization?.distributorName})"></div>
        <div v-else class="body-text" v-html="t('phoneVerification.phoneNumberText1Org')"></div>
        <BaseInputPhone
            :name="t('common.phoneNumber')"
            v-model="phoneData.phoneNumber"
            :is-required="true"
            autoDefaultCountry
            :auto-format="false"
            :error="phoneErrors.phoneNumber"
            @validate="onValidPhoneNumber"
        />
      </template>

      <template v-else-if="step === steps.code">
        <div v-if="_wasVerified" class="body-text">{{t("phoneVerification.phoneNumberText4")}}</div>
        <div v-else class="body-text">{{t("phoneVerification.phoneNumberText2")}}</div>

        <BaseInputString
            v-if="_wasVerified"
            :disabled="true"
            :name="t('common.phoneNumber')"
            :model-value="phoneData.phoneNumber"
            :is-required="true"
            autoDefaultCountry
            :auto-format="false"
            :error="phoneErrors.phoneNumber"
            class="mb-4"
        >
          <template #subNameContent>
            <div class="link-btn" @click="onChangePhone">{{ t("phoneVerification.changePhoneNumber") }}</div>
          </template>
        </BaseInputString>

        <BaseInputString
            :name="_wasVerified ? t('common.verificationCode') : t('common.code')"
            class="w-100"
            v-model="codeData.code"
            :is-required="true"
            :error="codeErrors.code"
        />
        <div class="d-flex justify-content-center my-2" v-if="needReCaptcha && RECAPTCHA_ENABLED">
          <vue-recaptcha :sitekey="RECAPTCHA_SITE_KEY"
                         ref="recaptcha"
                         @verify="onCaptchaVerify"
                         @expired="captchaToken = ''"
          />
        </div>
        <div class="mt-4" v-if="_wasVerified">
          {{ t("phoneVerification.phoneNumberText5") }}&nbsp;<a class="link-btn" @click="sendRequest" href="#">
          {{ t("phoneVerification.phoneNumberText6") }}
        </a>
        </div>
      </template>

      <template v-else-if="step === steps.finish">
        <div v-if="_wasVerified" class="body-text mb-0">{{t("phoneVerification.phoneNumberText3")}}</div>
        <div v-else class="body-text mb-0">{{t("phoneVerification.phoneNumberText3Bonus")}}</div>
      </template>

    </div>

    <template v-if="_phoneNumberAlreadyGotGift">
      <div class="footer justify-content-center">
        <div class="footer-container">
          <BaseButton class="w-100" @click="onCloseModal" :loading="pending">{{ t("phoneVerification.thankYou") }}</BaseButton>
        </div>
      </div>
    </template>
    <template v-else-if="step === steps.phone">
      <div class="footer justify-content-center">
        <div class="footer-container">
          <BaseButton class="w-100" @click="sendRequest" :loading="pending">{{ t("phoneVerification.getVerificationCode") }}</BaseButton>
        </div>
      </div>
    </template>
    <template v-else-if="step === steps.code">
      <div class="footer gap-3">
        <div class="footer-container">
          <BaseButton
              v-if="!_wasVerified"
              @click="sendRequest"
              :loading="pending"
              outline
              class="w-100 p-0"
          >
            {{ t("phoneVerification.requestCodeAgain") }}
          </BaseButton>
          <BaseButton
              @click="onCheckCode"
              :loading="pending"
              :disabled="(needReCaptcha && RECAPTCHA_ENABLED && !captchaToken)"
              class="w-100 p-0"
          >
            {{ _wasVerified ? t("common.submit") : (appStore.isMobile ? t("common.submit") : t("phoneVerification.submitVerificationCode")) }}
          </BaseButton>
        </div>
      </div>
      <div class="footer-or">
        <div v-if="!_wasVerified" class="d-flex align-items-center gap-2 line-height-100">
          <div class="separate-line"></div>
          <div>{{ t("phoneVerification.or") }}</div>
          <div class="separate-line"></div>
        </div>
      </div>
      <div v-if="!_wasVerified"  class="padding-24 d-flex justify-content-center">
        <div class="link-btn link-btn-style" @click="onChangePhone">{{ t("phoneVerification.changePhoneNumber") }}</div>
      </div>
    </template>
    <template v-else-if="step === steps.finish">
      <div class="footer justify-content-center">
        <div class="footer-container justify-content-center">
          <BaseButton @click="onCloseModal" :loading="pending">{{ t("phoneVerification.gotIt") }}</BaseButton>
        </div>
      </div>
    </template>
  </modal>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import IconClose from "Common/assets/icons/close.svg?inline";
import BaseInputPhone from "Common/components/controls/BaseInputPhone";
import BaseInputString from "Common/components/controls/BaseInputString";
import BaseButton from "Common/components/controls/BaseButton";
import {watch, computed, ref, shallowRef} from "vue";
import {createFormErrors} from 'Common/utility/formErrors';
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import {useBillingStore} from "Common/stores/billingStore";
import {useAppStore} from "Common/stores/appStore";
import { VueRecaptcha  } from 'vue-recaptcha';

const organizationStore = useOrganizationStore();
const affiliationStore = useAffiliationStore();
const billingStore = useBillingStore();
const appStore = useAppStore();

const {t, te} = useI18n({useScope: 'global'});

const badgeNotify = useBadgeNotify();

const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
const RECAPTCHA_ENABLED = process.env.VUE_APP_RECAPTCHA_ENABLED;
const captchaToken = shallowRef('');
const recaptcha = shallowRef(null);

const codeAttempts = shallowRef(0);
const needReCaptcha = computed(() => {
  return codeAttempts.value > 1;
});

const translationOrder = ['phoneVerification.errorList', 'twilio.errorList', 'common.errorList'];
const steps = {
  phone: "phone",
  code: "code",
  finish: "finish"
}

//there is a problem with validation when i use preset valid phone and then ctrl+v another valid phone   (onValidPhoneNumber is not exec)
const phoneData = ref({
  phoneNumber: '',
});
const _wasVerified = shallowRef(false);
const _phoneNumberAlreadyGotGift = shallowRef(false);
const couponInfo = ref(null);

watch(() => organizationStore.showPhoneVerificationModal, () => {
  if(organizationStore.showPhoneVerificationModal){
    if(organizationStore.verificationPhoneNumber && organizationStore.verificationPhoneNumber.toString()[0] === '+'){
      phoneData.value.phoneNumber = organizationStore.verificationPhoneNumber;
    }
    _wasVerified.value = organizationStore.isPhoneVerified;
  }
}, {immediate: true})

const phoneNumberData = ref({valid: true});
const step = shallowRef(steps.phone);

const {errors: phoneErrors, showHiddenErrors: showPhoneErrors, addErrors: addPhoneErrors} = createFormErrors(t, te, [phoneData, phoneNumberData], () => {
  const newErrors = {};
  if(!phoneNumberData.value.valid) newErrors.phoneNumber = '_invalid_phone';
  return newErrors;
},{
  translationOrder
});

const codeData = ref({
  code: '',
});
const {errors: codeErrors, showHiddenErrors: showCodeErrors, addErrors: addCodeErrors} = createFormErrors(t, te, codeData,
    () => ({}),
    {
      requiredFields: Object.keys(codeData.value),
      translationOrder
    }
);

const pending = shallowRef(false);

function onCloseModal() {
  if (pending.value)
    return;

  organizationStore.showPhoneVerificationModal = false;
  step.value = steps.phone;
}

function sendRequest() {
  if (showPhoneErrors())
    return;

  pending.value = true;

  let number = phoneNumberData.value.number;
  if(!number && phoneNumberData.value.valid) {
    number = phoneData.value.phoneNumber;
  }

  if(!number){
    addPhoneErrors({phoneNumber: 'required'}, 'hiddenReactive');
    showPhoneErrors();
    return;
  }

  organizationStore.sendPhoneVerification(number).then(() => {
    pending.value = false;
    codeAttempts.value = 0;
    codeData.value.code = '';
    step.value = steps.code;
  }, err => {
    badgeNotify.show({
      text: getErrorMessage(err),
      error: true
    });
    pending.value = false;
  });
}

function onCheckCode() {
  if (showCodeErrors())
    return;

  pending.value = true;

  organizationStore.checkPhoneVerification({
    code: codeData.value.code,
    ...(needReCaptcha.value && RECAPTCHA_ENABLED ? {captchaToken: captchaToken.value} : {})
  }).then((data) => {
    if(organizationStore.organization) {
      organizationStore.organization.phoneVerified = true;
      organizationStore.organization.phoneNumber = phoneNumberData.value.number;
    }
    step.value = steps.finish;
    _phoneNumberAlreadyGotGift.value = data.phoneNumberAlreadyGotGift
    billingStore.getBalance();
  }).catch(err => {
    recaptcha.value?.reset();
    captchaToken.value = '';
    if(err.errorCode === "incorrect_code" && codeAttempts.value === 1) err.errorCode = 'incorrect_code_2'; // === 1 because it will increased after (in .finally)
    badgeNotify.show({
      text: getErrorMessage(err),
      error: true
    });
  }).finally(() => {
    codeAttempts.value++;
    pending.value = false;
  });
}

function getErrorMessage(err) {
  let message = t('common.errorList.sorrySomethingWrong');
  if(err.errorCode){
    const _message = translationOrder.map(g => `${g}.${err.errorCode}`).find(te);
    message = _message ? t(_message) : message;
  }
  return message;
}

function onValidPhoneNumber(data) {
  phoneNumberData.value = data;
}

function onChangePhone() {
  step.value = steps.phone;
}

function onCaptchaVerify(r) {
  captchaToken.value = r;
}


</script>
<style lang="scss">

@import "~Common/assets/styles/themes/light/light-theme-colors.scss";
@mixin font($size, $weight, $line: 100%, $letter:-0.02em) {
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $line;
  letter-spacing: $letter;
}

.phone-verification-modal {
  max-width: 500px;
  .footer-or{
    display: flex;
    flex-direction: column;
    padding: 0 36px;
  }
  .footer-container{
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 24px 36px 16px 36px;
    max-width: 500px;
  }
  .footer{
    max-width: 100% !important;
    padding: 0 !important;
    border-top: 1px solid #A3AED0 !important;
  }
  .gif-image{
    width: 150px;
    height: 150px;
  }
  .body-title {
    @include font(20px, 500, 26px);
    color: $c-blue-accent;
    text-align: center;
    margin: 24px 0;
  }
  .body-text {
    @include font(16px, 400, 21px);
    text-align: center;
    margin-bottom: 30px;
  }

  .info-0 {
    @include font(14px, 400, 18px);
    color: $c-secondary-text-pl;
  }

  .info-1 {
    @include font(16px, 400, 20px);
  }

  .info-2 {
    @include font(14px, 500, 14px);
  }

  .info-3 {
    @include font(12px, 400, 12px);
    color: $c-secondary-text-pl;
  }

  .char-counter {
    @include font(12px, 400, 12px);
    margin-top: 8px;
    float: right;
    color: $c-secondary-text-pl;
  }

  .info-4 {
    @include font(36px, 400, 47px);
    color: $c-blue-accent;
    text-align: center;
    margin: 24px 0;
  }

  .info-5 {
    @include font(16px, 400, 20px);
    text-align: center;
  }

  .separate-line{
    height: 1px;
    width: 100%;
    background-color: $c-text-secondary;
  }
  .link-btn-style {
    @include font(16px, 400, 100%);
    text-align: center;
  }
  .padding-t-24{
    padding-top: 24px;
  }
  .padding-24{
    padding: 16px 24px 24px 24px;
  }
  .line-height-100{
    line-height: 100%;
  }
}

.mobile-device{
  .phone-verification-modal{
    max-width: 100%;
    .footer-container,
    .footer-or,
    .body{
      padding-left: 24px;
      padding-right: 24px;
      max-width: 530px;
      width: 100%;
      margin: 0 auto;
    }
  }
}

body.rtl {
  .phone-verification-modal {
    .char-counter {
      float: left;
    }

    .body-title, .body-text {
      font-family: var(--bs-body-font-family);
    }
  }
}

</style>
