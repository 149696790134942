<template>
    <DashboardBlank>
        <template #header>
            <div>{{ t('affiliate.dashboard.conversionUsecase') }}</div>
            <div class="flex-grow-1"></div>
        </template>
        <template #body>
            <div class="d-flex flex-column gap-2 mb-4 px-4">
                <div class="d-flex w-100 position-relative align-items-center justify-content-center"
                     style="height: 100%">
                    <template v-if="noData">
                        <div class="chart-graph"/>
                        <div class="total-sum">
                            <div class="total-sum-text">{{ t('common.noConversions') }}</div>
                            <div class="total-sum-value">{{totalCount}}</div>
                        </div>
                    </template>
                    <template v-else>
                        <v-chart :option="options" autoresize class="chart-graph"/>
                        <div class="total-sum">
                            <div class="total-sum-text">{{t('common.totalSum')}}</div>
                            <div class="total-sum-value">{{totalCount}}</div>
                        </div>
                    </template>
                </div>
                <div class="legend" v-if="!noData">
                    <div class="hidden-area left" :style="`width: ${leftHiddenWidth}`"></div>
                    <div class="scrolled" ref="errorsScrollContainer" @wheel.prevent="onWheel">
                        <template v-for="item in options.series[0].data">
                            <div class="legend-item">
                                <div class="legend-item-dot" :style="`background-color: ${item.itemStyle.color}`"></div>
                                <div class="legend-item-text">{{ item.name }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="hidden-area right" :style="`width: ${rightHiddenWidth}`"></div>
                </div>
            </div>
        </template>
    </DashboardBlank>
</template>

<script setup>

import DashboardBlank from "./DashboardBlank";
import VChart from "vue-echarts";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import {useI18n} from "vue-i18n";
import {computed, onMounted, ref} from "vue";

import getChartBarOptions from "../charts/conversion-by-usecase";


const affiliationStore = useAffiliationStore();
const {t, locale} = useI18n({useScope: 'global'});
const errorsScrollContainer = ref(null);
const errorsScrollContainerTo = ref(0);
const errorsScrollContainerScrolling = ref(null);

const data = ref([]);

const totalCount = computed(() => {
    return data.value.reduce((sum, curr) => {
        return sum + curr.totalCount;
    }, 0);
});

const noData = computed(() => {
    return totalCount.value === 0;
});

const options = computed(() => {
    return getChartBarOptions(data.value);
})

onMounted(() => {
    affiliationStore.getUsecaseConversion().then(_data => {
        data.value = _data;
    }).catch(err => {
        console.log(err);
    })
})

const leftHiddenWidth = computed(() => {
    if (!errorsScrollContainer.value) return '0px';
    if (errorsScrollContainerTo.value < 0) return '0px';
    if (errorsScrollContainerTo.value > 0 && errorsScrollContainerTo.value < 60) return errorsScrollContainerTo.value + 'px';
    if (errorsScrollContainerTo.value > 60) return '60px';
});
const rightHiddenWidth = computed(() => {
    if (!errorsScrollContainer.value) return '0px';
    if (errorsScrollContainerTo.value < errorsScrollContainer.value.scrollWidth - 60) return '60px';
    if (errorsScrollContainerTo.value > errorsScrollContainer.value.scrollWidth - 60 && errorsScrollContainerTo.value < errorsScrollContainer.value.scrollWidth) return errorsScrollContainer.value.scrollWidth - errorsScrollContainerTo.value + 'px';
    if (errorsScrollContainerTo.value > errorsScrollContainer.value.scrollWidth) return '0px';
})

function onWheel(event) {
    if (!errorsScrollContainerScrolling.value) {
        onScrollLeft();
    } else {
        errorsScrollContainerTo.value += event.deltaY;
        if (errorsScrollContainerTo.value < 0) errorsScrollContainerTo.value = 0;
        if (errorsScrollContainerTo.value > errorsScrollContainer.value.scrollWidth) errorsScrollContainerTo.value = errorsScrollContainer.value.scrollWidth;
    }
}

function onScrollLeft() {
    errorsScrollContainer.value.scroll({
        left: errorsScrollContainerTo.value,
        top: 0,
        behavior: 'smooth'
    });
    errorsScrollContainerScrolling.value = setTimeout(() => {
        errorsScrollContainerScrolling.value = null;
        if (errorsScrollContainer.value.scrollLeft !== errorsScrollContainerTo.value) {
            onScrollLeft();
        }
    }, 200);
}

</script>

<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';

.chart-graph {
    height: 273px;
}

.legend {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 6px;
    background: $c-input-disabled-bg;
    border-radius: 6px;

    .legend-item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .legend-item-dot {
        width: 8px;
        min-width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 6px;
    }

    .legend-item-text {
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        color: $c-secondary-text-pl;
    }
}

.scrolled {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.hidden-area {
    position: absolute;
    min-height: 40px;

    &.left {
        background-image: linear-gradient(to right, rgba(246, 246, 248, 1), rgba(246, 246, 248, 0));
        left: 0;
    }

    &.right {
        background-image: linear-gradient(to right, rgba(246, 246, 248, 0), rgba(246, 246, 248, 1));
        right: 0;
    }
}

.total-sum {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .total-sum-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
    }

    .total-sum-value {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
    }
}

.see-all {
    cursor: pointer;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $c-blue-accent;

    &.disabled {
        pointer-events: none;
        color: $c-text-secondary;
    }
}

body.rtl {
    .legend {
        .legend-item-dot {
            margin-left: 6px;
            margin-right: 0;
        }
    }

    .hidden-area {
        &.left {
            right: 0;
            left: unset;
        }

        &.right {
            left: 0;
            right: unset;
        }
    }
}
</style>

