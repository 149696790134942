<template>
  <component :is="comp" :attr="$attrs" :class="$attrs.class" :style="$attrs.style">
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData"><slot :name="name" v-bind="slotData" /></template>
  </component>
</template>

<script setup>
import BaseTableDesktop from "./baseTable/BaseTableDesktop"
import BaseTableMobile from "./baseTable/BaseTableMobile"
import {shallowRef} from "vue";
import {useAppStore} from "Common/stores/appStore";

const appStore = useAppStore();

const comp = shallowRef(
  (() => {
    if(appStore.isMobile) return BaseTableMobile;
    else return BaseTableDesktop;
  })()
);

</script>
