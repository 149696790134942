import {computed, shallowRef, ref, watch} from 'vue';
import {getLocale} from '@/locales/index.js';

export function getLocaleTexts(props, localeFn){
    return computed(() => {
        if(props?.value?.localeTexts) return props?.value?.localeTexts;
        return localeFn(getLocale(props?.value?.language));
    });
}

export function getDefinePropsObject(propsDefinition){
    const props = {};
    for(let propName in propsDefinition){
        switch (propsDefinition[propName].typeof){
            case "object": props[propName] = {type: [String, Object], default: propsDefinition.default}; break;
            case "array": props[propName] = {type: [String, Array], default: propsDefinition.default}; break;
            case "number": props[propName] = {type: [String, Number], default: propsDefinition.default}; break;
            case "boolean": props[propName] = {type: [Boolean], default: propsDefinition.default}; break;
            default: props[propName] = String;
        }
    }
    return props;
}

export function validateProps(propsDefinition, props, addValidationFunc = null){ //addValidationFunc(_props)
    const requiredFields = Object.keys(propsDefinition).filter((key) => propsDefinition[key].required)
    
    const resultRef = shallowRef({})
    const propsRef = ref({})
    const isRequiredPropsReadyRef = ref({})
    
    watch(props, () => {
        let result = { isValid: true, message: ""};
        let _props = {};
        for(const pdName in propsDefinition){
            const validation = propsDefinition[pdName];
            let value = props[pdName];
            _props[pdName] = value;
            
            if(value === undefined && validation.default !== undefined){
                value = validation.default;
                _props[pdName] = validation.default;
            }
            if(!validation.required && value === undefined) continue;
            if(validation.required && value === undefined) {
                result = { isValid: false, message: `The ${pdName} is required!`};
                break;
            }
            if(validation.typeof){
                const validationTypes = Array.isArray(validation.typeof) ? validation.typeof : [validation.typeof];
                const actualValue = getActualValue(validationTypes, value);
                const actualType = getTypeOf(actualValue);
                
                if(validationTypes.includes(actualType)){
                    value = actualValue;
                    _props[pdName] = value;
                }
                else{
                    result = { isValid: false, message: `The ${pdName} has invalid type!`};
                    break;
                }
            }
            if(validation.existProps){
                let success = true;
                for(const p of validation.existProps){
                    if(value[p] === undefined){
                        success = false;
                        result = { isValid: false, message: `The ${pdName} is invalid! Property ${p} is missing.`};
                        break;
                    }
                }
                if(!success) break;
            }

            //must be last check in the "for"
            if(validation.validate) {
                const checkResult = validation.validate(value);
                if(checkResult && !checkResult.isValid) {
                    result = checkResult;
                    break;
                }
            }
        }
        if(result.isValid && addValidationFunc){
            const checkResult = addValidationFunc(_props);
            if(checkResult && !checkResult.isValid) {
                result = checkResult;
            }
        }
        
        resultRef.value = result;
        propsRef.value = _props;
        isRequiredPropsReadyRef.value = requiredFields.every((key) => _props.hasOwnProperty(key));
    }, {immediate: true})
    
    
    return {propsValidation: resultRef, props: propsRef, isRequiredPropsReady: isRequiredPropsReadyRef};
}

function getTypeOf(value) {
    if(Array.isArray(value)) return "array";
    else return typeof value;
}

function getActualValue(types, value) {
    if(typeof value !== "string") return value;

    if(value === '' && types.includes('boolean')){
       return true;
    }
    
    try{
        return JSON.parse(value);
    }
    catch (e){
        return value;
    }
}