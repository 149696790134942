import { createRouter } from "vue-router";
import { environment } from 'Common/environments/environment';
import { useAppStore } from 'Common/stores/appStore';
import MainRoutes from '@/routes/main';
import AffiliateRoutes from '@/routes/affiliate';

let router;

let appStore;

switch(window.location.hostname){
    case environment.affiliateDomain: router = createRouter(AffiliateRoutes); break;
    default: {
        router = createRouter(MainRoutes);
        router.beforeEach((to, from, next) => {
            if(!appStore) appStore = useAppStore();
            appStore.checkWelcomeAnalyzeNowModals(to, from);
            next();
        })
    }
}

export default router;
