<template>
  <component :is="changeOnIcon ? 'span' : 'label'" :class="{'base-checkbox': true, 'base-radio': radioButton, 'disabled': disabled}">
    <input
        type="checkbox"
        :disabled="disabled"
        v-model="value"
    >
    <template v-if="leftText">
      <span v-if="html" v-html="name" />
      <span v-else>{{name}}</span>
    </template>
    <span
        @click="() => changeOnIcon && (value = !value)"
        class="base-checkbox-checkmark"
          :class="{
            'base-checkbox-checkmark-mr': name && !leftText, 
            'base-checkbox-checkmark-ml': name && leftText, 
            'base-checkbox-checkmark-checked': value
          }">
      <span v-show="value && !radioButton"><Icon name="check" /></span>
      <span v-show="value && radioButton" class="base-radio-check"></span>
    </span>
    <template v-if="!leftText">
      <span v-if="html" v-html="name" />
      <span v-else>{{name}}</span>
    </template>
    <div v-if="error" class="base-input-error">
      <Icon name="error"/>
      <div class="base-input-error-text" v-html="error"></div>
    </div>
  </component>
</template>

<script setup>

import Icon from 'Common/components/svg';
import {computed} from "vue";

const props = defineProps({
  name: String,
  modelValue: Boolean,
  radioButton: Boolean,
  disabled: {type: Boolean, default: false},
  html: {
    type: Boolean,
    default: false,
  },
  leftText: Boolean,
  error: String,
  swapMeaning: Boolean,
  changeOnIcon: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get() {
    return props.swapMeaning ? !props.modelValue : props.modelValue;
  },
  set(value) {
    emit('update:modelValue', props.swapMeaning ? !value : value)
  }
})

</script>

<style lang="scss" scoped>
.base-checkbox {
  display: flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 18px;
  &.disabled{
    cursor: default;
    .base-checkbox-checkmark {
      opacity: 0.6;
    }
  }
}
/* Hide the browser's default checkbox */
.base-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.base-checkbox-checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
  min-width: 18px;
  border: 2px solid white;
  border-radius: 2px;
  > span {
    display: flex;
  }
}
.base-checkbox-checkmark-mr{
  margin-right: 10px;
}
.base-checkbox-checkmark-ml{
  margin-left: 10px;
}

.base-radio .base-checkbox-checkmark{
  border-radius: 50%;
  border: 2px solid #A3AED0;
  .base-radio-check{
    min-height: 8px;
    min-width: 8px;
    border-radius: 50%;
  }
}

/* On mouse-over, add a grey background color */
.base-checkbox:hover input ~ .base-checkbox-checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.base-checkbox-checkmark-checked {
  background-color: white;
  border: none;
}

.base-radio .base-checkbox-checkmark-checked{
  background-color: unset;
  .base-radio-check{
    background-color: white;
  }
}

body.rtl {
  .base-checkbox-checkmark-mr{
    margin-left: 10px;
    margin-right: 0;
  }
  .base-checkbox-checkmark-ml{
    margin-right: 10px;
    margin-left: 0;
  }
}  

</style>
