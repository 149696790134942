<template>
    <div class="d-flex align-items-center">
        <Icon name="edit" class="edit-icon" :class="{disabled: isEdit}" @click="onEdit"/>
        <div>
            <div class="text" v-if="!isEdit">
                {{data.name}}
            </div>
            <input
                v-else
                v-model="name"
                class="input-control"
                @keypress="onKeypress"
                @keyup="onKeyUp"
                ref="editInput"
            >
        </div>
    </div>
</template>

<script setup>

import Icon from 'Common/components/svg';
import {ref, watch, onMounted, computed} from "vue";

const props = defineProps({
    data: Object,
    class: String,
})
const emits = defineEmits(['save']);

const editInput = ref(null);
const isEdit = ref(false);

const name = ref(null);

function onKeypress(evt) {
    if (evt.which === 13) {
        evt.preventDefault();
        emits('save', {...props.data, name: name.value});
        isEdit.value = false;
    }
}

function onKeyUp(evt) {
    if (evt.which === 27) {
        evt.preventDefault();
        isEdit.value = false;
    }
}

function onEdit(){
    isEdit.value = true;
    name.value = props.data.name;
    setTimeout(() => {
        editInput.value.focus();
    }, 50);
}
        
</script>

<style lang="scss" scoped>
@import "~Common/assets/styles/themes/light/light-theme-colors.scss";

.edit-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    color: $c-blue-accent;
    cursor: pointer;
    &.disabled{
        color: $c-gray-icon;
        cursor: unset;
    }
}
.text{
    margin-left: 24px;
}
.input-control {
    width: 100%;
    margin-left: 12px;
    border-radius: 4px;
    padding: 0 12px;
    border: 1px solid $c-red-accent;
    outline: none;
    &:focus{
        border: 1px solid $c-text-secondary;
    }
}

body.rtl {
  .text{
    margin-right: 24px;
    margin-left: 0;
  }
}
</style>
