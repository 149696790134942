<template>
  <div class="base-input">
    <div class="base-dropdown-container">
      <Multiselect
          mode="single"
          :canClear="false"
          :canDeselect="false"
          openDirection="top"
          :value="modelValue"
          @change="onChange"
          :options="options"
      />
    </div>
  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const props = defineProps( {
  modelValue: String | Array,
  options: Array
});

const emit = defineEmits(['update:modelValue']);
const {t} = useI18n({useScope: 'global'});

function onChange(v) {
  emit('update:modelValue', v);
}

</script>

<style lang="scss" scoped>
@import 'Common/assets/styles/themes/light/light-theme-colors';
  .base-input{
    display: flex;
    flex-direction: column;
    width: 120px;
  }
  .base-dropdown-container{
    &:deep(.multiselect){
      height: 32px;
      min-height: unset;
      padding: 0 12px;
      border: 1px solid white;
      border-radius: 6px;
      background-color: inherit;
      outline: none;
      font-family: inherit;
      font-size: inherit;
      &.is-active{
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .multiselect-caret{
          transform: rotate(-90deg);
        }
      }
      &.is-open-top.is-active{
        border-radius: 0 0 6px 6px;
        .multiselect-dropdown{
          border: 1px solid $c-border-primary;
          border-bottom: none;
          border-radius: 6px 6px 0 0;
        }
      }
      .multiselect-single-label-text{
        font-weight: inherit;
      }
      .multiselect-search,
      .multiselect-multiple-label, 
      .multiselect-placeholder, 
      .multiselect-single-label{
        padding-left: 12px;
        max-width: unset;
      }
      
      .multiselect-caret {
        width: 24px;
        height: 24px;
        -webkit-mask-image: url('~Common/assets/icons/arrow.svg');
        mask-image: url('~Common/assets/icons/arrow.svg');
        transform: rotate(90deg);
        background-color: white;
        mask-size: unset;
        margin: 0;
      }
      .multiselect-dropdown{
        border: 1px solid white;
        border-top: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .multiselect-search{
        background-color: inherit;
      }
    }
  }

body.rtl {
  .base-dropdown-container{
    &:deep(.multiselect){
      &.is-open-top.is-active{
        border-radius: 0 6px 6px 0;
        .multiselect-dropdown{
          border-radius: 6px 0 0 6px;
        }
      }
      .multiselect-search,
      .multiselect-multiple-label, 
      .multiselect-placeholder, 
      .multiselect-single-label{
        padding-right: 12px;
        padding-left: 0;
        right: 0;
        left: unset;
      }
    }
  }
}
</style>
