<template>
  <div class="main-bg-container wrap-768">
    <div class="main-container">
      <BasePreloader v-if="loading" show />

      <div v-else class="main-form-container">
        <div class="second-bg-container">
          <div class="second-bg-doc-container"><img src="~Common/assets/emlo-affiliate.png" alt=""/></div>
        </div>
        <div class="main-input-group">
          <div class="main-input-group-title">{{ t('emailConfirmation.hello_title') }}</div>
          <div class="main-input-group-text">
            {{ t('emailConfirmation.hello_text_1') }}
            {{ userEmail }}
          </div>
          <div class="main-input-group-text">{{ t('emailConfirmation.hello_text_2') }}</div>
          <div class="main-input-group-text">{{ t('emailConfirmation.hello_text_3') }}</div>

          <BaseInputString
            v-if="!userEmail"
            :name="t('common.email')"
            v-model="userEmail"
            :is-required="true"
            class="mb-3"
          />

          <BaseButton outline class="w-100" @click="sendAgain" :loading="loadingSending" :disabled="!userEmail">
            {{ t('common.resend') }}
          </BaseButton>
          <div class="d-flex align-items-center justify-content-center mt-4">
            <router-link to="/login">
              <div class="link-btn login-button">{{ t('common.backToLogin') }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script setup>

import { useUserStore } from 'Common/stores/userStore';
import Icon from "Common/components/svg";
import BaseInputString from "Common/components/controls/BaseInputString";
import BaseButton from "Common/components/controls/BaseButton";
import BasePreloader from "Common/components/controls/BasePreloader";
import {useI18n} from "vue-i18n";
import {onMounted, shallowRef} from "vue";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import router from "@/routes";
import {useRoute} from "vue-router";
import {ROUTES} from "@/utility/routeUtils";

const userStore = useUserStore();
const badgeNotify = useBadgeNotify();
const { t, locale } = useI18n({ useScope: 'global' });
const userEmail = shallowRef('');
const loadingSending = shallowRef(false);
const loading = shallowRef(true);
const route = useRoute();

onMounted(() => {

  if (userStore.getUserToken() && !userStore.user) {

    userStore.loadUser().then(() => {
      userEmail.value = userStore.user?.email || "";
      if (userStore.user?.emailVerified) {
        router.push({name: ROUTES.Dashboard});
      }
    }).catch(err => {
      console.log(err);

      badgeNotify.show({
        text: t('common.errorList.sorrySomethingWrong'),
        error: true
      });

    }).finally(() => {
      loading.value = false;
      userEmail.value = userEmail.value || route.query.email || "";

    });
  } else {
    loading.value = false;
    userEmail.value = route.query.email || "";
  }
});

function sendAgain(){
  loadingSending.value = true;
  userStore.resendVerificationCode(userEmail.value).then(() => {
    badgeNotify.show({
      text: t('common.emailSuccessfullySent'),
      success: true
    })
  }).catch((err) => {
    console.log(err);
    badgeNotify.show({
      text: t('common.errorList.sorrySomethingWrong'),
      error: true
    })
  }).finally(() => {
    loadingSending.value = false;
  });
}
</script>

<style scoped lang="scss" src="Common/assets/styles/themes/light/auth.scss"></style>
