<template>
  <modal :visible="show" :close="onClose" className="breadcrumb-filter">
    <div class="header">
      <div>{{ t('common.filter') }}</div>
      <div class="modal-close" @click="onClose"><Icon name="close"/></div>
    </div>
    <div class="body pt-3 gap-4">
      <template v-for="item in filter">
        <component :is="item.component" v-bind="item.props" />
      </template>
    </div>
    <div class="footer local-footer">
      <BaseButton @click="onAccept">{{t('common.acceptFilter')}}</BaseButton>
      <BaseButton outline @click="onReset">{{t('common.resetFilter')}}</BaseButton>
    </div>
  </modal>
</template>

<script setup>

import { useI18n } from 'vue-i18n';
import Icon from 'Common/components/svg';
import BaseButton from 'Common/components/controls/BaseButton';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps({
  show: Boolean,
  filter: Array
}) 
const emit = defineEmits(['close', 'update:filter', 'accept', 'reset'])

function onAccept(){
  emit('accept');
  onClose();
}

function onReset(){
  emit('reset');
  onClose();
}

function onClose(){
  emit('close');
}

</script>

<style lang="scss">
#modals {
  .modal-content.breadcrumb-filter{
    overflow: unset;
  }
}
</style>

<style scoped lang="scss">
#modals {
  .local-footer{
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
    border-top: 1px solid #A3AED0; 
  }
}

</style>
