<template>
  <div class="badge-notify" :class="{'badge-notify-visible': visible}">
    <div class="d-flex flex-row align-items-center">
      <div v-if="success"><Icon name="success-sm"/></div>
      <div v-if="error"><Icon name="error-sm"/></div>
      <div class="badge-notify-text">{{text}}</div>
    </div>
    <div class="cursor-pointer" @click="hideBadge"><Icon name="close"/></div>
  </div>
</template>

<script>

import Icon from "Common/components/svg";

export default {
  components: {
    Icon,
  },
  props: {
    id: String,
    timeout: {
      type: Number,
      default: 5000
    },
    text: String,
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    class: String,
  },
  emits: ['closeNotify'],
  data(){
    return {
      visible: false
    }
  },
  methods:{
    hideBadge(){
      this.visible = false;
      setTimeout(() => {
        this.$emit('closeNotify', this.id);
      }, 1000);
    }
  },
  mounted(){
    setTimeout(() => {this.visible = true}, 0);
    if(this.timeout > 0) setTimeout(() => {this.hideBadge()}, this.timeout);
  },
}

</script>

<style scoped lang="scss">
@import 'Common/assets/styles/themes/light/light-theme-colors';

.badge-notify{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  width: 520px;
  padding: 15px 30px;
  border-radius: 0 0 20px 20px;
  transition: all 500ms;
  z-index: 3000;
}
.badge-notify-visible{
  top: 0;
}
.badge-notify-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-left: 12px;
  color: $c-sidebar_and_texts;
}

.mobile-device{
  @media (max-width: 767px){ //375 - 768
    .badge-notify{
      width: 100%;
    }
    .badge-notify-text{
      font-size: 16px;
    }
  }
}

body.rtl {
  .badge-notify{
    right: 50%;
    left: unset;
  }
  .badge-notify-text {
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>