export const diamondHeadersDictionary = {
    'EDP-Emotional': 'emotional',
    'EDP-Uneasy': 'uneasy',
    'EDP-Stressful': 'stressful',
    'EDP-Thoughtful': 'thoughtful',
    'EDP-Confident': 'confident',
    'EDP-Concentrated': 'concentrated',
    'EDP-Energetic': 'energetic',
    'EDP-Passionate': 'passionate',
}

export const enumLvaOnlineData = {
    '-3': {name: 'LVA_BADCALMSG', text: 'CALIBRATION ENDED', color: '#FC6853', background: 'rgba(64, 64, 64, 0.75)'},
    '-2': {name: 'LVA_CALIBRATION', text: 'CALIBRATION', color: '#ffffff', background: 'rgba(64, 64, 64, 0.75)'},
    '-1': {name: 'LVA_CALIBRATIONENDED', text: 'CALIBRATION ENDED', color: '#ffffff', background: 'rgba(64, 64, 64, 0.75)'},
    '0': {name: 'LVA_LOW_RISK', text: '...', color: '#C6E090', background: 'rgba(64, 64, 64, 0.75)'},
    '1': {name: 'LVA_HIGHANTICIPATION', text: 'ANTICIPATION FOR REACTION', color: '#92D04A', background: 'rgba(64, 64, 64, 0.75)'},
    '2': {name: 'LVA_EXCITEMENT', text: 'EXCITEMENT', color: '#92D04A', background: 'rgba(64, 64, 64, 0.75)'},
    '3': {name: 'LVA_UNEASY', text: 'UNEASY', color: '#F8CB8B', background: 'rgba(64, 64, 64, 0.75)'},
    '4': {name: 'LVA_CONFUSION', text: 'HIGH UNCERTAINTY', color: '#FFD95A', background: 'rgba(64, 64, 64, 0.75)'},
    '5': {name: 'LVA_STRESS', text: 'STRESS', color: '#F4B06F', background: 'rgba(64, 64, 64, 0.75)'},
    '6': {name: 'LVA_HIGH_ENERGY', text: 'HIGH ENERGY', color: '#90C6E7', background: 'rgba(64, 64, 64, 0.75)'},
    '7': {name: 'LVA_HIGH_CONCENTRATION', text: 'HIGH CONCENTRATION', color: '#75A9DB', background: 'rgba(64, 64, 64, 0.75)'},
    '8': {name: 'LVA_HIGH_EXCITEMENT', text: 'HIGH EXCITEMENT', color: '#92D04A', background: 'rgba(64, 64, 64, 0.75)'},
    '9': {name: 'LVA_HIGH_STRESS', text: 'HIGH STRESS', color: '#F4B06F', background: 'rgba(64, 64, 64, 0.75)'},
    '10': {name: 'LVA_VOICE_MANIPULATION', text: 'VOICE MANIPULATION', color: '#FA60C4', background: 'rgba(64, 64, 64, 0.75)'},
    '11': {name: 'LVA_EXTREME_STRESS', text: 'EXTREME STRESS', color: '#F8CBAD', background: 'rgba(141, 106, 18, 0.75)'},
    '12': {name: 'LVA_EXTREME_EMOTIONNEGATIVE', text: 'EXTREME NEGATIVE EMOTION', color: '#ffffff', background: 'rgba(141, 106, 18, 0.75)'},
    '13': {name: 'LVA_EXTREME_EMOTION', text: 'EXTREME EMOTION', color: '#C6E084', background: 'rgba(141, 106, 18, 0.75)'},
    '14': {name: 'LVA_EXTREME_COG', text: 'INTENSE COGNITIVE EFFORT', color: '#ffffff', background: 'rgba(141, 106, 18, 0.75)'},
    '15': {name: 'LVA_EXTREME_CONCENTRATION', text: 'EXTREME CONCENTRATION', color: '#C1D9D9', background: 'rgba(141, 106, 18, 0.75)'},
    '16': {name: 'LVA_STRESSRELIEF', text: 'STRESS RELIEF', color: '#ffffff', background: 'rgba(65, 63, 101, 0.75)'},
    '17': {name: 'LVA_SUSPECTED', text: 'SUSPICIOUS STATEMENT', color: '#ffffff', background: 'rgba(198, 89, 17, 0.75)'},
    '18': {name: 'LVA_MIDRISK', text: 'MODERATE RISK', color: '#ffffff', background: 'rgba(198, 89, 17, 0.75)'},
    '19': {name: 'LVA_MIDHIGHRISK', text: 'MODERATE-HIGH RISK', color: '#ffffff', background: 'rgba(192, 0, 0, 0.75)'},
    '20': {name: 'LVA_HIGHRISK', text: 'HIGH RISK', color: '#ffffff', background: 'rgba(192, 0, 0, 0.75)'},
}
