﻿import _ from 'lodash';

import en from "@/locales/en.json";
import es from "@/locales/es.json";
import pt from "@/locales/pt.json";

let languages = {
    en,
    es,
    pt
}

export {
    languages,
    getLocale
}

let isMerged = false;

function getLocale(lang = 'en'){
    if(!isMerged){
        languages = _.merge(languages, window.externalTranslations?.diamond || {});
        isMerged = true;
    }

    return languages[lang] || languages['en'];
}