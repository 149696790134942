import {defineStore} from 'pinia';
import axios from "axios";
import {environment} from 'Common/environments/environment';
import {useUserStore} from "Common/stores/userStore";
import {version} from "@/../frontendVersion.json";


export const useCustomerSupportStore = defineStore('customerSupportStore', {
    state: () => {
        return {
            notificationTemplates: null,
            notificationTypes: [],
            technicalIds: null,
            currentCase: {},
            csManagementStats: {
                openedCases: 0
            },
            csStats: {
                openedCases: 0
            }
        }
    },
    getters: {},
    actions: {
        getNotificationTemplate(notificationTemplateExternalId) {
            return axios.post(`${environment.customerSupportService}/notification-templates/getByExternalId`, {
                data: {notificationTemplateExternalId}
            }).then(r => {
                if (r.data.success) {
                    return r.data.data;
                }
                return Promise.reject(r.data);
            });
        },
        testNotificationTemplate(notificationTemplateExternalId) {
            return axios.post(`${environment.customerSupportService}/notification-templates/testByExternalId`, {
                data: {notificationTemplateExternalId}
            }).then(r => {
                if (r.data.success) {
                    return r.data.data;
                }
                return Promise.reject(r.data);
            });
        },

        saveNotificationTemplate(data) {
            const isUpdate = Boolean(data.notificationTemplateExternalId);
            const url = isUpdate ?
                `${environment.customerSupportService}/notification-templates/update` :
                `${environment.customerSupportService}/notification-templates/insert`;

            return axios.post(url, {
                data
            }).then(r => {
                if (r.data.success) {

                    this.technicalIds = null;
                    this.notificationTemplates = null;

                    return r.data.data;
                }
                return Promise.reject(r.data);
            });
        },

        loadNotificationTemplates(groupCode, force) {

            if (!force && this.notificationTemplates) {
                return Promise.resolve(this.notificationTemplates);
            }

            const payload = {
                data: {
                    ...(groupCode ? {groupCode}: {}),
                }
            };

            return axios.post(`${environment.customerSupportService}/notification-templates/search`, payload).then(r => {

                if (!r.data?.success) {
                    return Promise.reject(r.data);
                }

                const data = r.data.data || [];

                if (!force) {
                    this.notificationTemplates = data;
                }

                return data;
            });
        },

        async setRequiredAttention(data) {
            return axios.post(`${environment.customerSupportService}/support-requests/setRequiredAttention`, {
                data
            }).then(r => {
                if (r.data.success) {
                    return r.data.data;
                }
                return Promise.reject(r.data);
            });
        },

        loadCustomerSupportCases(filter) {
            return axios.post(`${environment.customerSupportService}/support-requests/search`, {
                data: filter
            }).then(r => {
                if (!r.data.success) return Promise.reject(r.data);
                return r.data.data || [];
            });
        },

        createCustomerSupportCase(data, file) {
            const fd = new FormData();
            const url = `${environment.customerSupportService}/support-requests/insert`;
            if (file) {
                fd.append('file', file)
            }

            fd.append("data", JSON.stringify(data));

            return axios.post(url, fd, {headers: { "Content-Type": "multipart/form-data" },}).then(r => {
                this.getCustomerSupportStats()
                if (!r.data.success)
                    return Promise.reject(r.data);
                return r.data.data || {};
            });
        },

        createCustomerSupportCaseByAnon(data, file) {
            const url = `${environment.customerSupportService}/support-requests/insertAnon`;

            return axios.post(url, {data}).then(r => {
                return !r.data.success ? Promise.reject(r.data) : r.data.data || {};
            });
        },

        closeCustomerSupportCase(data) {
            return axios.post(`${environment.customerSupportService}/support-requests/update`, {
                data
            }).then(r => {
                this.getCustomerSupportStats()
                if (!r.data.success) Promise.reject(r.data);
                return r.data.data || {};
            });
        },

        updateCustomerSupportCase(data) {
            return axios.post(`${environment.customerSupportService}/support-requests/update`, {
                data
            }).then(r => {
                this.getCustomerSupportStats()
                if (!r.data.success) Promise.reject(r.data);
                return r.data.data || {};
            });
        },
        loadCustomerSupportCaseById(csSupportRequestExternalId) {
            return axios.post(`${environment.customerSupportService}/support-requests/getByExternalId`, {
                data: {csSupportRequestExternalId}
            }).then(r => {
                if (!r.data.success) Promise.reject(r.data);
                r.data.data.messages = r.data.data.messages.map((d, ind) => {
                    return {...d, _index: r.data.data.messages.length - ind};
                });
                return this.currentCase = r.data.data || {};
            });
        },

        insertCustomerSupportCaseMessage(data, file) {
            const url = `${environment.customerSupportService}/support-requests/insertMessage`;
            const fd = new FormData();

            if (file) {
                fd.append('file', file)
            }

            const json = JSON.stringify(data);

            fd.append("data", json);

            return axios.post(url, fd, {headers: { "Content-Type": "multipart/form-data" },}).then(r => {
                this.getCustomerSupportStats()
                if (!r.data?.success) {
                    return Promise.reject(r.data);
                }
                return r.data.data || {};
            });
        },


        getCustomerSupportStats() {
            return axios.post(`${environment.customerSupportService}/support-requests/stats`).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {errorCode: "internal_error"});
                }
                this.csStats = r.data.data || {}
                try{
                    const userStore = useUserStore();
                    if(userStore.user?.roles?.includes('system_admin') || userStore.user?.roles?.includes('customer_spprt_role')){
                        this.getCustomerSupportStatsManagement({data: {isCustomerSupport: true}})
                    }
                } catch (e) {
                    console.error(e)
                }

                return r.data.data || {};
            });
        },

        getCustomerSupportStatsManagement(data = {}) {
            return axios.post(`${environment.customerSupportService}/support-requests/cs-stats`, data).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {errorCode: "internal_error"});
                }
                this.csManagementStats = r.data.data || {}
                return r.data.data || {};
            });
        },

        downloadAttachment(data) {
            return axios.post(`${environment.customerSupportService}/support-requests/downloadMessageFile`, {
                data
            }, {
                responseType: 'arraybuffer' // request to get data as binary
            }).then(r => {

                const contentDisposition = r?.headers['content-disposition'];
                const contentType = r?.headers['content-type'];
                const fileName = (contentDisposition?.match(/filename="?([^"]*)/i) || []) [1];

                // create file link in browser's memory
                const blob = new Blob([r.data], {type: contentType});
                const href = URL.createObjectURL(blob);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName || 'unknown');
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });


        },

        getTopicOptionList(t) {
            return [
                {label: t('common.critical_help'), value: 'critical_help'},
                {label: t('common.suggestion'), value: 'suggestion'},
                {label: t('common.help'), value: 'help'},
                {label: t('common.bug'), value: 'bug'},
            ];

        },

        updateAssignUser(data) {
            return axios.post(`${environment.customerSupportService}/support-requests/assignUser`, {data}).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {errorCode: "internal_error"});
                }
                return r.data.data || {};
            });
        },

        searchCustomerSupports() {
            return axios.post(`${environment.customerSupportService}/support-requests/searchCustomerSupports`, {
                data: {
                    extraFields: [],
                    extraFilterParams: [
                        {status: 'active'},
                    ],
                    params: {
                        fullMatchOnly: true
                    }
                }
            }).then((response) => {
                if (response?.data?.success) {
                    return response.data.data
                }
            })
        },


        clearDraft(key) {
            localStorage.removeItem(`cs_${version}_${key}`);
        },

        loadDraft(key) {
            const v = localStorage.getItem(`cs_${version}_${key}`);
            if (!v) {
                return {}
            }
            try {
                return JSON.parse(v);
            } catch {
                return {};
            }
        },

        saveDraft(key, v) {
            localStorage.setItem(`cs_${version}_${key}`, JSON.stringify(v));
        },

        async getReminder(data) {
            return axios.post(`${environment.customerSupportService}/support-requests/getRemind`, {data}).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {errorCode: "internal_error"});
                }
                return r.data.data || {};
            });
        },

        async createRemind(data) {
            return axios.post(`${environment.customerSupportService}/support-requests/createRemind`, {data}).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {errorCode: "internal_error"});
                }
                return r.data.data || {};
            });
        },
        async updateRemind(data) {
            return axios.post(`${environment.customerSupportService}/support-requests/updateRemind`, {data}).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {errorCode: "internal_error"});
                }
                return r.data.data || {};
            });
        },

        async deleteRemind(data) {
            return axios.post(`${environment.customerSupportService}/support-requests/deleteRemind`, {data}).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {errorCode: "internal_error"});
                }
                return r.data.data || {};
            });
        },

        emailBroadcast(data) {
            return axios.post(`${environment.customerSupportService}/users-communication/email-broadcast`, {data}).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {errorCode: "internal_error"});
                }

                return r.data.data || {};
            });
        },

    }
})
