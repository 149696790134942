import { defineStore } from 'pinia';
import axios from "axios";
import { environment } from '../environments/environment';
import { useI18n } from 'vue-i18n';

export const useNotificationStore = defineStore('notificationStore', {
    state: () => {
        return {
            notifications: [],
            notificationTypes: null,
            notificationStats: {
                allCount: 0,
                archivedCount: 0,
                starredCount: 0,
                trashedCount: 0,
                unreadCount: 0,
            },
            notificationSettings: [],

            openNotification: {
                openExternalId: null
            }
        }
    },
    getters: {
        getNewMessageCount: (state) => {
            return state.notifications.length > 0 ? state.newNotifications.length : state.notificationStats.unreadCount;
        },
        newNotifications: (state) => {
            return state.notifications.filter((notify) => !notify.isRead);
        },
        allNotifications: (state) => {
            return state.notifications.filter((notify) => !notify.isArchived && !notify.isTrashed);
        },
        archiveNotifications: (state) => {
            return state.notifications.filter((notify) => notify.isArchived);
        },
        trashNotifications: (state) => {
            return state.notifications.filter((notify) => notify.isTrashed);
        },
        starredNotifications: (state) => {
            return state.notifications.filter((notify) => notify.isStarred);
        },
    },
    actions: {
        loadNotificationStats() {
            return axios.post(`${environment.notificationServiceUrl}/notification/getStats`)
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return this.notificationStats = response.data.data || {};
                })
        },
        loadNotifications(filter, params, isSave = true) {
            return axios.post(`${environment.notificationServiceUrl}/notification/search`, {
                data: {
                    filter,
                    params
                }
            })
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    if(isSave) this.notifications = response.data.data.items || [];
                    return response.data.data;
                })
        },

        loadNotificationTypes() {
            if (this.notificationTypes) return Promise.resolve(this.notificationTypes);

            const url = `${environment.notificationServiceUrl}/notification_type/search`;

            return axios.post(url).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                this.notificationTypes = response.data.data || [];
                return this.notificationTypes;
            });
        },

        getNotificationTypeLocal(notificationTypeExternalId) {
            return this.notificationTypes.find((item) => item.notificationTypeExternalId === notificationTypeExternalId);
        },
        
        getNotificationType(notificationTypeExternalId) {
            const type = this.getNotificationTypeLocal(notificationTypeExternalId);
            if (type) return Promise.resolve(type);
            
            return axios.post(`${environment.notificationServiceUrl}/notification_type/getByExternalId`, {
                data: {notificationTypeExternalId}
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data;
            });
        },

        saveNotificationType(data) {
            const url = data.notificationTypeExternalId ?
                `${environment.notificationServiceUrl}/notification_type/update` :
                `${environment.notificationServiceUrl}/notification_type/insert` ;

            return axios.post(url, {
                data
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                const type = this.getNotificationTypeLocal(data.notificationTypeExternalId);
                if(type) Object.assign(type, data);
                return response.data.data;
            });
        },

        updateNotification(query) {
            return axios.post(`${environment.notificationServiceUrl}/notification/update`, {
                data: query
            });
        },
        markAsTrash(id) {
            let item = this.notifications.find((el) => el.notificationExternalId === id);
            item.isTrashed = true;
            item.isRead = true;
            return this.updateNotification(item);
        },
        markAsUnTrash(id) {
            let item = this.notifications.find((el) => el.notificationExternalId === id);
            item.isTrashed = false;
            return this.updateNotification(item);
        },
        markAsStarred(id) {
            let item = this.notifications.find((el) => el.notificationExternalId === id);
            item.isStarred = !item.isStarred;
            return this.updateNotification(item);
        },
        markAsArchive(id) {
            let item = this.notifications.find((el) => el.notificationExternalId === id);
            item.isArchived = true;
            item.isRead = true;
            return this.updateNotification(item);
        },
        markAsUnArchive(id) {
            let item = this.notifications.find((el) => el.notificationExternalId === id);
            item.isArchived = false;
            return this.updateNotification(item);
        },
        setActive(id, property = 'active', isSetRead = false, updateDatabase = true) {
            this.notifications.forEach((el) => {
                
                if (updateDatabase) {
                    if(isSetRead && el.notificationExternalId === id && !el.isRead){
                        el.isRead = true;
                        this.notificationStats.unreadCount--;
                        this.updateNotification(el);
                    }
                }

                el[property] = el.notificationExternalId === id;
            });
        },

        loadNotificationSettings(organizationExternalId) {
            const settings = this.getNotificationSettingsById(organizationExternalId);
            if(settings) return Promise.resolve(settings);
            
            return axios.post(`${environment.notificationServiceUrl}/notification_settings`, {
                    data: {
                        organizationExternalId: organizationExternalId
                    }
                })
                .then((response) => {
                    if (!response.data.success) return Promise.reject(response.data);
                    const data = response.data.data;
                    if(organizationExternalId) {
                        data.organizationExternalId = organizationExternalId;
                        this.notificationSettings.push(data);
                    }
                    return response.data.data;
                })
        },
        updateNotificationSettings(query) {
            return axios.post(`${environment.notificationServiceUrl}/notification_settings/update`, {
                data: query
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                if(query.organizationExternalId) {
                    let org = this.getNotificationSettingsById(query.organizationExternalId);
                    Object.assign(org, query);
                }
            });
        },
        getNotificationSettingsById(organizationExternalId) {
            return this.notificationSettings.find((o) => o.organizationExternalId === organizationExternalId);
        },
    }
})
