<template>
  <div class="base-tabs">
    <div class="base-tabs-header">
      <div
          v-for="(tab, index) in tabItems"
          :key="index"
          class="base-tab"
          :class="[{'base-tab-active': tab.isActive}, tab.props?.tabClass || {}]"
          @click="changeTab(index, tab)"
      >
        <div class="d-flex align-items-center">
          <Icon v-if="tab.icon" class="base-tabs-header-icon" :name="tab.icon"/>
          {{ tab.name }}
        </div>
      </div>
      <slot name="header-adds-content"></slot>
    </div>
    <div class="base-tab-content" :class="contentClass || ''">
      <component ref="activeComponent"
                 v-bind:is="activeTab?.component"
                 v-bind="activeTab?.props"
                 v-bind:organizationExternalId="activeTab?.props?.organizationExternalId"
                 v-on="activeTab?.events || {}"
      />

      <slot name="content-footer"></slot>
    </div>
  </div>
</template>
<script setup>

import {computed, onMounted, onUnmounted, ref, unref, watch} from "vue";
import Icon from "Common/components/svg";
import {onBeforeRouteLeave, useRouter} from "vue-router";

const props = defineProps({
  id: {
    type: String,
    default: 'tab',
  },
  tabs: Array, //{icon, name, component, props}
  contentClass: String,
});

const activeComponent = ref(null);

const router = useRouter();


const emit = defineEmits(["onTabSelected"]);

const activeTabOptions = computed(() => getTabOptionsFromUri(router.currentRoute.value.query.activeTabs));

const tabFromRouteIndex = computed(() => {
  if(!activeTabOptions.value[props.id]) return 0;
  const index = (props.tabs || []).findIndex((e) => getTabKey(e) === activeTabOptions.value[props.id]);
  return index > -1 ? index : 0;
});

const tabItems = computed(() => (props.tabs||[]).map((e, index) => ({
  ...e,
  isActive: tabFromRouteIndex.value === index,
  component: unref(e.component),
  key: getTabKey(e)
})));

const activeTab = computed(() => tabItems.value.find((e) => e.isActive));

let stopWatch;
onBeforeRouteLeave((to, from) => {
  stopWatch && stopWatch();
})

onMounted(() => {
  stopWatch = watch(activeTab, (v2, v1) => {
    if (v2?.key === v1?.key)
      return;
    emit('onTabSelected', v2);
    changeRoute(v2)
  })
});

function changeTab(tabIndex, tab) {
  changeRoute(tab)
}

function changeRoute(tab, clear = false) {
  const cv = router.currentRoute.value;
  const options = getTabOptionsFromUri(cv.query.activeTabs);

  if(clear){
    delete options[props.id];
  }
  else options[props.id] = tab.key;

  const newOptions = encodeURI(JSON.stringify(options));

  router.replace({
    ...cv,
    query: {
      ...cv.query,
      activeTabs: newOptions
    }
  })
}

function getTabKey(tab) {
  return tab.id || tab.name
}

function getTabOptionsFromUri(value) {
  let options = {};
  try{
    options = decodeURI(value || '');
    options = JSON.parse(options);
  }catch (e) {
    options = {};
  }

  return options;
}

defineExpose({activeComponent, setTabById, activeTabOptions})

function setTabById(id) {
  const tab = tabItems.value.find(i => i.key === id);
  tab && changeTab(0, tab);
}
</script>
