export default function (t, data, timeFormat) {
    const settings = {};
    
    const dates = data.map(d => {
        return t('months.'+d.month%100) + '\n' + Math.round(d.month / 100);
    });

    const values = {
        totalCount: [],
        totalConvertedCount: [],
    };

    if(data.length > 0){
        data.forEach((d) => {
            values.totalCount.push({
                value: d.totalCount,
                itemStyle: {}
            })
            values.totalConvertedCount.push({
                value: d.totalConvertedCount,
                itemStyle: {}
            })
        })
    }

    const series = [{
        //name: t('reports.totalRequests'),
        data: values.totalCount,
        type: 'bar',
        barGap: 0.2,
        barWidth: 20,
        stack: 'Total',
        itemStyle:{
            color: '#4E9BF0',
            borderRadius: [100, 100, 0, 0],
        }
    },{
        //name: t('reports.totalRequests'),
        data: values.totalConvertedCount,
        type: 'bar',
        barWidth: 20,
        stack: 'TotalConverted',
        itemStyle:{
            color: '#8BC541',
            borderRadius: [100, 100, 0, 0],
        }
    }];

    let _yAxisMinValue = 3.5; //yaxis shows values with dots if it less than 3.5
    const pixelsPerDot = -0.5; //just coefficient ...
    let maxValue = Math.max(...values.totalCount.map(v => v.value));
    let symbolCount;
    if(maxValue > _yAxisMinValue) symbolCount = Math.round(Math.max(...values.totalCount.map(v => v.value))).toString().length - 1;
    else{
        for(let i = 1; i < 10; i++){
            if(maxValue > _yAxisMinValue / Math.pow(10, i)) {
                symbolCount = 1 + pixelsPerDot + i;
                break;
            }
        }
    }
    const yAxisLabelPadding = -(symbolCount) * 6.7 - 4; // -4 width between numbers and vertical line (yAxis)

    settings.tooltip = {
        trigger: 'item',
        padding: 0,
        borderWidth: 0,
        position: function (point, params, dom, rect, size) {
            return [rect.x - dom.getBoundingClientRect().width/2 + 10, rect.y - dom.getBoundingClientRect().height - 6];
        },
        axisPointer: {
            type: 'shadow'
        },
        formatter
    };
    settings.grid = {
        top: '10',
        left: '0',
        right: '0',
        bottom: '0',
        containLabel: true
    };
    settings.xAxis = {
        type: 'category',
        splitLine: { show: false },
        data: dates,
        axisLine: {
            show: true,
            lineStyle:{
                color: '#A3AED0',    
            }
        },
        axisTick: {
            show: false
        },
        axisLabel:{
            fontFamily: 'DM Sans',
            fontSize: 10,
            lineHeight: 13,
            overflow: 'truncate',
            color: '#A3AED0',
            ellipsis: '...'
        }
    };
    settings.yAxis = {
        type: 'value',
        axisLine: {
            show: true,
            lineStyle: {
                color: '#A3AED0',
            }
        },
        splitLine: {
            lineStyle: {
                color: '#A3AED0',
                opacity: 0.2
            }
        },
        axisLabel: {
            formatter: function (value, index) {
                return value;
            },
            align: 'left',
            padding: yAxisLabelPadding,
        }
    };
    settings.series = series;

    return settings;
}

function formatter(params) {
    return `
    <div class="tooltiptext">
        <div class="dot" style="background-color: ${params.color}"></div>
        <div>${params.data.value}</div>
    </div>
    <style>
        .dot{
            min-width: 8px;
            min-height: 8px;
            border-radius: 50%;
            margin-right: 6px;
        }
        .tooltiptext {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 8px;  
        
          font-family: 'DM Sans'; 
          font-style: normal; 
          font-weight: 500; 
          font-size: 12px; 
          line-height: 16px;
          position: relative;
         
          min-width: 40px;
          background-color: #F6F6F8;
          color: #45557D;
          text-align: center;
          border-radius: 6px;
          z-index: 1;
        }
        
        .tooltiptext::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #F6F6F8 transparent transparent transparent;
        }
      
    </style>
    `;
}