<template>
    <div class="w-100">
        <div class="form-container">
            
            <BaseInputString
                :name="t('common.accountEmail')"
                :is-required="true"
                v-model="formData.paypalAccountId"
                :error="errors.paypalAccountId"
                class="w-100 mb-3"/>
            
            <div class="w-100 mb-3">
                <div class="link-btn" @click="onPaymentPolicyPage">
                    {{ t('affiliate.registerDetails.readPaymentPolicy') }}
                </div>
            </div>
        </div>
        <div class="d-flex mt-3 justify-content-end">
            <BaseButton @click="save" :loading="loadingSave">
                {{ t('common.update') }}
            </BaseButton>
        </div>
    </div>
</template>
<script setup>

import {ref, onMounted, shallowRef, onBeforeMount, onBeforeUnmount, watch, computed} from "vue";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useUserStore} from "Common/stores/userStore";
import BasePreloader from "Common/components/controls/BasePreloader";
import BaseButton from "Common/components/controls/BaseButton";
import Icon from "Common/components/svg";
import BaseInputString from "Common/components/controls/BaseInputString";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";
import {createFormErrors} from "Common/utility/formErrors";
import {validateEmail} from "Common/utility/validationUtility";
import {useBadgeNotify} from "Common/stores/badgeNotify";

const {t, te, locale} = useI18n({useScope: 'global'});
const userStore = useUserStore();
const organizationStore = useOrganizationStore();
const badgeNotify = useBadgeNotify();
const loadingSave = ref(false);
const route = useRoute();

const formData = ref({
    paypalAccountId: organizationStore.organization.paypalAccountId ?? "",
});

const {errors, showHiddenErrors, removeErrors} = createFormErrors(t, te, [formData], () => {
    const newErrors = {};

    if (formData.value.paypalAccountId &&
        !validateEmail(formData.value.paypalAccountId)) newErrors.paypalAccountId = 'invalid_value';

    return newErrors;
}, {requiredFields: ['paypalAccountId']});

function save(){
    if (showHiddenErrors()) {
        return;
    }
    removeErrors();
    
    loadingSave.value = true;
    
    const organization = {
        paypalAccountId: formData.value.paypalAccountId,
    }
    
    organizationStore.updateOrganization(organization).then(() => {
        organizationStore.organization.paypalAccountId = formData.value.paypalAccountId;
        badgeNotify.show({
            text: t('user_profile.update_success'),
            success: true
        });
    }).catch(err => {
        const invalidParams = err?.check?.invalidParams || [];
        const [invalidField] = invalidParams.map(i => i.field).filter(Boolean);

        let text = t('organization_profile.update_error');

        text = invalidField ? t(`organization_profile.update_error_invalid_${invalidField}`) : text;

        badgeNotify.show({
            text,
            error: true
        });

    }).finally(() => {
        loadingSave.value = false;
    });
}

function onPaymentPolicyPage() {
    router.push({name: ROUTES.AffiliatePaymentPolicy});
}

</script>

<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';
.form-container{
    display: flex;
    flex-direction: column;
    max-width: 500px;
}
</style>
