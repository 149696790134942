const lazyLoading = (func) => {
    return () => func().catch((err) => {
        if (/Loading (CSS\s)?chunk .+ failed/.test(err)) {
            window.location.reload()
        }
        return err;
    })
}

export { lazyLoading }
