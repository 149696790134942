<template>
    <modal :visible="affiliationStore.showCreateCouponModal" :close="onCloseModal" className="create-coupon-modal">
        <div class="header">
            <div class="title">
                {{ t("affiliate.createAffiliateLinkCode") }}
            </div>
            <div class="modal-close" @click="onCloseModal">
                <IconClose/>
            </div>
        </div>
        <div class="body">
            
            <BaseInputString
                :name="t('common.couponName')"
                class="w-100 mb-3"
                v-model="formData.name"
                :is-required="true"
                :error="errors.name"
            />
            <div class="field-label">
                {{t('common.validThrough')}} <span class="input-required">*</span>
            </div>
            <div class="d-flex align-items-center gap-3">
                <BaseInputString
                    class="input-number"
                    v-model="formData.expNumber"
                    :is-required="true"
                    :error="errors.expNumber"
                    type="number"
                />
                <BaseDropdown
                    class="w-100"
                    v-model="formData.expType"
                    :error="errors.expType"
                    :options="expTypeOptions"
                    :canClear="false"
                />
                <div class="d-flex">
                    <span class="input-required">*&nbsp;</span>
                    <div class="up-to">
                        {{t('common.upTo6Months')}}
                    </div>
                </div>
            </div>
            <div v-if="errors.expirationDate" class="base-input-error">
                <Icon name="error"/>
                <div v-html="errors.expirationDate"></div>
            </div>
            <div class="mb-3" />
            <div class="text-label">
                {{t('common.currentPromotion')}}
            </div>
            <div class="mb-4">
              {{couponTypeInfo.promotionText}}
            </div>
            <BaseButton class="w-100" @click="onSave" :disabled="resultData.code" :loading="loadingSave">{{ t("common.create") }}</BaseButton>
        </div>
        <div class="footer flex-column justify-content-center">
            <BaseInputString
                :name="t('affiliate.affiliateLink')"
                disabled
                class="w-100 mb-3"
                :model-value="resultData.code ? affiliationStore.getCouponLink(resultData.code) : ''"
                :customIcons="[{
                            component: 'Icon',
                            props: {
                              name: 'copy',
                              class: 'cursor-pointer',
                              onClick: () => { copyToClipboard(affiliationStore.getCouponLink(resultData.code)) }
                            }
                          }]"
            />
            <BaseInputString
                :name="t('common.couponCode')"
                disabled
                class="w-100"
                v-model="resultData.code"
                :customIcons="[{
                            component: 'Icon',
                            props: {
                              name: 'copy',
                              class: 'cursor-pointer',
                              onClick: () => { copyToClipboard(resultData.code) }
                            }
                          }]"
            />
        </div>
    </modal>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import IconClose from "Common/assets/icons/close.svg?inline";
import Icon from "Common/components/svg";
import BaseInputString from "Common/components/controls/BaseInputString";
import BaseDropdown from "Common/components/controls/BaseDropdown";
import BaseButton from "Common/components/controls/BaseButton";
import {watch, computed, onMounted, ref, shallowRef} from "vue";
import {createFormErrors} from 'Common/utility/formErrors';
import {useAffiliationStore} from "Common/stores/affiliationStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import {useSystemStore} from "Common/stores/systemStore";
import dayjs from "dayjs";

const {t, te, locale} = useI18n({useScope: 'global'});
const affiliationStore = useAffiliationStore();
const badgeNotify = useBadgeNotify();
const systemStore = useSystemStore();

const translationOrder = ['affiliate.errorList', 'common.errorList'];

const formData = ref({});
const resultData = ref({});
const couponTypeInfo = ref({});

const expTypeOptions = [
    {value: 'months', label: t('common.months')},
    {value: 'weeks', label: t('common.weeks')},
    {value: 'days', label: t('common.days')},
    {value: 'hours', label: t('common.hours')},
]

onMounted(() => {
    affiliationStore.loadCouponTypeInfo(null).then((data) => {
        couponTypeInfo.value = data;
    }).catch((err) => {
        console.log(err);
    });
})

watch(() => affiliationStore.showCreateCouponModal, () => {
  if(affiliationStore.showCreateCouponModal){
      formData.value = {
          name: '',
          expNumber: 6,
          expType: 'months',
      };
      resultData.value = {};
  }
}, {immediate: true})

const {errors, showHiddenErrors, addCatchErrors} = createFormErrors(t, te, formData, () => {
    const newErrors = {};
    if(!formData.value.expNumber) newErrors.expirationDate = 'required';
    if(formData.value.expNumber && formData.value.expType){
        if (dayjs().add(formData.value.expNumber, formData.value.expType) > dayjs().add(6, 'month')) newErrors.expirationDate = 'invalid_value';
        if (dayjs().add(formData.value.expNumber, formData.value.expType) < dayjs()) newErrors.expirationDate = 'invalid_value';
    }
    return newErrors;
}, {
    translationOrder,
    hideErrorAlias: [
        {whenFieldName: 'expNumber', hideFieldName: 'expirationDate'},
        {whenFieldName: 'expType', hideFieldName: 'expirationDate'}
    ],
    requiredFields: ['name']
});

const loadingSave = shallowRef(false);

function onCloseModal() {
    if (loadingSave.value) return;

    affiliationStore.showCreateCouponModal = false;
}

function onSave() {
    if (showHiddenErrors())
        return;

    loadingSave.value = true;

    affiliationStore.createAffiliateCoupon({
        name: formData.value.name,
        expNumber: formData.value.expNumber,
        expType: formData.value.expType,
    }).then((data) => {
        resultData.value = data;
    }).catch(err => {
        const mainError = addCatchErrors(err);
        if(mainError){
            badgeNotify.show({
                text: mainError,
                error: true
            });    
        }
    }).finally(() => {
        loadingSave.value = false;
    });
}

function copyToClipboard(text) {
    if(text) navigator.clipboard.writeText(text);
}


</script>
<style lang="scss">

@import "~Common/assets/styles/themes/light/light-theme-colors.scss";

@mixin font($size, $weight, $line: 100%, $letter:-0.02em) {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: $weight;
    font-size: $size;
    line-height: $line;
    letter-spacing: $letter;
}

.create-coupon-modal {
    max-width: 480px;

    .field-label {
        @include font(14px, 500);
        margin-bottom: 12px;
    }
    .text-label {
        @include font(16px, 500);
        margin-bottom: 12px;
    }
    .input-required{
        color: $c-red-accent;
    }
    .input-number{
        width: 120px;
    }
    .up-to{
        font-size: 12px;
        line-height: 16px;
    }

    .base-input-error{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 2px;
      color: $c-red-accent;

      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      svg{
        fill: $c-red-accent;
        min-width: 16px;
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
}

body.rtl {
    .create-coupon-modal {
        .base-input-error{
            svg{
                margin-left: 5px;
                margin-right: 0;
            }
        }
    }
}
</style>