<template>
  <div class="base-input" :class="this.class">
    <label class="base-input-container">
      <input
          v-model="value"
          class="base-input-control"
          @blur="onBlur"
          type="number">
    </label>
  </div>
</template>

<script>

import Icon from 'Common/components/svg';

export default {
  components: {Icon},
  name: 'PaginationInput',
  props: {
    modelValue: Number,
    class: String,
  },
  emits: ['update:modelValue', 'blur'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods:{
    onBlur(){
      this.$emit('blur', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-input{
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 32px;
    .base-input-container{
      padding: 0 12px;
      border: 1px solid white;
      border-radius: 6px;
    }
  }
</style>
