<template>
    <div :class="$style.root">
        <div :class="[$style.title, $style.titleSpace]">{{t('common.socialMediaHandles')}}</div>
        <div :class="[$style.text, $style.textSpace]">{{t('affiliate.registerDetails.step3Text')}}</div>
        <BaseInputString v-model="formData.linkLinkedIn"
                         :error="errors.linkLinkedIn"
                         :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'linkedin'
                           }
                         }]"
                         :placeholder="t('affiliate.registerDetails.enterUrl')"
                         class="w-100 mb-3"/>
        <BaseInputString v-model="formData.linkTwitter"
                         :error="errors.linkTwitter"
                         :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'twitter'
                           }
                         }]"
                         :placeholder="t('affiliate.registerDetails.enterUrl')"
                         class="w-100 mb-3"/>
        <BaseInputString v-model="formData.linkFacebook"
                         :error="errors.linkFacebook"
                         :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'facebook'
                           }
                         }]"
                         :placeholder="t('affiliate.registerDetails.enterUrl')"
                         class="w-100 mb-3"/>
        <BaseInputString v-model="formData.linkInstagram"
                         :error="errors.linkInstagram"
                         :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'instagram'
                           }
                         }]"
                         :placeholder="t('affiliate.registerDetails.enterUrl')"
                         class="w-100 mb-3"/>
        <BaseInputString v-model="formData.linkOther"
                         :error="errors.linkOther"
                         :custom-icons-pre="[{
                           component: Icon,
                           props: {
                             name: 'dots-filled'
                           }
                         }]"
                         :placeholder="t('affiliate.registerDetails.other')"
                         class="w-100 mb-3"/>
        
        <div class="d-flex flex-row gap-2 mb-3 w-100">
            <div class="w-100">
                <BaseButton class="w-100" outline @click="onBack">
                    {{t('common.back')}}
                </BaseButton>
            </div>
            <div class="w-100">
                <BaseButton class="w-100" @click="onSave">
                    {{t('common.next')}}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

import BaseInputString from "Common/components/controls/BaseInputString";
import BaseButton from "Common/components/controls/BaseButton";
import Icon from "Common/components/svg";
import {createFormErrors} from "Common/utility/formErrors";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";

const {t, te} = useI18n({useScope: 'global'});
const affiliationStore = useAffiliationStore();
const badgeNotify = useBadgeNotify();
const emit = defineEmits(['next', 'back']);

const loading = ref(true);
const loadingSave = ref(false);

const formData = ref({});

const {errors, showHiddenErrors, addErrors} = createFormErrors(t, te, formData, () => {
    const newErrors = {};
    //if(formData.value.reason.length > 500) newErrors.reason = 'invalid_length';
    return newErrors;
}, {});

onMounted(() => {
    if(!affiliationStore.affiliateUserProfile){
        affiliationStore.loadAffiliateUserProfile().then((data) => {
            formData.value = data;
        }).catch((err) => {
            console.log(err);
            badgeNotify.show({
                test: t("common.errorList.sorrySomethingWrong"),
                error: true
            });
        }).finally(() => {
            loading.value = false;
        });
    }
    else{
        formData.value = affiliationStore.affiliateUserProfile;
        loading.value = false;
    }
})

function onBack() {

    emit('back')
}

function onSave() {
    if (showHiddenErrors()) return;
    loadingSave.value = true;

    affiliationStore.updateAffiliateUserProfile(formData.value).then(() => {
        affiliationStore.affiliateUserProfile = {...formData.value} ?? {};
        emit('next')
    }).catch((err) => {
        console.log(err);
    }).finally(() => {
        loadingSave.value = false;
    })
}

function onPaymentPolicyPage() {
    router.push({name: ROUTES.AffiliatePaymentPolicy});
}

</script>

<style lang="scss" module>
@import "./steps-style.scss";

.root{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    width: 404px;
}

</style>
