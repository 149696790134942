import { defineStore } from 'pinia';
import axios from "axios";
import { environment } from 'Common/environments/environment';
import {useI18n} from "vue-i18n";

let t;

export const useAffiliationStore = defineStore('affiliationStore', {
    state: () => {
        if(!t) t = useI18n({ useScope: 'global' }).t;
        return {
            affiliateCouponTypeStatuses: [
                {label: t('common.active'), value: 'active'},
                {label: t('common.blockNew'), value: 'block_new'},
                {label: t('common.inactive'), value: 'inactive'},
            ],
            affiliateCouponTypeRuleTypes: [
                {label: t('affiliateCouponTypeRuleTypes.minCustomerPurchase'), value: 'min_customer_purchase'},
                {label: t('affiliateCouponTypeRuleTypes.percentageCustomerPurchase'), value: 'percentage_customer_purchase'},
            ],
            affiliateUserProfile: null,
            stats: null,
            showCreateCouponModal: false,
            coupons: [],
            couponsTotal: 0,
        }
    },
    getters: {

    },
    actions: {
        getCouponLink(code){
            return `https://${environment.customerDomain}/register?coupon=${code}`;
        },
        insertCouponType(query) {
            return axios.post(environment.affiliationServiceUrl + '/couponType/insert', {
                data: query
            }).then((response) => {
                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }
                return response.data.data || {};
            });
        },
        updateCouponType(query) {
            return axios.post(environment.affiliationServiceUrl + '/couponType/update', {
                data: query
            }).then((response) => {
                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }
                return response.data.data || {};
            });
        },
        searchCouponType(filter, params) {
            return axios.post(environment.affiliationServiceUrl + '/couponType/search', {
                data: {
                    filter,
                    params
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {items: [], total: 0};
            });
        },
        
        loadAffiliateUserProfile() {
            return axios.post(environment.affiliationServiceUrl + '/profile/getProfile').then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.affiliateUserProfile = response.data.data || {};
            });
        },

        loadCouponTypeInfo(couponTypeExternalId) {
            return axios.post(environment.affiliationServiceUrl + '/couponType/info', {
                data: {
                    couponTypeExternalId
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },
        
        updateAffiliateUserProfile(data) {
            return axios.post(environment.affiliationServiceUrl + '/profile/update', {
                data
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },

        getStats() {
            return axios.post(environment.affiliationServiceUrl + '/statistic/stats').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.stats = (response.data.data || {});
            });
        },

        createAffiliateCoupon(data) {
            return axios.post(environment.affiliationServiceUrl + '/coupon/insert', {
                data
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                this.coupons.unshift(response.data.data);
                this.couponsTotal++;
                return response.data.data || {};
            });
        },
        updateAffiliateCoupon(data) {
            return axios.post(environment.affiliationServiceUrl + '/coupon/update', {
                data
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },
        searchAffiliateCoupon(filter, params) {
            return axios.post(environment.affiliationServiceUrl + '/coupon/search', {
                data: {
                    filter,
                    params
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                const data = response.data.data || {items: [], total: 0};
                this.couponsTotal = data.total;
                this.coupons = data.items;
            });
        },
        loadCouponCodeInfo(couponCode) {
            return axios.post(environment.affiliationServiceUrl + '/coupon/info', {
                data: {
                    couponCode
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },
        loadMyCouponInfo() {
            return axios.post(environment.affiliationServiceUrl + '/coupon/myCouponInfo').then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },
        getMonthConversion() {
            return axios.post(environment.affiliationServiceUrl + '/statistic/monthConversion').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },
        getChannelConversion() {
            return axios.post(environment.affiliationServiceUrl + '/statistic/channelConversion').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },
        getUsecaseConversion() {
            return axios.post(environment.affiliationServiceUrl + '/statistic/usecaseConversion').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },
        searchAffiliatePayout(filter, params) {
            return axios.post(environment.affiliationServiceUrl + '/payout/search', {
                data: {
                    filter,
                    params
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {items: [], total: 0};
            });
        },
        getPayPalPayoutInvoiceUrl(activity_id){
            return `${environment.paypalDomain}/myaccount/activities/details/${activity_id}`;
        }
    }
})
