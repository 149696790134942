<template>
  <div class="root" ref="rootRef" :class="{'is-audio': type === 'audio', [dir]: true}">
    <div class="main-content" :class="{'full-size-video': isFullScreen}">
      <img class="nms-logo"  src="@/assets/images/logo.png"/>
      <video ref="videoRef" class="video" @canplay="onCanPlay" @play="onPlay" @timeupdate="timeUpdateHandler" @ended="onEnded" v-show="type=== 'video'">
        <source v-if="pProps.videoUrl" :src="pProps.videoUrl"/>
      </video>
      <img v-if="type === 'audio'" class="audio-bg" src="@/assets/images/audiobg.jpg" />
      <div class="elements">
        <div class="elements-top">
          <div class="player-indications">
            <LVAOnline v-if="type === 'audio'" :type="type" :lvaStatus="currentData.lvaStatus"/>
            <Stt v-if="currentData.speechToText && !showAnalysisSummaryReport" :type="type" :text="currentData.speechToText"/>
          </div>
          <AnalysisSummaryReport
            v-if="showAnalysisSummaryReport"
            :report="reportData"
            :language="language"
            :dir="dir"
          ></AnalysisSummaryReport>
          <div v-else class="player-sidebar">
            <Sidebar
                :type="type"
                :currentEmotionalStyle="currentData.currentEmotionalStyle"
                :currentEmotion="currentData.currentEmotion"
                :diamondValues="currentData.diamond"
                :timeCode="currentData.timeCode"
                :moods="currentData.moods"
                :lvaStatus="currentData.lvaStatus"
                :overall-cognitive-activity="currentData.overallCognitiveActivity"
            ></Sidebar>
          </div>
        </div>
        <div class="elements-bottom">
          <div class="player">
            <div class="controls-wrapper">
              <div class="controls">
                <div class="skip-backward" @click="skipBackwardHandler">
                  <PrevIcon/>
                  <span>10</span>
                </div>
                <div class="play-pause-btn" @click="toggleVideoStatus">
                  <PlayIcon v-if="!isPaused"/>
                  <PauseIcon v-else/>
                </div>
                <div class="skip-forward" @click="skipForwardHandler">
                  <span>10</span>
                  <NextIcon/>
                </div>
              </div>
              <div class="time-block">
                {{ currentVideoTime }}
              </div>
            </div>
            <div
                ref="videoTimeLine"
                class="video-time-line"
                @click="videoTimeLineClickHandler"
                @mousemove="videoTimeLineMouseMoveHandler"
                @mousedown="isMouseMove = true"
                @mouseup="isMouseMove = false"
            >
              <div
                  v-for="(timeCode, key) in timecodes"
                  :key="key"
                  :style="{left: circlePosition(timeCode.time)}"
                  class="time-code-circle"
                  :class="'time-code-circle-'+timeCode.type"
              />
              <div
                  ref="videoTimeLineActive"
                  class="video-time-line-active"
                  :style="{ width: videoTimeLineWidth }"
              />
            </div>
            <div class="player-fullscreen-container" v-if="type === 'video'">
              <ToFull v-if="!isFullScreen" @click="isFullScreen = true" />
              <ToSmall v-else @click="isFullScreen = false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, defineEmits, ref, watch, shallowRef, onMounted, unref} from 'vue'
import {formatTime} from '@/utils/format-time.js'
import ToFull from '@/assets/icons/player/to_full.svg'
import ToSmall from '@/assets/icons/player/to_small.svg'
import PrevIcon from '@/assets/icons/player/prev.svg'
import NextIcon from '@/assets/icons/player/next.svg'
import PlayIcon from '@/assets/icons/player/play.svg'
import PauseIcon from '@/assets/icons/player/pause.svg'
import {parseProps} from "@/utils/props.js";
import LVAOnline from "../EmotionDiamondLibrary/blocks/LVAOnline.ce.vue";
import Stt from "../EmotionDiamondLibrary/blocks/Stt.ce.vue";
import Sidebar from "../EmotionDiamondLibrary/blocks/Sidebar.ce.vue";
import AnalysisSummaryReport from "@/web-components/reports/AnalysisReport.ce.vue";
import {emloConverter} from "@/utils/emlo.js";

const props = defineProps([
  'videoUrl',
  'data',
  'language',
  'dir'
])

const pProps = computed(() => parseProps(props))
const video = ref('')

const emit = defineEmits(['onCurrentSecond'])

const rootRef = ref(null)
const videoRef = ref(0)
const isPaused = ref(false)
const videoTimeLine = ref(null)
const videoTimeLineWidth = ref(0)
const currentSecond = ref(0)
const currentVideoTime = ref('00:00')
const isMouseMove = ref(false)
const isEndOfFile = ref(false)

const isFullScreen = ref(false)

const type = ref('video')
const parsedData = shallowRef(null);

const getParent = () => {
  return rootRef.value?.parentElement || rootRef.value?.getRootNode().host
}

const showAnalysisSummaryReport = computed(() => {
  return isEndOfFile.value;
})

onMounted(() => {
  const parent = getParent()
  if(!parent) return

  parent.play = () => toggleVideoStatus()
  parent.setData = (data) => {
    parsedData.value = data
  }
})


const onCanPlay = () => {
  if(videoRef.value) {
    type.value = videoRef.value.videoWidth ? 'video' : 'audio'
  }
}

const getZoom = () => {
  let parent = videoTimeLine.value;
  let zoom = 1;

  do {
    zoom = zoom * parseFloat(getComputedStyle(parent).zoom);
    parent = parent.parentElement || parent.getRootNode().host;
  } while (parent)

  return zoom;
}

watch(() => props.data, () => {
  parsedData.value = typeof props.data === 'string' ? JSON.parse(props.data) : props.data
});

const reportData = computed(() => {
  const tData = unref(parsedData)
  if(!tData) return null;

  let data = {};

  if(tData?.segments) {
    data = tData.segments
  } else if(tData?.data?.segments) {
    data = tData?.data
  }
  
  return data;
})

const convertedData = computed(() => {
  if(!reportData.value) return {}
  return emloConverter(reportData.value);
})

const timecodes = computed(() => {
  const {timeCodesOnVideoLine = []} = convertedData.value
  return timeCodesOnVideoLine
})

const currentData = ref({
  diamond: JSON.stringify([]),
  lvaStatus: '',
  overallCognitiveActivity: 0,
  currentEmotionalStyle: '',
  currentEmotion: '',
  speechToText: '',
  timeCode: null,
  moods: [],
})

const setCurrentData = () => {
  const {finallyContent = {}} = convertedData.value

  if (finallyContent[currentSecond.value]) {
    const fc = finallyContent[currentSecond.value]
    currentData.value = {
      diamond: JSON.stringify(fc.diamond),
      lvaStatus: fc.lvaStatus,
      overallCognitiveActivity: fc.overallCognitiveActivity,
      currentEmotionalStyle: fc.currentEmotionalStyle,
      currentEmotion: 'happy',
      speechToText: fc.speechToText,
      timeCode: JSON.stringify(fc.timeCode),
      moods: JSON.stringify(fc.moods),
    }
  }
}

const onPlay = () => {
  const parent = getParent()
  if(parent){
    parent.dispatchEvent(new CustomEvent('play'))
  }
}

const onEnded = () => {
  isEndOfFile.value = true;
  toggleVideoStatus()
  const parent = getParent()
  if(parent){
    parent.dispatchEvent(new CustomEvent('ended'))
  }
}

const timeUpdateHandler = (e) => {
  const {currentTime, duration} = e.target
  videoTimeLineWidth.value = `${(currentTime / duration) * 100}%`
  currentVideoTime.value = formatTime(currentTime)
  currentSecond.value = Math.round(currentTime)
  isEndOfFile.value = false

  setCurrentData()
  //emit('onCurrentSecond', currentTime)
}

const circlePosition = (seconds) => {
  if (videoRef.value) {
    return `${(seconds / videoRef.value.duration) * 100}%`
  }
}

const toggleVideoStatus = () => {
  isPaused.value = !isPaused.value
  isPaused.value ? videoRef.value.play() : videoRef.value.pause()
}

const skipBackwardHandler = () => (videoRef.value.currentTime -= 10)
const skipForwardHandler = () => (videoRef.value.currentTime += 10)

const videoTimeLineClickHandler = (e) => {
  const timelineWidth = videoTimeLine.value.clientWidth
  videoRef.value.currentTime =
      (e.offsetX / getZoom() / timelineWidth) * videoRef.value.duration
}

const videoTimeLineMouseMoveHandler = (e) => {
  if (isMouseMove.value) {
    let offsetX = e.offsetX / getZoom()
    videoTimeLineWidth.value = `${offsetX}px`
  }
}

</script>

<style src="./NMSPlayer.ce.scss" lang="scss"></style>
