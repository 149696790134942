<template>
  <div v-if="!organizationStore.isPhoneVerified && !hide" class="phone-strip">
    {{ t('common.phoneStripText', {amount: '$20'}) }}
    <BaseButton class="text-nowrap w-auto" middle-size @click="onShowPhoneModal">{{t('common.phoneStripButton', {amount: '$20'})}}</BaseButton>
  </div>
</template>

<script setup>

import {useI18n} from "vue-i18n";
import BaseButton from "Common/components/controls/BaseButton";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {computed} from "vue";
import {ROUTES} from "@/utility/routeUtils";
import router from "@/routes";

const {t, locale} = useI18n({useScope: 'global'});

const organizationStore = useOrganizationStore();

const hide = computed(() => {
  return router.currentRoute.value?.matched?.some(i => [
    ROUTES.AdminOrganizationDetails
  ].includes(i.name));
})

function onShowPhoneModal(){
  organizationStore.showPhoneVerificationModal = true;
}
</script>

<style lang="scss" scoped>
.phone-strip{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 6px 24px;
  background-color: #45557D;
  color: white;
  text-align: center;
}

.mobile-device{ //1024 - ...

  @media (max-width: 1023px){ //678

  }

  @media (max-width: 767px){ //375
    .phone-strip{
      flex-direction: column !important;
      gap: 6px;
    }
  }
}

</style>