<template>
    <div class="dashboard">
        <Breadcrumbs :items="breadcrumbs" />

        <div class="d-flex gap-3 mb-4">
            <SingUpConversionsByTime />
            <SingUpConversionsByChannel />
            <ConversionByUsecase />
        </div>
        
        <CouponTable />
    </div>
</template>

<script setup>
import '@vuepic/vue-datepicker/dist/main.css';
import Breadcrumbs from "Common/components/controls/breadcrumbs";
import SingUpConversionsByTime from './components/SingUpConversionsByTime';
import SingUpConversionsByChannel from './components/SingUpConversionsByChannel';
import ConversionByUsecase from './components/ConversionByUsecase';
import CouponTable from './components/CouponTable';

import {useUserStore} from "Common/stores/userStore";
import { useI18n } from 'vue-i18n';

const userStore = useUserStore();

const { t, locale } = useI18n({ useScope: 'global' });

const breadcrumbs = [{
    "name": t('common.dashboard'),
    "link": "#",
    "active": false
}];



</script>

<style lang="scss" scoped>
.dropdown-width{
    width: 170px;
}

@media (min-width: 576px) {
    .col-xl-12 {
        margin-top: 10px;
    }
}
</style>

