<template>
  <div class="sidebar--wrapper" :class="{'is-audio': pProps.type === 'audio'}">
    <div class="sidebar--inner">
      <div class="sidebar">
        <div class="audio-group">
          <CurrentEmotionalStyle class="nms-current-emotional-style" :currentEmotionalStyle="pProps.currentEmotionalStyle"/>
          <Emotions class="nms-emotions" :moods="pProps.moods"/>
          <div v-if="showVideoDistress" class="sidebar--distress">
            DISTRESS
          </div>
          <LVAOnline class="nms-lva-online" v-if="pProps.type === 'video'" :type="pProps.type" :lvaStatus="pProps.lvaStatus"/>
        </div>
        <div class="nms-diamond">
          <Diamond :values="pProps.diamondValues" :overall-cognitive-activity="pProps.overallCognitiveActivity"/>
          <div class="sidebar--distress">
            <span v-if="showAudioDistress"> DISTRESS </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CurrentEmotionalStyle from './CurrentEmotionalStyle.ce.vue'
import Emotions from "./Emotions.ce.vue";
import Diamond from "./Diamond.ce.vue";
import LVAOnline from "./LVAOnline.ce.vue";
import TimeCode from "./TimeCode.ce.vue";
import {computed, watch} from "vue";

const props = defineProps([
    'type',
    'currentEmotionalStyle',
    'moods',
    'diamondValues',
    'timeCode',
    'lvaStatus',
    'overallCognitiveActivity'
])

const pProps = computed(() => {
  return Object.entries(props).reduce((acc, [key, value]) => {
    acc[key] = (typeof value === 'object' || Array.isArray(value)) ? JSON.stringify(value) : value
    return acc
  }, {})
})

const showVideoDistress = computed(() => {
  if (pProps.value.type === 'audio') return false
  return showDistress.value;
})

const showAudioDistress = computed(() => {
  if (pProps.value.type !== 'audio') return false;
  return showDistress.value;
})

const showDistress = computed(() => {
  const ocrLevel = (props.overallCognitiveActivity - 1000) / 10;
  return ocrLevel > 20;
})



</script>

<style src="./Sidebar.ce.scss" lang="scss"/>
