<template>
  <modal :visible="show" :close="onClose" className="form default-home-page-modal" >
    <div>
      <div class="header">
        <div>{{ t('homePageSettings.title') }}</div>
        <div class="modal-close" @click="onClose"><Icon name="close"/></div>
      </div>
      <div class="body attention-modal-text">
        <div class="text-center my-3">
          <Icon width="100px" height="100px" name="home-large" />
        </div>
        <div class="text-center" style="white-space: pre-line;">
          {{ t('homePageSettings.text') }}
        </div>
      </div>
      <div class="footer d-flex justify-content-center">
        <BaseButton class="w-100 base-btn" @click="onSubmit">
          {{t('common.apply')}}
        </BaseButton>
      </div>
    </div>
  </modal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import Icon from "Common/components/svg.vue";
import BaseButton from "Common/components/controls/BaseButton.vue";

export default {
  components: {Icon, BaseButton},
  props: {
    show: Boolean,
  },
  emits: ['close', 'submit'],
  setup(props, {emit}) {
    const { t } = useI18n({ useScope: 'global' });

    function onClose(){
      emit('close');
    }

    function onSubmit(){
      emit('submit');
    }

    return {
      t,
      onClose,
      onSubmit
    }
  }
}
</script>

<style lang="scss">
#modals .default-home-page-modal.modal-content{
  .footer,
  .body{
    margin: 0 auto;
    max-width: 530px;
  }
}
</style>