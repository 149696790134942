<template>
  <div v-if="item">
    <router-link
        :target="item.target || ''"
        :to="!item.route || item.route === '#' ? '#' : {name: item.route, ...(data?.query ? {query: data.query} : {})}"
        :class="{'sidebar_item_active' : item.isSelected }"
        @click="onItemClicked"
    >
      <span class="sidebar_item-icon">
        <Icon class="svg" :name="item.icon" v-if="item.icon"/>
      </span>
      <span class="sidebar_item-text">
        {{item.text}}
      </span>
      <slot name="afterText"></slot>
    </router-link>
  </div>
</template>

<script setup>

import {computed, unref } from "vue";
import Icon from "Common/components/svg";
import {useAppStore} from "Common/stores/appStore";
import router from "@/routes";
import {checkUserPermission, getRoutesByName} from "@/utility/routeUtils";

const props = defineProps({
  data: {type: Object},
  user: {}
});

const appStore = useAppStore();

const currentRouteName = computed(() => {
  const r = unref(router.currentRoute)
  return r && r.name;
});

const routes = computed(() => getRoutesByName(router));

const item = computed(() => getItem(props.user));

function getItem (user) {
  const item = props.data;
  const route = routes.value[item.route];

  if (route && !checkUserPermission(user, route))
    return null;

  return {
    ...item,
    isSelected: currentRouteName.value === item.route
  };
}

function onItemClicked(){ //#search00000001
  appStore.hamburger.sidebar.isOpened = false;
}

</script>
