import { defineStore } from 'pinia';

export const useBadgeNotify = defineStore('badgeNotify', {
    state: () => {
        return {
            notifications: [],
        }
    },
    getters: {
        getDefaultParams(){
            return {
                text: '',
                success: false,
                error: false,
                timeout: 5000,
            }
        }
    },
    actions: {
        show(data) {
            const params = {...this.getDefaultParams};
            Object.assign(params, data);
            params.id = (Math.random()).toString(32).substring(2);
            this.notifications.push(params);
        },
        remove(id){
            this.notifications = this.notifications.filter((e) => e.id !== id);
        }
    }
})
