import * as Components from './_exportComponents.js'
import { emloConverter } from '@/utils/emlo'

export * from './reports/_exportDefinitions.js'

export function registerNMSDiamond(htmlTag = 'nms-diamond') {
    customElements.define(htmlTag, Components.NMSDiamond)
}
export function registerTimeCode(htmlTag = 'nms-lva-timecode') {
    customElements.define(htmlTag, Components.NMSTimeCode)
}
export function registerLVA(htmlTag = 'nms-lva-online') {
    customElements.define(htmlTag, Components.NMSLVAOnline)
}
export function registerEmotionalStyleLVA(htmlTag = 'nms-lva-emotional-style') {
    customElements.define(htmlTag, Components.NMSEmotionalStyle)
}
export function registerEmotions(htmlTag = 'nms-lva-emotions') {
    customElements.define(htmlTag, Components.NMSEmotions)
}
export function registerPlayer(htmlTag = 'nms-player') {
    customElements.define(htmlTag, Components.NMSPlayer)
}
export function nmsDataConverter(data) {
    return emloConverter(data)
}