<template>
    <div class="w-100">
        <div class="d-flex gap-4">
            <BaseInputString
                :name="t('common.firstName')"
                v-model="user_data.firstName"
                :is-required="true"
                class="w-100"
            />
            <BaseInputString
                :name="t('common.lastName')"
                v-model="user_data.lastName"
                :is-required="true"
                class="w-100"
            />
            <BaseInputString
                :name="t('common.email')"
                v-model="user_data.email"
                :is-required="true"
                class="w-100"
                disabled
                readonly
            >
                <template #subNameContent>
                    <div class="d-flex flex-row align-items-center">
                        <Icon name="check-success"/>
                    </div>
                </template>
            </BaseInputString>
        </div>
        <div class="d-flex gap-4 mt-3">
            <BaseInputPhone
                :name="t('common.phoneNumber')"
                v-model="user_data.phoneNumber"
                :default-country="user_data.country"
                :is-required="true"
                :auto-format="false"
                :error="errors.phoneNumber"
                class="w-100"
                @validate="onValidPhoneNumber"
            />
            <BaseDropdown
                :name="t('common.country')"
                v-model="user_data.country"
                :options="countries"
                :error="errors.country"
                :is-required="true"
                searchable
                class="w-100"
            />
            <div class="w-100"></div>
        </div>
        <div class="d-flex gap-4 mt-3">
            <BaseInputString
                :name="t('common.accountName')"
                is-required
                v-model="org_data.name"
                :error="errors.orgName"
                class="w-100"
            />
            <BaseInputString
                :name="t('common.website')"
                v-model="org_data.webPage"
                :error="errors.orgWebPage"
                class="w-100"
            />
            <div class="w-100"></div>
        </div>
        <div class="d-flex mt-3 justify-content-end">
            <BaseButton @click="save" :loading="loadingSave">
                {{ t('common.update') }}
            </BaseButton>
        </div>
    </div>
</template>
<script setup>

import {ref, watch} from "vue";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useUserStore} from "Common/stores/userStore";
import BaseInputString from "Common/components/controls/BaseInputString";
import BaseInputPhone from "Common/components/controls/BaseInputPhone";
import BaseDropdown from "Common/components/controls/BaseDropdown";
import BaseButton from "Common/components/controls/BaseButton";
import Icon from "Common/components/svg";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import {createFormErrors} from "Common/utility/formErrors";
import {getCountryListOptions} from "Common/stores/countries";
import {validateURL} from "Common/utility/validationUtility";
import {useBadgeNotify} from "Common/stores/badgeNotify";

const {t, te, locale} = useI18n({useScope: 'global'});
const countries = getCountryListOptions(t);

const userStore = useUserStore();
const organizationStore = useOrganizationStore();
const badgeNotify = useBadgeNotify();
const loading = ref(false);
const loadingSave = ref(false);
const route = useRoute();

const user_data = ref(userStore.user);
const org_data = ref(organizationStore.organization);

watch(() => organizationStore.organization, () => {
    org_data.value = organizationStore.organization;
})

const phoneNumberData = ref({valid: true});
function onValidPhoneNumber(data) {
    phoneNumberData.value = data;
}

const {errors, showHiddenErrors, removeErrors} = createFormErrors(t, te, [user_data, org_data], () => {
    const newErrors = {};
    
    if (!phoneNumberData.value.valid) newErrors.phoneNumber = '_invalid_phone';

    if (!org_data.value.name || org_data.value.name.length < 3) newErrors.orgName = 'invalid_length';
    if (org_data.value.webPage &&
        !validateURL(org_data.value.webPage)) newErrors.orgWebPage = 'invalid_value';

    return newErrors;
}, {
    requiredFields: ['firstName', 'lastName', 'email', 'phoneNumber', 'country']
});

function save() {
    if (showHiddenErrors()) {
        return;
    }
    removeErrors();

    const data = {
        user: {
            externalId: user_data.value.externalId,
            firstName: user_data.value.firstName,
            lastName: user_data.value.lastName,
            phoneNumber: phoneNumberData.value.number,
            country: user_data.value.country,
        },
        organization: {
            name: org_data.value.name,
            webPage: org_data.value.webPage,
        }
    }

    loadingSave.value = true;

    userStore.updateUserProfile(data.user).then(() => {
        organizationStore.updateOrganization(data.organization).then(() => {
            badgeNotify.show({
                text: t('user_profile.update_success'),
                success: true
            });
        }).catch(err => {
            const invalidParams = err?.check?.invalidParams || [];
            const [invalidField] = invalidParams.map(i => i.field).filter(Boolean);

            let text = t('organization_profile.update_error');

            text = invalidField ? t(`organization_profile.update_error_invalid_${invalidField}`) : text;

            badgeNotify.show({
                text,
                error: true
            });

        }).finally(() => {
            loadingSave.value = false;
        });
        
    }).catch(err => {
        const {validationData} = err || {};
        const {missingParams, invalidParams} = validationData || {};

        const [missingField] = (missingParams || []).map(i => i.field).filter(Boolean);
        const [invalidField] = (invalidParams || []).map(i => i.field).filter(Boolean);

        let text = t('user_profile.update_error');

        if (missingField)
            text = t(`user_profile.update_error_missing_${missingField}`);

        if (invalidField)
            text = t(`user_profile.update_error_invalid_${invalidField}`);

        badgeNotify.show({
            text,
            error: true
        });

        loadingSave.value = false;
    });
}

</script>

<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';

</style>
