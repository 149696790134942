<template>
  <div class="emotions">
    <div v-for="item in items" :key="item.id" :class="['emotions-item', {active: item.isActive}]">
      <component :is="item.icon" class="emotions-item-icon" />
      <div class="emotions-item-title">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script setup>
import AggressiveIcon from '@/assets/icons/emotions/aggressive.svg'
import HesistantIcon from '@/assets/icons/emotions/hesistant.svg'
import MentalEffortIcon from '@/assets/icons/emotions/mental_effort.svg'
import UpsetIcon from '@/assets/icons/emotions/upset.svg'
import {computed} from "vue";
import {parseProps} from "@/utils/props.js";

const props = defineProps(['moods'])
const pProps = computed(() => parseProps(props))

const items = computed(() => {
  const currentEmotions = pProps.value.moods || [];

  const emotions = [
    {
      icon: AggressiveIcon,
      id: 'aggressive',
      title: 'Aggressive',
    },
    {
      icon: HesistantIcon,
      id: 'hesitant',
      title: 'Hesitant',
    },
    {
      icon: UpsetIcon,
      id: 'upset',
      title: 'Distress',
    },
    {
      icon: MentalEffortIcon,
      id: 'mental_effort',
      title: 'Mental Effort',
    },
  ]

  return emotions.map(item => {
    return {
      ...item,
      isActive: currentEmotions.includes(item.id),
    }
  })
})

</script>

<style src="./Emotions.ce.scss" lang="scss"/>
