<template>
  <BadgeNotifyContainer />
  <router-view/>
</template>

<script setup>
import {useUserStore} from "Common/stores/userStore"; //affect some style priority
import {useAppStore} from "Common/stores/appStore";
import {useSystemStore} from "Common/stores/systemStore";
import BadgeNotifyContainer from "Common/components/badge_notifications/BadgeNotifyContainer";
import {onMounted} from "vue";

const userStore = useUserStore();
const appStore = useAppStore();
const systemStore = useSystemStore();

const onClick = (event) => {
  if(["Escape", "Enter"].includes(event.key) || event.shiftKey || event.altKey) {
    return;
  }
  appStore.showAutofillPlaceholder = true;
  document.body.removeEventListener('click', onClick)
  document.body.removeEventListener('keydown', onClick)
}

document.body.addEventListener('click', onClick)
document.body.addEventListener('keydown', onClick)

onMounted(() => {
  userStore.initialSettings();
  systemStore.getTranslationLanguageList();
  // document.body.classList.add("dark-theme");
  document.body.classList.add("light-theme");
})

</script>

<!--<style lang="scss" src="Common/assets/styles/themes/dark/theme.scss"></style>-->
<style lang="scss" src="Common/assets/styles/themes/light/theme.scss"></style>

