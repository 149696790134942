<template>
    <div class="main-bg-container" v-if="!loading">
      <div class="main-container">
        <div class="main-form-container">
          <div class="second-bg-container">
            <div class="second-bg-doc-container"><img src="~Common/assets/emlo-affiliate.png" alt=""/></div>
          </div>
          <div class="main-input-group">
            <div class="main-input-group-title">{{ t('affiliate.register.create_your_account') }}</div>
            <!--        <div class="main-input-group-text">{{ t('register.description') }}</div>-->
            <div class="container-fluid base-cf">
              <div class="row mb-3">
                <div class="col-6">
                  <BaseInputString
                    :name="t('common.firstName')"
                    v-model="registerForm.firstName"
                    :is-required="true"
                    :error="errors.firstName"
                  />
                </div>
                <div class="col-6">
                  <BaseInputString
                    :name="t('common.lastName')"
                    v-model="registerForm.lastName"
                    :is-required="true"
                    :error="errors.lastName"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <BaseInputString
                    :name="t('common.email')"
                    v-model="registerForm.email"
                    :is-required="true"
                    :error="errors.email"
                  />
                </div>
                <div class="col-6">
                  <!--              <BaseInputString
                                    :name="t('common.organizationName')"
                                    v-model="registerForm.organizationName"
                                    :is-required="true"
                                    :error="errors.organizationName"
                                />-->
                  <BaseDropdown
                    :name="t('common.country')"
                    v-model="registerForm.country"
                    :options="countries"
                    searchable
                  />
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center my-2 recccc" v-if="RECAPTCHA_ENABLED">
              <vue-recaptcha :sitekey="RECAPTCHA_SITE_KEY"
                             ref="recaptcha"
                             @verify="onCaptchaVerify"
                             @expired="captchaToken = ''"
              />
            </div>

            <BaseButton
              class="w-100 mb-3"
              @click="register"
              :loading="loadingRegister"
              :disabled="RECAPTCHA_ENABLED && !captchaToken">
              {{ t('common.createYourAccount') }}
            </BaseButton>

            <div class="mb-3" v-html="t('affiliate.register.termsAndConditions.text')"></div>

            <GoogleButton @authenticated="googleAuth" class="mb-4"/>

            <div class="d-flex align-items-center justify-content-center">
              <router-link to="/login">
                <div class="link-btn login-button">{{ t('common.backToLogin') }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
        
    </div>
</template>

<script setup>
import BaseInputString from "Common/components/controls/BaseInputString";
import BaseButton from "Common/components/controls/BaseButton";
import BaseDropdown from "Common/components/controls/BaseDropdown";
import GoogleButton from "Common/components/controls/GoogleButton"
import router from "@/routes";
import {useUserStore} from "Common/stores/userStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import {useI18n} from "vue-i18n";
import {useSystemStore} from "Common/stores/systemStore";
import {onMounted, ref, shallowRef, unref} from "vue";
import {ROUTES} from "@/utility/routeUtils";
import {createFormErrors} from "Common/utility/formErrors";
import {getCountryListOptions} from "Common/stores/countries";
import {RECAPTCHA_SITE_KEY, RECAPTCHA_ENABLED} from "@/utility/captchaUtils";
import { VueRecaptcha  } from 'vue-recaptcha'

const userStore = useUserStore();
const systemStore = useSystemStore();
const badgeNotify = useBadgeNotify();
const {t, te} = useI18n({useScope: 'global'})
const password = ref(null);
const loading = shallowRef(false);
const loadingRegister = shallowRef(false);

const captchaToken = shallowRef('');
const recaptcha = shallowRef(null);

const countries = getCountryListOptions(t);

const registerForm = ref({
    'firstName': '',
    'lastName': '',
    'email': '',
    'country': '',
    'organizationName': '',
    'receiveNewsEmails': true,
});

const {
    errors,
    addErrors,
    showHiddenErrors,
    removeErrors
} = createFormErrors(t, te, [registerForm], null, {
    requiredFields: ["firstName", "lastName", "email"]
});

onMounted(() => {
    userStore.removeUserToken();

    systemStore.getBrowserInfo().then(x => {
        if (x && x.alpha2 && !registerForm.value.country) {
            registerForm.value.country = x.alpha2;
        }
    });

});


function register() {
    registerForm.value.organizationName = `${registerForm.value.email}`;
    
    if (showHiddenErrors()) return;
    removeErrors();

    loadingRegister.value = true;

    const {
        email,
        firstName,
        lastName,
        country,
        organizationName,
        receiveNewsEmails,
    } = unref(registerForm);

    userStore.register({
        ...(RECAPTCHA_ENABLED ? {captchaToken: captchaToken.value} : {}),
        user: {
            email,
            password: "[AUTO_GENERATE]",
            firstName,
            lastName,
            redirectUrl: '',
            organizationName,
            country,
            receiveNewsEmails
        }
    }).then(response => {
        router.push({name: ROUTES.EmailConfirmation, query: {email: response?.email || ""}});
    }).catch(err => {
        console.log(err);

        recaptcha.value?.reset();
        captchaToken.value = '';

        switch (err.errorCode) {
            case 'duplicate_user_identifier':
                err.fieldErrors = {
                    email: {
                        code: 'duplicate_user_identifier',
                        params: {link: router.resolve({name: ROUTES.ForgotPassword, query: {email: email}}).href}
                    }
                }
                break;
            case 'not_verified_user':
                err.fieldErrors = {
                    email: {
                        code: 'not_verified_user',
                        params: {link: router.resolve({name: ROUTES.EmailConfirmation, query: {email: email}}).href}
                    },
                }
                break;
        }

        if (err.fieldErrors) {
            addErrors(err.fieldErrors);
        } else {
            badgeNotify.show({
                text: t(`common.errorList.${err.errorCode ? err.errorCode : 'sorrySomethingWrong'}`),
                error: true,
                timeout: 10000
            })
        }
    }).finally(() => {
        loadingRegister.value = false;
    });
}

function googleAuth() {
    if (!userStore.isNewUser) {
        router.push({name: ROUTES.Dashboard})
    } else {
        router.push({name: ROUTES.RegisterDetails})
    }
}

function onCaptchaVerify(r) {
    captchaToken.value = r;
}

</script>

<style scoped lang="scss" src="Common/assets/styles/themes/light/auth.scss"></style>
<style scoped lang="scss">
.main-input-group {
    width: 640px;
}

.login-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
}

@media (max-width: 991px) {
    .main-form-container {
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        border-radius: 0;
        padding-top: 15px;
    }
    .main-bg-container {
        height: auto;
    }
    .second-bg-container {
        background: none;
    }
    .second-bg-doc-container {
        display: none;
    }
}
</style>
