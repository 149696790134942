import {defineStore} from 'pinia'
import axios from "axios";
import router from "@/routes";
import {environment} from '../environments/environment';
import {useOrganizationStore} from './organizationStore';
import {useBillingStore} from './billingStore';
import {useSiteStore} from "Common/stores/siteStore";
import {useApiKeyStore} from "Common/stores/apiKeyStore";
import {useSystemStore} from "Common/stores/systemStore";
import {ROUTES} from "@/utility/routeUtils";

const defaultUserLogo = require('../assets/default_user_photo.png');


// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore('userStore', {
    state: () => {
        return {
            user: null,
            managementData: null,
            organization: null,
            balance: null,
            userService: null,
            users: null,
            orgRoles: null,
            statuses: null,
            authAppDetails: null,
            token: null,
            redirectDestination: null,
            availableRoles: null,
            isNewUser: false,
            user2FAData: [],
            showPhoneVerificationModal: false,
            verificationPhoneNumber: '',
            isUserMode: !!getAltUserToken(),
            login2FAAttempt: {},
        }
    },
    getters: {
        isUserShouldSeeOnlyApptone: (state) => {
            const roles = state.user?.roles || [];
            if(roles.includes('system_admin')){
                return false;
            }

            return roles.includes('org_admin_apptone') || roles.includes('org_user_apptone');
        },
        isAccountManagementData: (state) => {
            return state.user?.permissions.account_management_data;
        },
        isAdmin: (state) => {
            const roles = state.user?.roles || [];
            return roles.includes("customer_spprt_role") || roles.includes("system_admin");
        },
        isLoaded: (state) => {
            return state.user && state.organization && state.balance !== null
        },
        // type is automatically inferred because we are not using `this`
        fullName: (state) => {
            if (state.user) return state.user.firstName + " " + state.user.lastName;
            else return '';
        },
        // here we need to add the type ourselves (using JSDoc in JS). We can also
        // use this to document the getter
        isPremiumAccount: (state) => {
            return state.user?.permissions.premium_services;
        }
    },
    actions: {
        initialSettings() {
        },

        async setLanguage(language) {
            const url = `${environment.usersManagementServiceUrl}/user/setLanguage`;

            return axios.post(url,{data: {language}}).then(response => {
                if(response.data?.success){
                    this.user = {
                        ...this.user,
                        language
                    }

                    useSystemStore().updateLangDirection();

                    return response.data;
                }

                return response.data;
            });
        },

        verifyAuthApp(code) {
            return axios.post('users/verifyauthapp', {code}).then(x => {
                if (!x.data.success)
                    throw new Error(x.data.message || "Failed to verify code.");
            }).then(() => this.loadAuthAppDetails(true));
        },

        loadAuthAppDetails(force = false) {
            if (!this.authAppDetails || force) {
                return axios.post('users/authappdetails').then(x => {
                    this.authAppDetails = x.data;
                    return x.data;
                });
            }
            return Promise.resolve(this.authAppDetails);
        },

        async loadUser(forceReload = false, afterEmailVerification = false) {
            const that = this;
            if (this.user && !forceReload)
                return Promise.resolve(this.user);

            return axios.get(`${environment.usersManagementServiceUrl}/user/`).then(async response => {
                //if (!response.data.success) return Promise.reject(response.data);
                if (String(response?.response?.status) === "401") {
                    this.logout();
                    return;
                }
                const {user} = response.data || {};

                if (user) {
                    user.profileImageLarge = user.profileImageLarge || defaultUserLogo;

                    await this.getUserAvailableRoles()
                        const os = useOrganizationStore();
                        const bs = useBillingStore();
                        os.setUserOrgExternalId(user.organizationExternalId).then(() => {
                            os.loadOrganizationById(user.organizationExternalId).then((org) => {
                                that.organization = org;

                                if(afterEmailVerification){
                                    os.checkAndShowPhoneVerificationModal();
                                }
                                bs.getBalance(true).then((balance) => {
                                    that.balance = balance
                                })
                            })
                        })

                    that.user = user;
                }
            });
        },
        loadOrganizationOwners(filter, params) {
            return axios.post(`${environment.usersManagementServiceUrl}/userManagement/getOrganizationOwners`, {
                data: {
                    filter,
                    params
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                this.users = response.data.data.items || [];
                return response.data.data;
            });
        },

        loadManagementData() {
            if (this.managementData)
                return Promise.resolve(this.managementData);

            const url = `${environment.usersManagementServiceUrl}/userManagement/management`;

            return axios.post(url).then(x => {
                this.managementData = x.data.data;
                return this.managementData;
            });
        },

        load2FAData() {
            const url = `${environment.usersManagementServiceUrl}/2fa/getList`;

            return axios.post(url).then(x => {
                this.user2FAData = x.data.data;
                return this.user2FAData;
            });
        },

        getUserAvailableRoles() {
            if (this.availableRoles)
                return Promise.resolve(this.availableRoles);

            const url = `${environment.usersManagementServiceUrl}/userManagement/getAvailableRoles`;

            return axios.post(url).then(response => {
                if (!response.data.success) return Promise.reject(response.data);

                this.availableRoles = response.data.data;
                return this.availableRoles;
            });
        },

        loadUsers(organizationExternalId) {
            const usersUrl = `${environment.usersManagementServiceUrl}/userManagement/getOrganizationUsers`;

            return axios.post(usersUrl, {
                data: {
                    filter: {
                        showSuspended: true,
                        organizationExternalId: organizationExternalId
                    }
                }
            }).then(response => {
                //if (!response.data.success) return Promise.reject(response.data);

                this.users = response.data.data;
                return this.users;
            });
        },
        loadUserByExternalId(organizationExternalId, externalId) {
            const usersUrl = `${environment.usersManagementServiceUrl}/userManagement/getOrganizationUsers`;

            return axios.post(usersUrl, {
                data: {
                    filter: {
                        showSuspended: true,
                        organizationExternalId: organizationExternalId,
                        externalId: externalId
                    }
                }
            }).then(response => {
                return response.data.data[0];
            });
        },

        saveDestinationPath() {
            this.redirectDestination = router.currentRoute.value.fullPath;
        },

        tryGoToDestinationPath() {
            if (this.redirectDestination) {
                const route = this.redirectDestination === `/${ROUTES.Dashboard.toLowerCase()}`
                  ? { name: ROUTES.AnalyzeNow }
                  : this.redirectDestination;

                router.push(route);
                this.redirectDestination = null;
                return true;
            }
            return false;
        },

        enterUserMode(data) {
            return axios.post(`${environment.usersManagementServiceUrl}/auth/enterUserMode`, {data}).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {});
                }
                if (!r.data?.user?.token) {
                    return Promise.reject({});
                }

                const token = this.getUserToken();

                this.$reset();
                this.saveUserToken(r.data.user.token, token);

                return this.loadUser(() => {
                    return r;
                }).then((response) => {
                    const os = useOrganizationStore();
                    const sts = useSiteStore();
                    const aps  = useApiKeyStore();

                    sts.getSiteStats().catch((e) => {
                        console.log(e)
                    });


                    aps.getApiKeyStats().catch((e) => {
                        console.log(e)
                    });
                    os.loadOrganizationById(this.user.organizationExternalId).then(() => {
                        os.checkAndShowPhoneVerificationModal();
                    });

                    return {
                        user: r.data.user,
                    };
                });
            });
        },

        quitUserMode() {
            return axios.post(`${environment.usersManagementServiceUrl}/auth/quitUserMode`, {data: {token: getAltUserToken()}}).then(r => {
                if (!r.data?.success) {
                    return Promise.reject(r.data || {});
                }
                if (!r.data?.user?.token) {
                    return Promise.reject({});
                }

                this.$reset();
                this.saveUserToken(r.data.user.token);

                return this.loadUser(() => {
                    return r;
                }).then((response) => {
                    const os = useOrganizationStore();
                    const sts = useSiteStore();
                    const aps  = useApiKeyStore();

                    sts.getSiteStats().catch((e) => {
                        console.log(e)
                    });


                    aps.getApiKeyStats().catch((e) => {
                        console.log(e)
                    });

                    os.loadOrganizationById(this.user.organizationExternalId).then(() => {
                        os.checkAndShowPhoneVerificationModal();
                    });

                    return {
                        user: r.data.user,
                    };
                });
            });
        },

        login(query) {
            return axios.post(`${environment.usersManagementServiceUrl}/auth/login`, {
                data: {
                    query
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                if (response.data && response.data.user.token) {
                    this.saveUserToken(response.data.user.token);
                }
                return this.loadUser(() => {
                    return response;
                }).then((response) => {
                    const os = useOrganizationStore();
                    os.loadOrganizationById(this.user.organizationExternalId).then(() => {
                        os.checkAndShowPhoneVerificationModal();
                    });

                    return response;
                })
            });
        },

        checkAndShowPhoneVerificationModal(){
            this.getUserProfile().then(()=>{
                this.showPhoneVerificationModal = this.user && !this.user?.phoneVerified;
                this.verificationPhoneNumber = this.user?.phoneNumber || '';
            })
        },

        logout(skipRedirect = false) {
            this.$reset();
            this.removeUserToken();
            if (!(skipRedirect || window.noRedirect))
                router.push('/login');
        },
        expired() {
            if (window.noRedirect) {
                return;
            }
            const pn = window.location.pathname;
            const url = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
            if (!pn.startsWith('/login')) {
                this.logout(true);
                setTimeout(() => {
                    router.push(`/login?returnUrl=${url}`)
                }, 100)
            }
        },

        register(data) {
            const url = `${environment.usersManagementServiceUrl}/registration/register`;

            return axios.post(url, {data}).then(response => {

                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }

                if (response.data?.data?.token) {
                    this.saveUserToken(response.data.data.token);
                }

                return response.data.data;
            });
        },

        resendVerificationCode(email) {
            return axios.post(`${environment.usersManagementServiceUrl}/registration/resendEmailCode`, {
                data: {
                    email
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
            });
        },

        resendResetPassword(data) {
            const url = environment.usersManagementServiceUrl + '/auth/resendResetPassword';

            return axios.post(url, {data}).then(response => {
                if (!response.data.success)
                    return Promise.reject(response.data);
            });
        },
        sendResetPassword(data) {
            const url = environment.usersManagementServiceUrl + '/auth/sendResetPassword';

            return axios.post(url, {data}).then(response => {
                if (!response.data.success)
                    return Promise.reject(response.data);
            });
        },
        sendOneTimeLoginLink(data) {
            const url = environment.usersManagementServiceUrl + '/auth/sendOneTimeLoginLink';

            return axios.post(url, {data}).then(response => {
                if (!response.data.success)
                    return Promise.reject(response.data);
            });
        },

        /**
         * @param {Object} data
         * @param {string} data.email
         * @param {string} data.emailVerificationCode
         * @param {string} data.password
         * @returns {Promise<AxiosResponse<any>>}
         */
        verifyEmailCode: function (data) {
            const url = `${environment.usersManagementServiceUrl}/registration/verifyEmailCode`;

            return axios.post(url, {
                data
            }).then((response) => {

                if (!response.data.success) {

                    return Promise.reject(response.data);
                }

                if (response.data && response.data.user.token) {

                    this.saveUserToken(response.data.user.token);

                    return this.loadUser(true, true);
                }

                return response.data;
            });
        },

        createPassword(data) {
            // TODO: fix url
            return axios.post('/Users/create-password', data);
        },

        addUserToOrganization(user) {
            return axios.post(`${environment.usersManagementServiceUrl}/userManagement/addUserToOrganization`, {
                data: user
            }).then((res) => {
                if (res.data.success) {
                    this.users.push(res.data.data);
                    return res.data.data;
                }
                return Promise.reject(res.data);
            });
        },

        updateOrganizationUser(user) {

            const data = {
                externalId: user.externalId,
                firstName: user.firstName,
                lastName: user.lastName,
                country: user.country,
                roles: user.roles,
                password: user.password,
            };

            return axios.post(`${environment.usersManagementServiceUrl}/userManagement/updateOrganizationUser`, {
                data
            }).then(res => {
                if (res.data.success) {
                    this.users = (this.users || []).map(u => u.externalId === user.externalId ? {
                        ...u,
                        ...data
                    } : u);
                    return res.data.data;
                }
                return Promise.reject(res.data);
            })
        },

        changeUserStatus(userExternalId, status) {
            const url = `${environment.usersManagementServiceUrl}/userManagement/updateUserStatus`;
            return axios.post(url, {
                userExternalId,
                status
            }).then((res) => {
                if (res.data.success) {
                    const user = (this.users || []).find(u => u.externalId === userExternalId);
                    if (user) {
                        user.status = status;
                        return user;
                    }

                    return res.data
                }
                return Promise.reject(res.data)
            });
        },

        updateProfileImage(userExternalId, file) {
            const url = `${environment.imagesUrl}/profile/uploadUserProfileImage`;
            const fd = new FormData();
            fd.append('userExternalId', userExternalId)
            fd.append('file', file)
            fd.append('create3Sizes', true)
            return axios.post(url, fd, {headers: { "Content-Type": "multipart/form-data" },}).then((res) => {
                if (!res.data.success) {
                    return Promise.reject(res.data);
                }

                const links = res.data.data.links;
                Object.assign(this.user, {
                    ...this.user,
                    profileImageSmall: links.small,
                    profileImageMedium: links.medium,
                    profileImageLarge: links.large,
                });
            });
        },

        deleteUser(userExternalId) {
            // TODO: fix
            return axios.post(`${environment.usersManagementServiceUrl}/userManagement/deleteUser`, {
                data: {userExternalId}
            }).then(response => {
                if (!response.data.success)
                    return Promise.reject(response.data);
            });
        },

        changePassword(data) {
            return axios.post(`${environment.usersManagementServiceUrl}/user/password`, {
                data: data
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
            });
        },

        resetPassword(data) {
            const url = environment.usersManagementServiceUrl + '/auth/reset-password';

            return axios.post(url, {data}).then(response => {
                if (!response.data.success)
                    return Promise.reject(response.data);
            });
        },

        saveUserToken(token, altToken) {
            this.token = token;
            localStorage.setItem('token', token);
            localStorage.setItem('token-date', Date.now().toString());
            setAltUserToken(altToken);
            this.isUserMode = !!getAltUserToken();
        },

        getUserToken() {
            return localStorage.getItem('token');
        },

        getAltUserToken() {
            return getAltUserToken();
        },

        removeUserToken() {
            this.token = null;
            localStorage.removeItem('token');
            setAltUserToken(null);
            this.isUserMode = false;
            localStorage.removeItem('token-date');
        },

        searchUserSuggestions(query) {
            axios.post(environment.usersManagementServiceUrl + '/searchUser/searchUsersSuggestions', {
                data: {
                    query: query,
                    extraFields: [],
                    extraFilterParams: [
                        {status: 'active'},
                    ],
                    params: {
                        page: 0,
                        limit: 10,
                        fullMatchOnly: true
                    }
                }
            }).then((response) => {
                console.log(response.data)
            })
        },

        getUserProfile() {
            return axios.post(environment.usersManagementServiceUrl + '/profile/getByExternalId', {
                data: {
                    userExternalId: this.user?.externalId
                }
            }).then((response) => {
                if (response.data.success) {
                    Object.assign(this.user, response.data.data.profile)
                    this.user.profileImageLarge = this.user.profileImageLarge || defaultUserLogo;
                    return this.user;
                } else throw response.data;
            });
        },
        updateUserProfile(query) {
            const requestData = {...query, userExternalId: query.externalId};
            delete requestData.profileImageLarge;
            delete requestData.profileImageMedium;
            delete requestData.profileImageSmall;

            return axios.post(environment.usersManagementServiceUrl + '/profile/update', {
                data: requestData
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                Object.assign(this.user, requestData);
            });
        },
        updateUserDefaultUrl(path) {
            return axios.post(environment.usersManagementServiceUrl + '/profile/updateDefaultHomeUrl', {
                data: {
                    path
                }
            })
        },
        googleAuth(data) {
            return axios.post(`${environment.usersManagementServiceUrl}/auth/google-auth`, data).then((response) => {
                if (!response.data?.success && response.data?.errorCode === 'duplicate_user_identifier') {
                    return response.data
                }

                if (response.data?.data && response.data.data.user?.token) {
                    this.saveUserToken(response.data.data.user.token);
                    this.isNewUser = response.data.data.isNewUser
                    return this.loadUser(() => response.data.data.user).then(r => {

                        const os = useOrganizationStore();

                        os.loadOrganizationById(this.user.organizationExternalId).then(() => {
                            os.checkAndShowPhoneVerificationModal();
                        });

                        return r;

                    })
                }

                return response.data;
            }).catch(error => error);
        },

        sendPhoneVerification(phoneNumber) {
            return axios.post(environment.usersManagementServiceUrl + '/user/sendPhoneVerification', {
                data: { phoneNumber }
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        send2FAPhoneVerification(phoneNumber) {
            return axios.post(environment.usersManagementServiceUrl + '/2fa/sendPhoneVerification', {
                data: { phoneNumber }
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        send2FAPhoneDisableVerification(phoneNumber) {
            return axios.post(environment.usersManagementServiceUrl + '/2fa/sendPhoneDisableVerification', {
                data: { phoneNumber }
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        checkPhoneVerification(data) {
            return axios.post(environment.usersManagementServiceUrl + '/user/checkPhoneVerification', {
                data: data
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        check2FAPhoneVerification(data) {
            return axios.post(environment.usersManagementServiceUrl + '/2fa/checkPhoneVerification', {
                data: data
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        check2FAPhoneDisableVerification(data) {
            return axios.post(environment.usersManagementServiceUrl + '/2fa/checkPhoneDisableVerification', {
                data: data
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        update2FASettings(data) {
            return axios.post(environment.usersManagementServiceUrl + '/2fa/update', {
                data: data
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        waitUserLoaded(fn) {
            let stop = false;

            const check = y => {
                if (this.isLoaded || stop) {
                    y(!stop);
                    return;
                }
                setTimeout(() => check(y), 300);
            }

            return new Promise(y => {
                fn && fn(() => stop = true);
                check(y);
            })
        },

        resend2FAPhoneCode(twoFAToken) {
            return axios.post(environment.usersManagementServiceUrl + '/auth/resend2FAPhoneCode',{
                data: {token: twoFAToken}
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        generateOTP() {
            return axios.post(environment.usersManagementServiceUrl + '/2fa/generateOTP',{
                data: {}
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        verifyOTP(secret, code) {
            return axios.post(environment.usersManagementServiceUrl + '/2fa/verifyOTP',{
                data: { secret, code }
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        disableOTP(code) {
            return axios.post(environment.usersManagementServiceUrl + '/2fa/disableOTP',{
                data: { code }
            }).then((response) => {
                if (!response.data.success) {
                    return Promise.reject(response.data);
                }

                return response.data;
            });
        },

        async onLoginSuccess() {
            const organizationStore = useOrganizationStore();

            if (!this.user.emailVerified) {
                await router.push({ name: ROUTES.EmailConfirmation });
                return;
            }

            await Promise.all([
                this.loadUser(),
                organizationStore.loadOrganizationById(this.user.organizationExternalId)
            ]);

            organizationStore.checkDistributorModals = true;

            if (this.tryGoToDestinationPath()) return;
            const returnUrl = router.currentRoute.value?.query?.returnUrl;
            const defaultHomeUrl = this.user?.defaultHomeUrl || this.user?.organization?.defaultHomeUrl;

            return router.push(returnUrl || defaultHomeUrl || { name: ROUTES.AnalyzeNow });
        }
    },
})

function getAltUserToken() {
    return localStorage.getItem('token-alt');
}
function setAltUserToken(v) {
    if (!v) {
        localStorage.removeItem('token-alt');
    } else {
        localStorage.setItem('token-alt', v);
    }
}
