<template>
  <div class="header_sites_badge" 
       :key="key"
       :class="{
         'header_sites_badge_outlined': outlined,
         'header_sites_badge_light': light,
         [this.class]: true
       }"
  >
    <div v-if="icon" class="sites_badge_icon_container"><Icon :name="icon"/></div>
    <div class="d-flex flex-column" :class="icon ? '' : 'ml-2'">
      <div class="sites_badge_name">{{name}}</div>
      <div class="sites_badge_value">
        <div>{{value}}</div>
        <div class="sites_badge_type" v-if="type">{{type}}</div>
      </div>
    </div>
  </div>
</template>

<script>

import Icon from "Common/components/svg";

export default {
  components: {
    Icon,
  },
  props: {
    icon: String,
    name: String,
    value: [Number,String],
    type: String,
    class: String,
    outlined: Boolean,
    light: Boolean,
  },
  data() {
    return {
      key: 0
    }
  },
  watch:{
    icon: function (){
      this.key++;
    }
  }
}

</script>

<style scoped lang="scss">
  .header_sites_badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 24px 13px 13px;
    border-radius: 50px;
    & + .header_sites_badge{
      margin-left: 12px;
    }
    &.onboarding-selected{
      box-shadow: rgba(white, 0.56) 0px 0px 15px 0px;
      border: 2px solid white;
    }
  }
  .header_sites_badge_outlined{
    background: inherit;
    border: 1px solid white;
  }
  
  .sites_badge_icon_container {
    padding: 8px;
    border-radius: 50px;
    margin-right: 10px;
    svg{
      width: 24px;
      height: 24px;
    }
  }
  .sites_badge_name{
    font-size: 12px;
    line-height: 20px;
  }
  .sites_badge_value{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
  }
  .sites_badge_type{
    margin-left: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

body.rtl {
  .header_sites_badge {
    padding: 13px 13px 13px 24px;
    & + .header_sites_badge{
      margin-right: 12px;
    }
  }
  .sites_badge_icon_container {
    margin-left: 10px;
    margin-right: 0;
  }
  .sites_badge_type{
    margin-right: 6px;
    margin-left: 0;
  }
}
</style>