<template>
    <div class="main-bg-container wrap-768">
      <div class="main-container">
        <div class="main-form-container">
          <div class="second-bg-container">
            <div class="second-bg-doc-container"><img src="~Common/assets/emlo-affiliate.png" alt=""/></div>
          </div>
          <div v-if="sentSuccessfully" class="main-input-group d-flex">
            <div class="my-auto">
              <div class="main-input-group-title">{{ t('common.isForgotPassword') }}</div>
              <div class="main-input-group-text">{{ t('forgotPassword.success_text', {email: email}) }}</div>
              <BaseButton outline class="w-100" @click="send" :loading="loadingSending" :disabled="!email">
                {{ t('common.resend') }}
              </BaseButton>

              <div class="d-flex align-items-center justify-content-center mt-3">
                <router-link to="/login">
                  <div class="link-btn register-button">{{ t('common.backToLogin') }}</div>
                </router-link>
              </div>
            </div>
          </div>
          <div v-else class="main-input-group d-flex">
            <div class="my-auto">
              <div class="main-input-group-title">{{ t('common.isForgotPassword') }}</div>
              <div class="main-input-group-text">{{ t('forgotPassword.text') }}</div>

              <BaseInputString
                :name="t('common.email')"
                v-model="email"
                :is-required="true"
                :error="errorCode ? t(`common.errorList.${errorCode}`) : ''"
              />

              <BaseButton class="my-3 w-100" @click="send" :loading="loadingSending" :disabled="!email">
                {{ t('common.send') }}
              </BaseButton>

              <div class="d-flex align-items-center justify-content-center">
                <router-link to="/login">
                  <div class="link-btn register-button">{{ t('common.backToLogin') }}</div>
                </router-link>
              </div>
            </div>

          </div>
        </div>
      </div>
        
    </div>
</template>

<script>

import {useUserStore} from 'Common/stores/userStore';
import Icon from "Common/components/svg";
import BaseInputString from "Common/components/controls/BaseInputString";
import BaseButton from "Common/components/controls/BaseButton";
import {useI18n} from "vue-i18n";
import {onMounted, ref} from "vue";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import {useRouter} from "vue-router";

export default {
    components: {Icon, BaseInputString, BaseButton},
    name: 'ResetPassword',
    setup() {
        const userStore = useUserStore();
        const badgeNotify = useBadgeNotify();
        const {t, locale} = useI18n({useScope: 'global'});
        const loadingSending = ref(false);
        const sentSuccessfully = ref(false);
        const email = ref('');
        const errorCode = ref('');
        const router = useRouter();

        onMounted(() => {
            if (router.currentRoute.value.query.email) {
                email.value = router.currentRoute.value.query.email;
            }
        })

        function send() {
            loadingSending.value = true;
            userStore.sendResetPassword({email: email.value}).then(() => {
                sentSuccessfully.value = true
                badgeNotify.show({
                    text: t('common.emailSuccessfullySent'),
                    success: true,
                    timeout: 15000,
                })
            }).catch((error) => {
                if (error.errorCode === "cant_reset_password_for_this_user") {
                    badgeNotify.show({
                        text: t('googleAuth.errors.cant_reset_password_for_this_user'),
                        error: true,
                        timeout: 15000,
                    })
                } else {
                    badgeNotify.show({
                        text: t('common.errorList.sorrySomethingWrong'),
                        error: true,
                        timeout: 15000,
                    })
                }
            }).finally(() => {
                loadingSending.value = false;
            });
        }

        return {
            userStore,
            t,
            locale,
            loadingSending,
            sentSuccessfully,
            email,
            errorCode,
            send,
        }
    },
}
</script>

<style scoped lang="scss" src="Common/assets/styles/themes/light/auth.scss"></style>