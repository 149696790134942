<template>
    <legal-document code="term_of_use" :title="t('common.termOfUse')"/>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {defineAsyncComponent} from "vue";
import {lazyLoading} from "Common/utility/component";

const {t} = useI18n({useScope: 'global'});
const LegalDocument = defineAsyncComponent( lazyLoading(() => import("./legal_document.vue")) );
</script>


