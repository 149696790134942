<template>
    <modal :visible="show" :close="close" className="form-md">
        <div class="body">
            <div class="container">
                <div class="mb-4">
                    <img src="~Common/assets/emlo-affiliate-black.png" alt=""/>
                </div>
                <div class="title mb-5">{{t('affiliate.allDoneModal.title')}}</div>
                <div class="text">{{t('affiliate.allDoneModal.text')}}</div>
            </div>
        </div>
        <div class="footer">
            <BaseButton class="w-100" @click="close">{{t('common.letsDoIt')}}</BaseButton>
        </div>
    </modal>
</template>

<script setup>
import BaseButton from "Common/components/controls/BaseButton";
import {useI18n} from "vue-i18n";

const {t} = useI18n({useScope: 'global'});

const props = defineProps({
    show: Boolean,
    close: Function,
});

</script>


<style scoped lang="scss">
@import "Common/assets/styles/themes/light/light-theme-colors.scss";
#modals .body{
    padding: 48px 24px;
}
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .title{
        font-weight: 700;
        font-size: 34px;
        line-height: 42px;
        color: $c-sidebar_and_texts;
    }
    .text{
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
    }
}
</style>
