const PaymentSystemType = {
    cardcom: 'cardcom',
    bluesnap: 'bluesnap',
}

const CardExternalId = {
    cardcom: 'cardcomCardExternalId',
    bluesnap: 'bluesnapCardExternalId',
}

const ConvertPaymentSystemType = (typeNumber) => {
    switch (typeNumber){
        case 1: return PaymentSystemType.bluesnap; 
        case 2: return PaymentSystemType.cardcom; 
        default: return null; 
    }
}

export {
    PaymentSystemType,
    CardExternalId,
    ConvertPaymentSystemType
}