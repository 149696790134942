<template>
    <div :class="$style.root">
        <div :class="[$style.title, $style.titleSpace]">{{t('common.paymentInformation')}}</div>
        <div :class="[$style.text, $style.textSpace]">{{t('affiliate.registerDetails.step2Text')}}</div>
        
        <BaseInputString :name="t('common.accountEmail')"
                         :is-required="true"
                         v-model="formData.paypalAccountId"
                         :error="errors.paypalAccountId"
                         class="w-100 mb-3"/>
        
        <div class="w-100 mb-3">
            <div class="link-btn" @click="onPaymentPolicyPage">
                {{t('affiliate.registerDetails.readPaymentPolicy')}}
            </div>
        </div>
        <div class="d-flex flex-row gap-2 mb-3 w-100">
            <div class="w-100">
                <BaseButton class="w-100" outline @click="onBack">
                    {{t('common.back')}}
                </BaseButton>
            </div>
            <div class="w-100">
                <BaseButton class="w-100" :loading="loadingSave" @click="onSave">
                    {{t('common.next')}}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

import BaseInputString from "Common/components/controls/BaseInputString";
import BaseButton from "Common/components/controls/BaseButton";
import Icon from "Common/components/svg";
import {createFormErrors} from "Common/utility/formErrors";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";
import {validateEmail} from "Common/utility/validationUtility";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useBadgeNotify} from "Common/stores/badgeNotify";

const {t, te} = useI18n({useScope: 'global'});
const emit = defineEmits(['next', 'back']);

const organizationStore = useOrganizationStore();
const badgeNotify = useBadgeNotify();

const loadingSave = ref(false);

const formData = ref({
    paypalAccountId: organizationStore.organization.paypalAccountId ?? "",
});

const {errors, showHiddenErrors, removeErrors} = createFormErrors(t, te, [formData], () => {
    const newErrors = {};

    if (formData.value.paypalAccountId &&
        !validateEmail(formData.value.paypalAccountId)) newErrors.paypalAccountId = 'invalid_value';

    return newErrors;
}, {requiredFields: ['paypalAccountId']});

function onBack() {

    emit('back')
}

function onSave() {
    if (showHiddenErrors()) {
        return;
    }
    removeErrors();

    loadingSave.value = true;

    const organization = {
        paypalAccountId: formData.value.paypalAccountId,
    }

    organizationStore.updateOrganization(organization).then(() => {
        organizationStore.organization.paypalAccountId = formData.value.paypalAccountId;
        emit('next');
    }).catch(err => {
        const invalidParams = err?.check?.invalidParams || [];
        const [invalidField] = invalidParams.map(i => i.field).filter(Boolean);

        let text = t('organization_profile.update_error');

        text = invalidField ? t(`organization_profile.update_error_invalid_${invalidField}`) : text;

        badgeNotify.show({
            text,
            error: true
        });

    }).finally(() => {
        loadingSave.value = false;
    });
}

function onPaymentPolicyPage() {
    router.push({name: ROUTES.AffiliatePaymentPolicy});
}


</script>

<style lang="scss" module>
@import "./steps-style.scss";

.root{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    width: 404px;
}

</style>
