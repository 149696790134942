<template>
    <div class="org-details">
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <BasePreloader v-if="loading" show/>
        <BaseTabs v-else :tabs="tabs" content-class="org-tabs-content"></BaseTabs>
    </div>
</template>

<script setup>

import {ref, shallowRef, computed} from "vue";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useUserStore} from "Common/stores/userStore";
import BasePreloader from "Common/components/controls/BasePreloader";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";

import Breadcrumbs from "Common/components/controls/breadcrumbs";
import BaseTabs from "Common/components/controls/BaseTabs";
import PersonalInfo from "./components/PersonalInfo";
import PaymentInfo from "./components/PaymentInfo";
import SocialMediaHandles from "./components/SocialMediaHandles";
import PromotionInfo from "./components/PromotionInfo";

const PersonalInfoRef = shallowRef(PersonalInfo);
const PaymentInfoRef = shallowRef(PaymentInfo);
const SocialMediaHandlesRef = shallowRef(SocialMediaHandles);
const PromotionInfoRef = shallowRef(PromotionInfo);

const {t, locale} = useI18n({useScope: 'global'});
const userStore = useUserStore();
const organizationStore = useOrganizationStore();
const loading = ref(false);
const route = useRoute();

const breadcrumbs = computed(() => {
    return [{
        "name": t('affiliate.affiliateAccountInformation'),
        "link": "#",
        "active": false
    }]
})

let tabs = [{
        icon: "profile",
        name: t('common.personalInformation'),
        component: PersonalInfoRef,
        props: {},
    },{
        icon: "pension-2",
        name: t('common.paymentInformation'),
        component: PaymentInfoRef,
        props: {},
    },{
        icon: "share",
        name: t('common.socialMediaHandles'),
        component: SocialMediaHandlesRef,
        props: {},
    },{
        icon: "loudspeaker",
        name: t('common.promotionInformation'),
        component: PromotionInfoRef,
        props: {},
    },
];

</script>

<style lang="scss" scoped>
.org-details {
    position: relative;
    min-height: 200px;

    & :deep(.org-tabs-content) {
        border-radius: 0 0 20px 20px;
    }

    & :deep(.org-info) {
        margin: -24px -48px -48px -48px;
        background: transparent;
        border: 0;
    }

    & :deep(.cloud-analysis) {
        margin: 0 -24px -36px -24px;
        background: transparent;
        border: 0;
    }
}

body.rtl {
  .org-details {
    & :deep(.org-tabs-content) {
        border-radius: 0 20px 20px 0;
    }
  }
}
</style>
