<template>
    <DashboardBlank>
        <template #header>
            <div class="d-flex align-items-center">
                <div class="mr-3">{{ t('affiliate.dashboard.signUpChannel') }}</div>
            </div>
        </template>
        <template #body>
            <div class="d-flex flex-row gap-2 mb-4 px-4">
                <div v-if="noData" class="no-chart-data-text chart-graph">
                    {{ t('dashboard.newDashboard.noDataForChart') }}
                </div>
                <div v-else class="d-flex w-100" style="height: 100%">
                    <v-chart :option="options" autoresize class="chart-graph"/>
                </div>
            </div>
        </template>
    </DashboardBlank>
</template>

<script setup>

import DashboardBlank from "./DashboardBlank";
import VChart from "vue-echarts";
import {useI18n} from "vue-i18n";
import {computed, ref, onMounted} from "vue";
import getChartBarOptions from "../charts/sign-up-by-name-bar";
import {useAffiliationStore} from "Common/stores/affiliationStore";

const affiliationStore = useAffiliationStore();
const {t, locale} = useI18n({useScope: 'global'});
const data = ref([]);

const noData = computed(() => {
    const total = data.value.reduce((sum, curr) => {
        return sum + curr.usageCount;
    }, 0);
    return total === 0;
});

onMounted(() => {
    affiliationStore.getChannelConversion().then(_data => {
        data.value = _data;
    }).catch(err => {
        console.log(err);
    })
})

const options = computed(() => {
    return getChartBarOptions(
        data.value ?? [],
    );
})


</script>

<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';

.chart-graph {
    height: 315px;
}

.total-badge {
    display: flex;
    flex-direction: column;
    width: 170px;
    border: 1px solid $c-text-secondary;
    border-radius: 12px;
    padding: 12px;

    &.total {
        background: linear-gradient(0deg, rgba(78, 155, 240, 0.1), rgba(78, 155, 240, 0.1)), #FFFFFF;
    }

    &.success {
        background: linear-gradient(0deg, rgba(41, 187, 89, 0.1), rgba(41, 187, 89, 0.1)), #FFFFFF;
    }

    &.errors {
        background: linear-gradient(0deg, rgba(255, 0, 0, 0.1), rgba(255, 0, 0, 0.1)), #FFFFFF;
    }

    .total-header {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: $c-secondary-text-pl;
        margin-bottom: 2px;
    }

    .total-num {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: $c-text-primary;
    }

    .total-type {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $c-secondary-text-pl;
    }

    .total-percent {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        &.better {
            color: $c-green-1;
        }

        &.worse {
            color: $c-red-accent;
        }

        &.up {
            svg {
                transform: rotate(0deg);
            }
        }

        &.down {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .btn-link {
        margin-top: 12px;
    }
}

.see-all {
    cursor: pointer;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $c-blue-accent;

    &.disabled {
        pointer-events: none;
        color: $c-text-secondary;
    }
}

.legend {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $c-secondary-text-pl;
}

.legend-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;

    &.total {
        background-color: $c-blue-accent;
    }

    &.fails {
        background-color: $c-red-accent;
    }
}

.no-chart-data-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $c-secondary-text-pl;
    white-space: pre-wrap;
}

body.rtl {
    .legend-dot {
        margin-left: 6px;
        margin-right: 0;
    }
}
</style>

