<template>
  <div class="timecode-wrapper">
    <div class="timecode">
      <transition-group :css="false" @before-enter="onBeforeEnter" @enter="onEnter">
        <div v-for="(item, index) in timeCodes" :key="item" :class="item.cssClass" :data-index="index">
          <div class="timecode-item-line">
          <span v-if="item.isTyping" class="timecode-item-badge">
            {{ item.time }}
          </span>
          </div>
          <span v-if="item.isTyping" class="timecode-item-text">
          <span>
            {{ item.text }}
          </span>
        </span>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, watch} from 'vue'
import gsap from 'gsap'

const props = defineProps({
  timeCode: {
    type: String,
    default: '', // {}
  },
})

const items = ref([])

const timeCodes = computed(() => {
  return items.value.slice(0, 4)
})

watch(
    () => props.timeCode,
    () => {
      const newObj = JSON.parse(props.timeCode)
      if (newObj.text !== items.value[0]?.text) {
        items.value.unshift({
          ...newObj,
          cssClass: ['timecode-item'],
        })
      }
    },
)

function onBeforeEnter(el) {
  el.style.opacity = 0
  el.style.height = 0
}

function onEnter(el, done) {
  gsap.from(el, {
    display: 'none',
    opacity: 0,
    delay: el.dataset.index * 0.25,
    onComplete: done,
  })

  gsap.to(el, {
    display: 'flex',
    opacity: 1,
    height: 'auto',
    delay: el.dataset.index * 0.25,
    onComplete: () => {
      setTimeout(() => {
        const item = items.value[el.dataset.index];
        item.cssClass = ['timecode-item', 'is-typing']
        item.isTyping = true
      }, 2000)
    },
  })
}
</script>

<style src="./TimeCode.ce.scss" lang="scss"></style>
