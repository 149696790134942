export {
    camelize,
    numberWithCommas
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

function numberWithCommas(x, upto = undefined, options = { alwaysShowDecimalPlaces: false }) {
    if(typeof x !== 'number') x = parseFloat(x);
    if(upto !== undefined){
        if(options.alwaysShowDecimalPlaces || (x % 1 !== 0) ){
            x = x.toFixed(upto);    
        }
    }
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}