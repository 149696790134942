<template>
    <div class="header">
        <div class="d-flex flex-row">
            <SiteBadge icon="user_2" class="badge-class person" :name="t('affiliate.signUps30days')" :value="affiliationStore.stats?.totalCount"/>
            <SiteBadge icon="currency-exchange" class="badge-class conversion" :name="t('affiliate.conversions30days')" :value="affiliationStore.stats?.totalConvertedCount"/>
            <SiteBadge icon="pension-2" class="badge-class payout" :name="t('common.payoutDue')" :value="affiliationStore.stats?.payoutDue"/>
            <SiteBadge icon="sum" class="badge-class total-payouts" :name="t('affiliate.totalPayouts6months')" :value="affiliationStore.stats?.totalPayouts"/>
        </div>
        <div class="d-flex flex-row align-items-center">
            <UserBadge :hideNotifications="!canNotificationRead" />
        </div>
    </div>
</template>

<script setup>

import SiteBadge from "Common/components/header/site_badge";
import UserBadge from "Common/components/header/user_badge";
import {useAffiliationStore} from "Common/stores/affiliationStore";
import {useUserStore} from "Common/stores/userStore";
import {useI18n} from "vue-i18n";
import {ref, computed} from "vue";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";


const canNotificationRead = computed(() => {
    const permissions = userStore.user?.permissions;
    return permissions?.notifications_read;
})

const {t, locale} = useI18n({useScope: 'global'});
const affiliationStore = useAffiliationStore();
const userStore = useUserStore();

affiliationStore.getStats().catch((err) => {
    console.log(err);
})
    

</script>

<style lang="scss" scoped>
@import '~Common/assets/styles/themes/light/light-theme-colors';
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 21px 30px 0 30px;
    flex-wrap: wrap;

    @media (max-width: 990px) {
        flex-direction: column;
        justify-content: center;
        > * + *{
            margin-top: 12px;
        }
    }
}
.badge-class{
    &.person:deep(.sites_badge_icon_container) {
        color: $c-blue-accent;
    }
    &.conversion:deep(.sites_badge_icon_container) {
        color: $c-green;
    }
    &.payout:deep(.sites_badge_icon_container) {
        color: $c-yellow;
    }
    &.total-payouts:deep(.sites_badge_icon_container) {
        color: $c-green-1;
    }
}


</style>