<template>
  <div v-if="show" :keydown.capture.esc="onClose" tabindex="2">
    <div class="notification-modal-container" :class="this.class">
      <div class="arrow-up"></div>
      <div class="notification-modal">
        <div class="notification-modal__header d-flex flex-row justify-content-between align-items-end">
          <div class="notification-modal__title">{{ t('common.notifications') }}</div>
          <!--<div class="notification-modal__view">{{ t('common.clearAll') }}</div>-->
        </div>
        <div class="notification-modal-messages">
          <div v-for="item in unreadNotifications.slice(0,5)">
            <NotificationModalItem :item="item" @on-close="onClose"/>
          </div>
          <BasePreloader class="loader-size" :show="loading"></BasePreloader>
        </div>
        <div class="notification-modal__footer notification-modal__view" @click="readNotifications">{{ t('common.viewAll') }}</div>
      </div>
    </div>
    <div class="close-btn" @click="onClose"></div>
  </div>
</template>

<script>

import Icon from "Common/components/svg";
import BasePreloader from "Common/components/controls/BasePreloader";
import NotificationModalItem from "Common/components/header/NotificationModalItem";
import {useNotificationStore} from "Common/stores/notificationStore";
import {watch, ref} from "vue";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";
import {useI18n} from "vue-i18n";

export default {
  components: {
    Icon,
    NotificationModalItem,
    BasePreloader
  },
  props: {
    show: Boolean,
    class: String,
  },
  setup(props, {emit}){
    const { t } = useI18n({ useScope: 'global' });
    const notificationStore = useNotificationStore();
    const unreadNotifications = ref([]);
    const loading = ref(false);

    watch(() => props.show, function (){
      if(props.show && notificationStore.notificationStats.unreadCount !== unreadNotifications.value.length){
        loading.value = true;
        unreadNotifications.value = [];
        notificationStore.loadNotifications({isRead: false, limit: 5}, {}, false).then((data) => {
          unreadNotifications.value = data.items;
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          loading.value = false;
        });
      }
    });
    
    return {
      t,
      notificationStore,
      unreadNotifications,
      loading,

      onClose,
      readNotifications,
      readNotification,
    }
    
    function onClose(){
      emit('on-close');
    }

    function readNotifications() {
      onClose();
      notificationStore.openNotification.openExternalId = 'view all';
      router.push({name: ROUTES.NotificationList});
    }

    function readNotification(externalId) {
      onClose();
      notificationStore.openNotification.openExternalId = externalId;
      router.push({name: ROUTES.NotificationList});
    }
  }
}

</script>

<style scoped lang="scss">  
  .loader-size:deep {
    .base-preloader{
      width: 30px;
      height: 30px;
      border-width: 4px;
    }
  } 
  .notification-modal-messages{
    position: relative;
    min-height: 50px;
  }
  .notification-modal-container {
    width: 330px;
    position: absolute;
    background-color: transparent;
    top: 88px;
    right: 52px;
    z-index: 110;
    .arrow-up {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid white;
      margin-left: 79%;
    }
    .notification-modal{
      padding: 16px;
      border-radius: 16px;
      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
      &__header {
        height: 40px;
        border-radius: 3px 3px 0 0;
        font-size: 14px;
        padding: 0 0 16px 0;
      }
      &__footer {
        border-radius: 0 0 3px 3px;
        font-size: 14px;
        text-align: center;
        padding: 0;
        cursor: pointer;
      }  
      &__view{
        cursor: pointer;
        font-size: 12px;
        text-decoration-line: underline;
      }
    }
  }
  .close-btn {
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
  }

body.rtl {
  .notification-modal-container {
    right: 52px;
    .arrow-up {
      margin-right: 79%;
      margin-left: 0;
    }
    .notification-modal{
      &__header {
        border-radius: 3px 0 0 3px;
      }
      &__footer {
        border-radius: 0 03px 3px 0;
      }  
    }
  }
  .close-btn {
    right: 0;
    left: unset;
  }
}
</style>