<template>
  <div>
    <div class="d-flex flex-row justify-content-between align-items-center mb-4">
      <div class="subtitle">{{ t('common.supportCases') }}</div>
      <div class="flex-grow-1">

      </div>
      <div class="mx-2">
        <BaseCheckbox v-model="opened" :name="t('customerSupport.openTickets')"/>
      </div>
      <div>
        <BaseSearch
            v-model="searchValue"
            :placeholder="t('common.search')"
            data-test-id="searchCase"
        />
      </div>
      <div class="d-flex flex-row">
        <BaseButton v-if="isUserCanCreateCase" data-test-id="createCase" class="ms-2" add @click="createNew">
          {{ t('common.createCase') }}
          <Icon name="add-2"/>
        </BaseButton>
      </div>
    </div>

    <BaseTable
        async-data
        :headers="grid_heads"
        :rows="cases"
        @loadData="loadData"
        v-model:data-filter="dataFilter"
        v-model:loading="loading"
        pagination
        :pagination-total-rows="totalRows"
    >
      <template #_index="{row}">
        <div :class="{'c-secondary': row.status === CaseStatus.closed}">
          {{ row._index }}
        </div>
      </template>
      <template #lastMessageDate="{row}">
        <div :class="{'c-secondary': row.status === CaseStatus.closed}" style="white-space: nowrap">
          {{ formatDateTimeToLocal(row.lastMessageDate) }}
        </div>
      </template>
      <template #status="{row}">
        <div class="status-badge status-badge__red" v-if="row.status === CaseStatus.opened">
          {{ t('common.' + row.status) }}
        </div>
        <div v-else class="status-badge status-badge__green c-secondary">
          {{ t('common.' + row.status) }}
        </div>
      </template>
      <template #client="{row}">
        <div v-if="row.organization" class="link-btn" @click="viewOrganization(row)">{{ row.organization.name }}</div>
      </template>
      <template #repliedBy="{row}">
        <div v-if="row.organization">
          {{ /support/i.test(row.repliedBy) ? t("customerSupport.support") : t("customerSupport.customer") }}
        </div>
      </template>
      <template #topic="{row}">
        <div :class="{'c-secondary': row.status === CaseStatus.closed}">
          {{ t('common.' + row.topic) }}
        </div>
      </template>
      <template #subject="{row}">
        <div :class="{'c-secondary': row.status === CaseStatus.closed}">
          {{ row.subject }}
        </div>
      </template>
      <template #quick-actions="{row}">
        <div class="d-flex justify-content-center align-items-center">
          <div class="cursor-pointer" @click="openForEdit(row)">
            <Icon name="edit"/>
          </div>
        </div>
      </template>
    </BaseTable>

    <CustomerSupportNewCaseModal
        :show="showCreateModal"
        :close="closeCreateModal"
        @caseCreated="caseCreated"
    />

  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {ref, shallowRef, computed, onActivated, onMounted, watch} from "vue";
import Icon from "Common/components/svg";
import {useCustomerSupportStore} from "Common/stores/customerSupportStore";
import BaseButton from "Common/components/controls/BaseButton";
import BaseCheckbox from "Common/components/controls/BaseCheckbox";
import BaseSearch from "Common/components/controls/BaseSearch";
import BaseTable from "Common/components/table/BaseTable";
import CustomerSupportNewCaseModal from "../modals/CustomerSupportNewCaseModal";
import {formatDateTimeToLocal} from "Common/utility/dateTimeUtility";
import {CaseStatus} from "Common/types/customer_support_type";
import router from "@/routes";
import {ROUTES} from "@/utility/routeUtils";
import {useTableActions} from "Common/stores/tableActions";
import {debounce} from "Common/utility/debounce";
import {useUserStore} from "Common/stores/userStore";

const props = defineProps({
  isAdmin: Boolean,
});

const emit = defineEmits(['openCase']);
const customerSupportStore = useCustomerSupportStore();
const tableActions = useTableActions();
const {t} = useI18n({useScope: 'global'});

const showCreateModal = shallowRef(false);
let mounting = false;
const search = shallowRef("");
const cases = ref([]);
const csSupportRequestExternalId = ref("")
const assignedUserId = ref("")
const loading = ref(false)
const searchValue = ref('')
const userStore = useUserStore()

const grid_heads = computed(() => [
  {sortable: false, label: '#', class: 'base-table-th-sm', fieldName: '_index'},
  {sortable: false, label: t('common.date'), class: 'base-table-th-w-date', fieldName: 'lastMessageDate'},
  {sortable: false, label: t('common.ticketId'), class: 'base-table-th-w-date', fieldName: 'caseId'},
  {sortable: false, label: t('common.status'), class: 'base-table-th-w-status', fieldName: 'status'},
  ...(props.isAdmin ? [{sortable: false, label: t('common.client'), fieldName: 'client'}] : []),
  {sortable: false, label: t('customerSupport.lastResponse'), class: 'base-table-th-w-status', fieldName: 'repliedBy'},
  {sortable: false, label: t('common.topic'), fieldName: 'topic'},
  {sortable: false, label: t('common.subject'), fieldName: 'subject'},
  {
    sortable: false,
    label: t('common.actions'),
    fieldName: 'quick-actions',
    export: false,
    class: 'base-table-th-sm',
    rowClass: 'quick-actions'
  },
]);

const dataFilter = ref({
  status: 'opened',
  search: '',
  _counter: 0,
});

const totalRows = ref(0);

const opened = computed({
  get() {
    return dataFilter.value.status === 'opened';
  },
  set(v) {
    dataFilter.value.status = v ? 'opened' : '';
  }
})


const isUserCanCreateCase = computed(() => userStore.user?.permissions?.cs_create_ticket)


onMounted(() => {
  mounting = true;
});

onActivated(() => {
  if (!mounting) {
    dataFilter.value._counter += 1;
  }
});

function loadData(filter) {
  loading.value = true
  const filterData = {...({...filter} || {})};
  delete filterData._counter;
  return customerSupportStore.loadCustomerSupportCases(filterData).then((data) => {
    cases.value = data.items.map((d, ind) => {
      return {...d, _index: data.items.length - ind};
    });
    totalRows.value = data.total;
  }).then(() => {
    mounting = false;
  }, err => {
    mounting = false;
    console.log(err);
  }).finally(() => loading.value = false);
}

function viewOrganization(row) {
  const route = router.resolve({
    name: ROUTES.AdminOrganizationDetails,
    params: {organizationExternalId: row.organization.organizationExternalId}
  });
  window.open(route.href, '_blank');
}

function openForEdit(row) {
  emit("openCase", row.csSupportRequestExternalId);
}

function caseCreated(caseData) {
  cases.value.unshift({
    _index: cases.value.length + 1,
    lastMessageDate: new Date(),
    status: CaseStatus.opened,
    topic: caseData.topic,
    subject: caseData.subject,
    csSupportRequestExternalId: caseData.csSupportRequestExternalId
  });
}

function closeCreateModal() {
  showCreateModal.value = false;
}

function createNew() {
  showCreateModal.value = true;
}

const searchHandler = () => {
  dataFilter.value.search = searchValue.value
}

watch(searchValue, newValue => {
  dataFilter.value.search = searchValue.value
  debounce(() => searchHandler(), 1000)
})
</script>

<style scoped lang="scss">
.subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 42px;
}
</style>
