<template>
  <div class="base-input base-phone-input" :class="this.class">
    <div v-if="name" class="base-input-header d-flex flex-row justify-content-between">
      <div class="base-input-label">
        {{name}}
        <span v-if="isRequired" class="input-required">*</span>
      </div>
      <div>
        <slot name="subNameContent"></slot>
      </div>
    </div>
    <vue-tel-input
       :key="updateKey"
        class="base-input-container"
        v-model="value"
        :defaultCountry="defaultCountry"
        :inputOptions="{placeholder: ''}"
        :mode="mode"
        :disabled="disabled"
        :autoDefaultCountry="autoDefaultCountry"
        validCharactersOnly
        :dropdownOptions="{
                          showSearchBox: true,
                          showDialCodeInSelection: true,
                          showDialCodeInList: true,
                        }"
        @input="onInput"
        @on-input="onValidate"
        :autoFormat="autoFormat"
    ></vue-tel-input>
    <div v-if="error" class="base-input-error">
      <Icon name="error"/>
      <div v-html="error"></div>
    </div>
  </div>
</template>

<script>

import Icon from 'Common/components/svg';

export default {
  components: {Icon},
  name: 'BaseInputString',
  props: {
    name: String,
    isRequired: {
      type: Boolean,
      default: false
    },
    mode:{
      type: String,
      default: 'international',
    },
    autoFormat: {
      type: Boolean,
      default: true,
    },
    disabled: {type: Boolean, required: false, default: false},
    modelValue: String,
    error: String,
    class: String,
    defaultCountry: String,
    autoDefaultCountry: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue', 'blur', 'validate', 'input'],
  data() {
    return {
      updateKey: 1,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods:{
    onBlur(){
      this.$emit('blur', this.value)
    },
    onValidate(phone, data){
      this.$emit('validate', data);
    },
    onInput(data){
      this.$emit('input', data)
    }
  },
  watch:{
    defaultCountry: function (){
      this.updateKey++;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~Common/assets/styles/themes/light/light-theme-colors.scss";
.base-input{
  display: flex;
  flex-direction: column;
  &:deep{
    .vti__selection{
      font-size: 14px;
    }
    .vue-tel-input:focus-within{
      box-shadow: none;
    }
    .vti__dropdown-list{
      border-radius: 12px;
    }
    .vti__search_box{
      padding: 3px 3px 3px 12px;
      height: auto;
      border: 1px solid $c-secondary-text-pl;
      border-radius: 8px;
    }
  }
  
  .base-input-label{
    margin-bottom: 8px;
    line-height: 14px;
  }
  .base-input-container{
    padding: 17px 24px 17px 8px;
    border: 1px solid white;
    border-radius: 8px;
  }
  .base-input-error{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    svg{
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}

body.rtl {
  .base-input{
    &:deep{
      .vti__search_box{
        padding: 3px 12px 3px 3px;
      }
    }

    .base-input-container{
      padding: 17px 8px 17px 24px;
      direction: ltr;
    }
    .base-input-error{
      svg{
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
}
</style>
