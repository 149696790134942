<template>
  <template v-if="modelType === TableActionModalTypes.ApproveWithUnderstand">
    <ConfirmationModal :show="tableActions.show" :loading-approve="tableActions.actionLoading" @close="tableActions.close" @approve="tableActions.okCallBack">
      <template #title>
        <div v-html="tableActions.options.title"></div>
      </template>
      <template #text>
        <div v-html="tableActions.options.text"></div>
      </template>
    </ConfirmationModal>
  </template>
  <template v-else-if="modelType === TableActionModalTypes.ApproveWithoutUnderstand">
    <ConfirmationModalWithoutUnderstand :show="tableActions.show" :loading-approve="tableActions.actionLoading" @close="tableActions.close" @approve="tableActions.okCallBack">
      <template #title>
        <div v-html="tableActions.options.title"></div>
      </template>
      <template #text>
        <div v-html="tableActions.options.text"></div>
      </template>
    </ConfirmationModalWithoutUnderstand>
  </template>
</template>

<script>

import {computed} from 'vue';
import ConfirmationModal from 'Common/components/modals/ConfirmationModal';
import ConfirmationModalWithoutUnderstand from 'Common/components/modals/ConfirmationModalWithoutUnderstand';
import {useTableActions} from 'Common/stores/tableActions';
import {TableActionModalTypes} from 'Common/types/table_action_modal_type';

export default {
  components: {
    ConfirmationModal,
    ConfirmationModalWithoutUnderstand,
  },
  setup(){
    const tableActions = useTableActions();
    
    const modelType = computed(() => {
      return tableActions.options && tableActions.options.modalType;
    });
    
    return {
      TableActionModalTypes,
      modelType,
      tableActions
    }
  }
}
</script>

<style scoped lang="scss">

</style>